import { FC, MouseEvent, useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash-es";
import { useNavigate } from "react-router";
import Content from "../../components/Content";
import { fromPlatformIcon } from "../../utils/iconUtil";
import { toHumanReadableDate } from "../../utils/dateUtil";
import { getToBeMigrated, migrate } from "../../database/migration/00_legacy-app-to-current/migrationUtil";
import useAuth from "../../hooks/useAuth";
import { useMoreAppClient } from "../../context/MoreAppContext";
import Migration from "../../types/Migration";
import Page from "../../components/Page";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import EmptyContent from "../../components/EmptyContent";
import { noop, noopAsync } from "../../utils/noop";
import { Text } from "../../storybook/components/Text/Text";
import { Icon } from "../../storybook/components/Icon/Icon";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";
import { Header } from "../../storybook/components/Header/Header";
import { Spinner } from "../../storybook/components/Spinner/Spinner";
import { NavItem } from "../../storybook/components/NavItem/NavItem";

const SettingsMigrationPage: FC = () => {
  const { t } = useTranslation();
  const { username } = useAuth();
  const client = useMoreAppClient();
  const navigate = useNavigate();
  const [initialized, setInitialized] = useState<boolean>(false);
  const [selecting, setSelecting] = useState(false);
  const [selection, setSelection] = useState<string[]>([]);
  const [openMigrations, setOpenMigrations] = useState<Migration[]>([]);
  const [migrating, setMigrating] = useState(false);
  const [migrated, setMigrated] = useState<string[]>([]);
  const [whitespaceHeight, setWhitespaceHeight] = useState(0);
  const filteredSubmissions = useMemo(
    () => openMigrations.filter((i) => !migrated.includes(i.submission.meta.guid)),
    [migrated, openMigrations],
  );
  const buttonRef = useCallback((node: HTMLDivElement) => node && setWhitespaceHeight(node.clientHeight), []);

  useAsyncEffect(
    async () => {
      if (username) {
        setOpenMigrations(await getToBeMigrated(username));
        setInitialized(true);
      }
    },
    noopAsync,
    [username],
  );

  const migrateSelectedSubmissions = async (): Promise<void> => {
    setMigrating(true);
    const result = (await migrate(username!, client!, selection)).filter((i) => !!i) as string[];
    setMigrated((prevState) => [...prevState, ...result]);
    clearSelection();
    setMigrating(false);
  };

  const migrateAllSubmissions = async (): Promise<void> => {
    setMigrating(true);
    const result = (await migrate(username!, client!)).filter((i) => !!i) as string[];
    setMigrated((prevState) => [...prevState, ...result]);
    setMigrating(false);
  };

  const onSelect = (submissionId: string) => (event?: MouseEvent) => {
    event?.stopPropagation();
    setSelection((prevState) =>
      prevState.includes(submissionId) ? prevState.filter((id) => id !== submissionId) : [...prevState, submissionId],
    );
  };

  const clearSelection = (): void => {
    setSelecting(false);
    setSelection([]);
  };

  const migrateSelectedButton = (
    <>
      <div
        {...(selection.length > 0 && {
          onClick: migrateSelectedSubmissions,
        })}
        className="absolute bottom-0 z-10 w-full border-t-2 border-gray-100 bg-white mb-safe md:hidden"
      >
        <div className="my-0.5 grid h-16 place-content-center">
          <div className={classNames("flex", selection.length === 0 && "opacity-40")}>
            <Icon className={classNames("mr-2", selection.length > 0 && "text-brand-500")} name="ArchiveIcon" />
            <Text weight="semibold" color={selection.length > 0 ? "medium" : undefined}>
              {t("MIGRATE", { count: selection.length })}
            </Text>
          </div>
        </div>
      </div>
      <div className="relative hidden md:flex">
        <div ref={buttonRef} className="absolute bottom-0 hidden w-full justify-center mb-safe md:flex">
          <IconAndTextButton
            className="mb-2"
            icon="ArchiveIcon"
            disabled={selection.length === 0}
            variant="default"
            onClick={migrateSelectedSubmissions}
            label={t("MIGRATE", { count: selection.length })}
          />
        </div>
      </div>
    </>
  );

  const disabledActions = migrating || isEmpty(filteredSubmissions);
  const header = (
    <Header
      className="mt-safe"
      title={t("MIGRATION_PAGE_TITLE")}
      actions={
        !selecting
          ? [
              {
                kind: "icon",
                icon: "PencilIcon",
                disabled: disabledActions,
                onClick: () => setSelecting(true),
                "aria-label": t("EDIT"),
              },
              {
                kind: "icon",
                icon: "ArchiveIcon",
                disabled: disabledActions,
                onClick: migrateAllSubmissions,
                "aria-label": t("MIGRATE_ALL"),
              }, // TODO, turn back into MIGRATE_ALL button
            ]
          : [
              {
                kind: "text",
                onClick: clearSelection,
                label: t("DONE"),
              },
            ]
      }
      backBtn={{ ariaLabel: t("BACK"), onClick: () => navigate("/settings", { replace: true }) }}
    />
  );

  return (
    <Page>
      {header}
      <Content padding={false}>
        <div className="mt-2 flex-col">
          {(migrating || !initialized) && (
            <div className="flex justify-center">
              <Spinner />
            </div>
          )}
          <div>
            {!isEmpty(filteredSubmissions) ? (
              filteredSubmissions.map(({ submission, migrationType }) => (
                <NavItem
                  key={submission.meta.guid}
                  className="py-1"
                  label={submission.formName}
                  icon={{ name: fromPlatformIcon(submission.formIcon) }} // Legacy submissions don't have color stored
                  meta={[
                    {
                      label: ((): string => {
                        switch (migrationType) {
                          case "saved":
                            return t("MIGRATION_SAVED");
                          case "sent":
                            return t("MIGRATION_QUEUED");
                          case "draft":
                          default:
                            return t("MIGRATION_DRAFT");
                        }
                      })(),
                    },
                    {
                      icon: "CalendarIcon",
                      label: toHumanReadableDate(new Date(submission.meta.registrationDate)),
                    },
                  ]}
                  onClick={selecting ? onSelect(submission.meta.guid) : noop}
                  selectable={selecting}
                  disabled={!selecting}
                  onSelect={onSelect(submission.meta.guid)}
                  selected={selection.includes(submission.meta.guid)}
                />
              ))
            ) : (
              <EmptyContent
                title={t("MIGRATION_EMPTY_TITLE")}
                description={t("MIGRATION_EMPTY_DESCRIPTION")}
                imgSrc="assets/empty.svg"
              />
            )}
          </div>
          {selecting && <div style={{ height: `${whitespaceHeight}px` }} />}
        </div>
      </Content>
      {selecting && migrateSelectedButton}
    </Page>
  );
};

export default SettingsMigrationPage;
