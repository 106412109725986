import { useState, useEffect } from "react";

const hasFocus = (): boolean => typeof document !== "undefined" && document.hasFocus();

// https://github.com/jpalumickas/use-window-focus/blob/main/src/index.ts #a8975bc
const useWindowFocus = (): boolean => {
  const [focused, setFocused] = useState(hasFocus); // Focus for first render

  useEffect(() => {
    setFocused(hasFocus()); // Focus for additional renders

    const onFocus = (): void => setFocused(true);
    const onBlur = (): void => setFocused(false);

    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);

    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  return focused;
};

export default useWindowFocus;
