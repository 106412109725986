import React, { FC, Ref, RefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Label } from "../Label/Label";

export interface ReadOnlyTextAreaProps {
  id: string;
  value?: string;
  ref?: Ref<any>;
  label?: string;
}

export const ReadOnlyTextArea: FC<ReadOnlyTextAreaProps> = ({ id, value, ref, label }) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(true);
  const [expandable, setExpandable] = useState(false);
  const labelId = `${id}-label`;

  const spanRef = useRef<HTMLSpanElement>(null);

  const updateTruncation = (): void => {
    const truncated = shouldBeTruncated(spanRef);
    setExpandable(truncated);
    if (!truncated && !collapsed) {
      setCollapsed(true);
    }
  };

  const onResize = (): void => {
    setCollapsed(true);
    updateTruncation();
  };

  useEffect(() => {
    updateTruncation();
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return (
    <div>
      {label && <Label id={labelId} label={label} />}

      <div
        id={id}
        className="block min-h-12.5 w-full cursor-not-allowed rounded border-1.5 border-gray-200 bg-gray-100 p-3.5 text-sm text-gray-700 transition"
        ref={ref}
        aria-labelledby={label ? labelId : undefined}
      >
        <span className={`${collapsed ? "line-clamp-4" : ""} whitespace-pre-wrap break-words`} ref={spanRef}>
          {value}
        </span>
        {expandable && (
          <button
            onClick={() => setCollapsed(!collapsed)}
            className="block select-none rounded text-brand-600 outline-none focus-visible:ring active:!text-gray-900 pointer:hover:text-brand-800"
          >
            <span className="mt-2.5 inline-block text-sm font-semibold">
              {collapsed ? t("SHOW_MORE_BUTTON") : t("SHOW_LESS_BUTTON")}
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

const shouldBeTruncated = (spanRef: RefObject<HTMLSpanElement>): boolean => {
  const span = spanRef.current;
  if (!span) {
    return false;
  }

  return span.clientHeight < span.scrollHeight;
};
