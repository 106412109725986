export type HexColor = `#${string}`;
export type Shade = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

type Palette = Record<Shade, HexColor>;

const gray: Palette = {
  50: "#f9fafb",
  100: "#f3f4f6",
  200: "#e5e7eb",
  300: "#d1d5db",
  400: "#9ca3af",
  500: "#6b7280",
  600: "#4b5563",
  700: "#343d4b",
  800: "#1f2937",
  900: "#111827",
};

const brand: Palette = {
  50: "#f0f6ff",
  100: "#dce9ff",
  200: "#c1daff",
  300: "#a2c0ee",
  400: "#809ecc",
  500: "#5f7dab",
  600: "#3f5d8b",
  700: "#345280",
  800: "#284674",
  900: "#203e6c",
};

const red: Palette = {
  50: "#fef2f2",
  100: "#fee2e2",
  200: "#fecaca",
  300: "#fca5a5",
  400: "#f87171",
  500: "#ef4444",
  600: "#dc2626",
  700: "#b91c1c",
  800: "#991b1b",
  900: "#7f1d1d",
};

const yellow: Palette = {
  50: "#fffbeb",
  100: "#fef3c7",
  200: "#fde68a",
  300: "#fcd34d",
  400: "#ffc217",
  500: "#f59e0b",
  600: "#d97706",
  700: "#b45309",
  800: "#92400e",
  900: "#78350f",
};

const orange: Palette = {
  50: "#fff9f4",
  100: "#fff2ea",
  200: "#ffdfca",
  300: "#ffcca9",
  400: "#ffa569",
  500: "#ff7f29",
  600: "#e67225",
  700: "#bf5f1f",
  800: "#994c19",
  900: "#7d3e14",
};

const teal: Palette = {
  50: "#f4fcfc",
  100: "#e8f9f9",
  200: "#c7f0f0",
  300: "#a5e7e7",
  400: "#61d6d4",
  500: "#1dc4c2",
  600: "#1ab0af",
  700: "#169392",
  800: "#117674",
  900: "#0e605f",
};

const green: Palette = {
  50: "#edfaf0",
  100: "#dcefe0",
  200: "#badec1",
  300: "#97cea2",
  400: "#78af83",
  500: "#649b6f",
  600: "#548b5f",
  700: "#41784c",
  800: "#376e42",
  900: "#285f33",
};

const mossGreen: Palette = {
  50: "#EEFCFA",
  100: "#E6F2F0",
  200: "#CDE4E2",
  300: "#B4D7D3",
  400: "#85BDB6",
  500: "#4EAAA0",
  600: "#548A83",
  700: "#337774",
  800: "#32534F",
  900: "#1E433E",
};

const blue: Palette = {
  50: "#eff6ff",
  100: "#dbeafe",
  200: "#bfdbfe",
  300: "#93c5fd",
  400: "#60a5fa",
  500: "#3b82f6",
  600: "#2563eb",
  700: "#1d4ed8",
  800: "#1e40af",
  900: "#1e3a8a",
};

const lightBlue: Palette = {
  50: "#F0F9FF",
  100: "#E0F2FE",
  200: "#BAE6FD",
  300: "#7DD3FC",
  400: "#38BDF8",
  500: "#0EA5E9",
  600: "#0284C7",
  700: "#0369A1",
  800: "#075985",
  900: "#0C4A6E",
};

const indigo: Palette = {
  50: "#EEF2FF",
  100: "#E0E7FF",
  200: "#C7D2FE",
  300: "#A5B4FC",
  400: "#818CF8",
  500: "#6366F1",
  600: "#4F46E5",
  700: "#4338CA",
  800: "#3730A3",
  900: "#312E81",
};

const purple: Palette = {
  50: "#f5f3ff",
  100: "#ede9fe",
  200: "#ddd6fe",
  300: "#c4b5fd",
  400: "#a78bfa",
  500: "#8b5cf6",
  600: "#7c3aed",
  700: "#6d28d9",
  800: "#5b21b6",
  900: "#4c1d95",
};

const pink: Palette = {
  50: "#fdf2f8",
  100: "#fce7f3",
  200: "#fbcfe8",
  300: "#f9a8d4",
  400: "#f472b6",
  500: "#ec4899",
  600: "#db2777",
  700: "#be185d",
  800: "#9d174d",
  900: "#831843",
};

export const colors = {
  transparent: "transparent",
  current: "currentcolor",
  inherit: "inherit",
  black: "#000000" as HexColor,
  white: "#ffffff" as HexColor,
  gray,
  brand,
  red,
  yellow,
  orange,
  teal,
  green,
  "moss-green": mossGreen,
  blue,
  "light-blue": lightBlue,
  indigo,
  purple,
  pink,
};
