import { get, set, del } from "idb-keyval";

type IDBPersister = {
  setItem: (key: string, value: string) => Promise<void>;
  getItem: (key: string) => Promise<any>;
  removeItem: (key: string) => Promise<void>;
};

export const createIDBPersister = (idbValidKey: IDBValidKey = "reactQuery"): IDBPersister => ({
  setItem: async (key: string, value: string): Promise<void> => {
    // Don't persist unauthenticated state
    if (key !== "REACT_QUERY_OFFLINE_CACHE") {
      set(`${idbValidKey}-${key}`, value);
    }
  },
  getItem: async (key: string): Promise<any> => get(`${idbValidKey}-${key}`),
  removeItem: async (key: string): Promise<void> => {
    await del(`${idbValidKey}-${key}`);
  },
});
