import { Submission } from "../types/Submission";
import { Field } from "../types/Field";
import { useMoreAppClient } from "../context/MoreAppContext";
import { copySubmission } from "../utils/submissionUtil";
import usePublishedFormVersion from "./usePublishedFormVersion";
import useAuth from "./useAuth";
import { filterExcludedFields, filterUnpublishedFields } from "../utils/copyUtil";
import useFieldCollection from "./useFieldCollection";
import useSubmissionCollection from "./useSubmissionCollection";
import useDeviceInfo from "./useDeviceInfo";

type UseSubmissionCopyResult = {
  copy: () => Promise<string | undefined>;
};

const useSubmissionCopy = (submission?: Submission, fields?: Field[], formId?: string): UseSubmissionCopyResult => {
  const submissionCollection = useSubmissionCollection();
  const fieldCollection = useFieldCollection();
  const client = useMoreAppClient();
  const { authorization } = useAuth();
  const { id: deviceId } = useDeviceInfo();
  const { formVersion } = usePublishedFormVersion(submission?.customerId, formId);

  const copy = async (): Promise<string | undefined> => {
    if (!submission || !fields || !formVersion) {
      return undefined;
    }

    // Filter out fields that are no longer available in published FormVersion
    const remainingFields = filterUnpublishedFields(formVersion, fields);

    // Filter out fields that are excluded by Search Settings
    const allowedFields = filterExcludedFields(formVersion, remainingFields);
    const result = await copySubmission(submission, allowedFields, formVersion, client!, authorization, deviceId);
    await submissionCollection?.upsert(result.submission);
    await fieldCollection?.bulkUpsert(result.fields);
    return result.submission.id;
  };
  return { copy };
};

export default useSubmissionCopy;
