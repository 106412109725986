import { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useOutletContext } from "react-router";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";
import { RxDocument } from "rxdb";
import classNames from "classnames";
import { QueryConstructor } from "rxdb-hooks/dist/useRxData";
import { TFunction } from "i18next";
import Content from "../components/Content";
import { StatusMessage, Submission } from "../types/Submission";
import { fromPlatformIcon } from "../utils/iconUtil";
import { toHumanReadableDate } from "../utils/dateUtil";
import SubmissionDetailDrawer from "../components/SubmissionDetailDrawer";
import useFileHandler from "../hooks/useFileHandler";
import useToasts from "../hooks/useToasts";
import useAuth from "../hooks/useAuth";
import MenuPage from "../components/MenuPage";
import SearchDrawer from "../components/SearchDrawer";
import useDrawer from "../hooks/useDrawer";
import { NavigationPageOutletContext } from "./NavigationPage";
import useDeviceOrientation from "../hooks/useDeviceOrientation";
import { Text } from "../storybook/components/Text/Text";
import { HeaderButtonProps } from "../storybook/components/Header/Header";
import { Modal } from "../storybook/components/Modal/Modal";
import { Icon, IconName } from "../storybook/components/Icon/Icon";
import { IconAndTextButton } from "../storybook/components/IconAndTextButton/IconAndTextButton";
import { Title } from "../storybook/components/Title/Title";
import { NavItem, type NavItemMeta } from "../storybook/components/NavItem/NavItem";
import { Spinner } from "../storybook/components/Spinner/Spinner";
import useLocalSubmissions from "../hooks/useLocalSubmissions";
import { resolveTheme } from "../storybook/themes";

interface SubmissionListPageProps {
  ariaLabel?: string;
  translationPrefix: "DRAFT" | "TASK";
  type: "DRAFTS" | "TASKS";
  query: QueryConstructor<Submission>;
  emptyState: JSX.Element;
  showListHeaders?: boolean;
}

const SubmissionListPage: FC<SubmissionListPageProps> = ({
  ariaLabel,
  query,
  emptyState,
  translationPrefix,
  showListHeaders,
  type,
}) => {
  const {
    result: submissions,
    isFetching,
    fetchMore,
    isExhausted,
  } = useLocalSubmissions(query, {
    pageSize: 20,
    pagination: "Infinite",
  });
  const { customerId } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { removeLocalFiles } = useFileHandler();
  const { showToast } = useToasts();
  const { ref, inView: scrollerInView } = useInView();
  const [selecting, setSelecting] = useState(false);
  const [selection, setSelection] = useState<string[]>([]);
  const [search, setSearch] = useDrawer("search");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { setHeaderProps, setShowWorkspaceHeader } = useOutletContext<NavigationPageOutletContext>();
  const buttonRef = useCallback((node: HTMLDivElement) => node && setWhitespaceHeight(node.clientHeight), []);
  const [whitespaceHeight, setWhitespaceHeight] = useState(0);
  const [infoOpen, setInfoOpen] = useDrawer("info");
  const [info, setInfo] = useState<RxDocument<Submission> | undefined>();
  const { landscapeIndent } = useDeviceOrientation(false);

  useEffect(() => {
    clearSelection();
  }, [customerId]);

  useEffect(() => {
    if (scrollerInView) {
      fetchMore();
    }
  }, [scrollerInView, fetchMore]);

  const submissionsInProgress = submissions.filter(({ viewedAt }) => viewedAt);
  const submissionsNew = submissions.filter(({ viewedAt }) => !viewedAt);

  const clearSelection = (): void => {
    setSelecting(false);
    setSelection([]);
  };

  useEffect(() => {
    setShowWorkspaceHeader(true);
    setHeaderProps({
      defaultTitle: t(`${translationPrefix}_PAGE_TITLE`),
      actions: !selecting
        ? [
            ...(submissions && submissions.length > 0
              ? [
                  {
                    kind: "icon",
                    icon: "PencilIcon",
                    onClick: () => setSelecting(true),
                    "aria-label": t("EDIT"),
                  } as HeaderButtonProps,
                ]
              : []),
            {
              kind: "icon",
              icon: "SearchIcon",
              onClick: () => setSearch(true),
              "aria-label": t("SEARCH"),
            },
          ]
        : [
            {
              kind: "text",
              label: t("DONE"),
              onClick: clearSelection,
            },
          ],
    });
  }, [selecting, setHeaderProps, setShowWorkspaceHeader, submissions, t, translationPrefix]); // eslint-disable-line react-hooks/exhaustive-deps

  const onDelete = async (): Promise<void> => {
    setShowDeleteModal(false);

    const submissionDeletions = submissions
      .filter((submission) => selection.includes(submission.id))
      .map(async (submission) => {
        await submission.remove();
        return removeLocalFiles(submission.id);
      });
    await Promise.allSettled(submissionDeletions);

    showToast({
      message: t(`${translationPrefix}_DELETED_TOAST_TITLE`, { count: selection.length }),
      icon: "CheckIcon",
    });

    setSelecting(false);
    setSelection([]);
  };

  const onSelect = (submissionId: string) => (event?: MouseEvent) => {
    event?.stopPropagation();
    setSelection((prevState) =>
      prevState.includes(submissionId) ? prevState.filter((id) => id !== submissionId) : [...prevState, submissionId],
    );
  };

  const deleteModal = (
    <Modal
      title={t(`${translationPrefix}_DELETE_MODAL_TITLE`, { count: selection.length })}
      content={{
        kind: "message",
        message: t(`${translationPrefix}_DELETE_MODAL_DESCRIPTION`, { count: selection.length }),
      }}
      open={showDeleteModal}
      onClose={() => setShowDeleteModal(false)}
      buttons={[
        {
          label: t("CANCEL"),
          onClick: () => setShowDeleteModal(false),
        },
        {
          label: t("DELETE"),
          variant: "destructive",
          onClick: onDelete,
        },
      ]}
    />
  );

  const deletionButton = (
    <>
      <div
        {...(selection.length > 0 && {
          onClick: () => setShowDeleteModal(true),
        })}
        className="absolute bottom-0 z-10 w-full border-t-2 border-gray-100 bg-white mb-safe md:hidden"
      >
        <div className="my-0.5 grid h-16 place-content-center">
          <div className={classNames("flex", selection.length === 0 && "opacity-40")}>
            <Icon className={classNames("mr-2", selection.length > 0 && "text-red-500")} name="TrashIcon" />
            <Text weight="semibold" color={selection.length > 0 ? "danger" : undefined}>
              {t(`${translationPrefix}_DELETE`, { count: selection.length })}
            </Text>
          </div>
        </div>
      </div>
      <div className="relative hidden md:flex">
        <div ref={buttonRef} className="absolute bottom-0 hidden w-full justify-center mb-safe md:flex">
          <IconAndTextButton
            className="mb-2"
            icon="TrashIcon"
            disabled={selection.length === 0}
            variant="destructive"
            onClick={() => setShowDeleteModal(true)}
            label={t(`${translationPrefix}_DELETE`, { count: selection.length })}
          />
        </div>
      </div>
    </>
  );

  const listHeaders = (submission: RxDocument<Submission>): JSX.Element => (
    <>
      {showListHeaders && submissionsInProgress.length > 0 && submissionsInProgress[0].id === submission.id && (
        <Title as="h2" size="xs" className="mt-6 pl-4">
          {t("TASKS_IN_PROGRESS", { count: submissionsInProgress.length })}
        </Title>
      )}
      {showListHeaders && submissionsNew.length > 0 && submissionsNew[0].id === submission.id && (
        <Title as="h2" size="xs" className="mt-6 pl-4">
          {t("TASKS_NEW", { count: submissionsNew.length })}
        </Title>
      )}
      {!showListHeaders && (
        <Title as="h2" size="xs" className="sr-only">
          {ariaLabel}
        </Title>
      )}
    </>
  );

  const getSubmissionElement = (submission: RxDocument<Submission>): JSX.Element => (
    <NavItem
      className={classNames(selection.includes(submission.id) && "bg-brand-100")}
      icon={{
        name: fromPlatformIcon(submission.form.icon),
        theme: resolveTheme(submission.form.iconColor),
        showSubIcon: !!submission.task,
      }}
      key={submission.id}
      btnAriaLabel={`${t(`OPEN_${translationPrefix}_OPTIONS`)}, ${submission.form.name}`}
      label={submission.form.name}
      description={submission.description ? submission.description : submission.form.description}
      comment={submission.task?.message}
      menuItems={[
        {
          label: t("DETAILS"),
          onClick: (): void => {
            setInfo(submission);
            setInfoOpen(true);
          },
        },
        {
          label: t("DELETE"),
          type: "destructive",
          onClick: (): void => {
            setSelection([submission.id]);
            setShowDeleteModal(true);
          },
        },
      ]}
      meta={[
        {
          icon: "PencilAltIcon",
          label: toHumanReadableDate(submission.updatedAt, true),
        },
        ...getDueDatePill(submission),
        ...getStatusMessagePill(submission, t),
      ]}
      onClick={() => (selecting ? onSelect(submission.id)() : navigate(`/submissions/${submission.id}`))}
      selectable={selecting}
      onSelect={onSelect(submission.id)}
      selected={selection.includes(submission.id)}
    />
  );

  const submissionElements = useMemo(
    () =>
      submissions.map((submission) => (
        <div key={submission.id} className="relative">
          {listHeaders(submission)}
          {getSubmissionElement(submission)}
        </div>
      )),
    [submissions, selection, selecting, navigate], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <MenuPage showTabBar>
      <Content padding={false}>
        <SearchDrawer open={search} onClose={() => setSearch(false)} initialCategory={type} />
        {info && (
          <SubmissionDetailDrawer
            submission={info}
            translationPrefix={translationPrefix}
            onClose={() => setInfoOpen(false)}
            showUnreadButton={showListHeaders && !!info.viewedAt}
            open={infoOpen}
          />
        )}
        <div className={`mt-3 ${landscapeIndent}`}>{submissions && submissions.length > 0 && submissionElements}</div>
        {isFetching && <Spinner className="m-auto mt-8" />}
        {!isFetching && submissions.length === 0 && emptyState}
        {selecting && <div style={{ height: `${whitespaceHeight}px` }} />}
        {!isExhausted && <div ref={ref} />}
      </Content>

      {selecting && deletionButton}
      {showDeleteModal && deleteModal}
    </MenuPage>
  );
};

const getDueDatePill = (submission: Submission): NavItemMeta[] =>
  submission.task?.dueDate
    ? [
        {
          icon: "CalendarTimeIcon" as IconName,
          label: toHumanReadableDate(submission.task.dueDate),
        },
      ]
    : [];

const getStatusMessagePill = (submission: Submission, t: TFunction): NavItemMeta[] =>
  submission.meta?.statusMessage
    ? [
        {
          icon: "ExclamationIcon" as IconName,
          label: t(StatusMessage[submission.meta.statusMessage]),
        },
      ]
    : [];

export default SubmissionListPage;
