import { NavigateFunction, NavigateOptions, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { To } from "react-router-dom";

const useNavigateAsync: () => NavigateFunction = () => {
  const navigate = useNavigate();
  const [navigateTo, setNavigateTo] = useState<{
    to: To | number;
    options?: NavigateOptions;
  }>();

  useEffect(() => {
    if (navigateTo && !Number.isNaN(Number(navigateTo?.to))) {
      navigate(navigateTo.to as number);
    } else if (navigateTo) {
      navigate(navigateTo.to as To, navigateTo.options);
    }
    setNavigateTo(undefined);
  }, [navigate, navigateTo]);

  return (to: To | number, options?: NavigateOptions) => setNavigateTo({ to, options });
};

export default useNavigateAsync;
