import { ChangeEvent, forwardRef, useImperativeHandle, useRef } from "react";
import axios from "axios";
import uuidv4 from "../../../utils/uuid";
import { seconds } from "../../../utils/timeUtil";
import { IMAGE_OPT_URL } from "../../../constants";
import useFileHandler from "../../../hooks/useFileHandler";
import { UploadStatus } from "../../../types/Field";
import { FileResult } from "../../../types/Widget";
import { uriToBlob } from "../../../utils/fileUtil";

export type ConvertStatus = UploadStatus | "pending" | "converting";
interface PdfImageUploadProps {
  submissionId: string;
  persistWithUploadStatus: (fileResult: FileResult, uploadStatus: UploadStatus) => void;
  setConvertState: (value: ConvertStatus) => void;
}

export type PdfImageUploadMethods = {
  onClick: () => void;
};

const PdfImageUpload = forwardRef<PdfImageUploadMethods, PdfImageUploadProps>(
  ({ submissionId, persistWithUploadStatus, setConvertState }, ref) => {
    const { storeFileUri } = useFileHandler();
    const inputFile = useRef<HTMLInputElement | null>(null);

    useImperativeHandle(ref, () => ({ onClick }));

    const onClick = (): void => {
      inputFile.current?.removeAttribute("capture");
      inputFile.current?.click();
    };

    const addSelectedPdf = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
      if (!event.currentTarget.files || !event.currentTarget.files[0]) {
        return;
      }
      try {
        setConvertState("converting");
        const convertedPdf = await convertPDF(event.currentTarget.files[0]);
        await uploadPdf(convertedPdf);
      } catch (e) {
        setConvertState("error");
      }
    };

    const convertPDF = async (file: File): Promise<string> => {
      const pdfObjectURL = URL.createObjectURL(file);
      const pdfBlob = await uriToBlob(pdfObjectURL);

      const data = new FormData();
      data.append("file", pdfBlob);
      const response = await axios.post(`${IMAGE_OPT_URL}/pdf/convert`, data, {
        headers: { "Content-Type": "application/pdf" },
        responseType: "blob",
        timeout: seconds(90), // Overriding the default. File uploads can take longer on unstable connections
      });
      URL.revokeObjectURL(pdfObjectURL);
      return URL.createObjectURL(response.data);
    };

    const uploadPdf = async (objectUrl: string): Promise<void> => {
      setConvertState("uploading");
      const id = uuidv4();
      try {
        const result = await storeFileUri(objectUrl, submissionId, id);
        URL.revokeObjectURL(objectUrl);
        persistWithUploadStatus(result.fileResult, result.uploadStatus);
        setConvertState("uploaded");
      } catch (e) {
        setConvertState("error");
      }
    };

    return (
      <input
        className="hidden"
        type="file"
        accept="application/pdf"
        ref={inputFile}
        onChange={(e) => addSelectedPdf(e)}
      />
    );
  },
);

PdfImageUpload.displayName = "PdfUpload";

export default PdfImageUpload;
