import { RxJsonSchema } from "rxdb";
import { FormVersion } from "../types/FormVersion";

export interface FormVersionEntry {
  id: string;
  customerId: number;
  formId: string;
  formVersion: FormVersion;
}
export const formVersionSchema: RxJsonSchema<FormVersionEntry> = {
  title: "FormVersion schema",
  description: "Specific version of a form",
  version: 0,
  primaryKey: "id",
  type: "object",
  keyCompression: true,
  properties: {
    id: { type: "string", maxLength: 36 },
    customerId: { type: "number", minimum: 1, maximum: 999_999_999, multipleOf: 1 },
    formId: { type: "string", maxLength: 36 },
    formVersion: { type: "object" },
  },
  required: ["id", "customerId", "formId", "formVersion"],
};
