const EXAMPLE_DECIMAL = 1.1;

export const formatCurrency = (value: number, locale: string, options: Intl.NumberFormatOptions): string =>
  new Intl.NumberFormat(locale, { style: "currency", ...options }).format(value);

export const delimiter =
  Intl.NumberFormat(navigator.language)
    .formatToParts(EXAMPLE_DECIMAL)
    .find((part) => part.type === "decimal")?.value ?? ".";

export const thousandSeparator = delimiter === "." ? "," : ".";
