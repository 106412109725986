import { FocusEvent } from "react";
import { isFinite, isNil } from "lodash-es";
import { NumberFormatValues } from "react-number-format";
import { CurrencyResult, Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import { toFormattedCurrency } from "../../utils/stringUtil";
import { NumberInput } from "../../storybook/components/NumberInput/NumberInput";
import { validateCurrency } from "../../utils/validationUtil";
import { getFormValue } from "../../utils/numberUtil";
import { isTouchCapable } from "../../utils/deviceUtil";
import { toIsoCurrency, toIsoCurrencySymbol } from "../../utils/currencyUtil";
import useSyncedState from "../../hooks/useSyncedState";
import WidgetContainer from "../WidgetContainer";

export interface WidgetPriceProperties {
  required: boolean;
  currency?: string;
  label_text: string;
  min?: number;
  max?: number;
  precision?: number;
  decimal_mark: "comma" | "period";
}

const STEP_SIZE = 0.01;
const PLACEHOLDER = 0.0;

const WidgetPrice: Widget<WidgetPriceProperties, WidgetResult<CurrencyResult>> = ({
  fieldState,
  setFieldState,
  readOnly,
}) => {
  const [localState, setLocalState] = useSyncedState(fieldState.value.rawValue?.value);

  const { precision, currency: unMappedCurrency, decimal_mark: decimalFormat } = fieldState.properties;
  const currency = toIsoCurrency(unMappedCurrency);

  const onChange = (values: NumberFormatValues): void => {
    setLocalState(values.floatValue);
  };

  const onBlur = async (e: FocusEvent<any, any>): Promise<void> => {
    const value = getFormValue(e);
    const rawValue = !isNil(value) ? { currency, value, decimalFormat, precision } : undefined;
    setFieldState(rawValue);
  };

  return (
    <WidgetContainer fieldState={fieldState} name="PRICE_FIELD">
      <NumberInput
        name={fieldState.uniqueFieldId}
        leftChild={toIsoCurrencySymbol(currency)}
        label={fieldState.properties.label_text}
        inputMode="decimal"
        disabled={readOnly}
        errorMessage={fieldState.error}
        required={fieldState.properties.required}
        step={STEP_SIZE}
        value={localState}
        onChange={onChange}
        onBlur={onBlur}
        onPlusMinus={
          isTouchCapable()
            ? (): void => {
                // Only switch real numbers and skip 0 because -0 is a quirky number
                const isSignSwitchable = !isNil(localState) && isFinite(localState) && localState !== 0;
                if (isSignSwitchable) {
                  setFieldState({ ...fieldState.value.rawValue, value: localState * -1 });
                }
              }
            : undefined
        }
        placeholder={toFormattedCurrency(PLACEHOLDER)}
        min={fieldState.properties.min}
        max={fieldState.properties.max}
        maxPrecision={fieldState.properties.precision ?? 2}
        showThousandSeparator
      />
    </WidgetContainer>
  );
};

WidgetPrice.defaultValue = (_properties, defaultMeta): WidgetResult<CurrencyResult> => ({
  type: "currency",
  meta: {
    widget: "price",
    ...defaultMeta,
  },
});

WidgetPrice.validate = (val, properties, t): string | undefined => validateCurrency(properties, val, t);

export default WidgetPrice;
