import { FC, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { first } from "lodash-es";
import useLocalSubmissions from "../hooks/useLocalSubmissions";
import SubmissionPage from "./SubmissionPage";
import { SubmissionSummary } from "../types/Submission";
import logger from "../utils/logger";
import { useMoreAppClient } from "../context/MoreAppContext";
import useAuth from "../hooks/useAuth";
import SubmissionNotFoundPage from "./errorpages/SubmissionNotFoundPage";
import OfflinePage from "./errorpages/OfflinePage";
import useOnlineStatus from "../hooks/useOnlineStatus";
import LoadingPage from "./LoadingPage";

const SubmissionPageResolver: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { isOnline } = useOnlineStatus();
  const client = useMoreAppClient();
  const { customerId } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { result, isFetching } = useLocalSubmissions((collection) => collection.find().where("id").equals(id));
  const submission = useMemo(() => first(result), [result]);

  useEffect(() => {
    // Try navigation to remote submission, when available
    if (submission == null && !isFetching) {
      client!
        .get<SubmissionSummary>(`/api/v1.0/client/customers/${customerId}/submissions/${id}`)
        .then((data) => navigate(`/sent/${data.data.id}`, { replace: true }))
        .catch(() => {
          logger.log(`Couldn't get completed submission ${id}, probably deleted or unprocessed`);
          location.key !== "default" ? navigate(-1) : navigate("/folders", { replace: true });
        });
    }
  }, [submission, isFetching, client, customerId, id, navigate, location]);

  if (isFetching) {
    return <LoadingPage conditions={[{ labelKey: "LOADING_ERROR_SUBMISSION", value: !result }]} />;
  }

  if (!submission) {
    return isOnline ? <SubmissionNotFoundPage /> : <OfflinePage />;
  }

  return <SubmissionPage submission={submission} />;
};

export default SubmissionPageResolver;
