import { Component } from "react";
import logger from "../utils/logger";

type ErrorBoundaryProps = {
  children: (hasError: boolean) => JSX.Element;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch(e: Error): void {
    logger.error("Hit error boundary", e);
  }

  render(): JSX.Element {
    return this.props.children(this.state.hasError);
  }
}
