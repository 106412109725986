import React, { FC, MutableRefObject, PropsWithChildren, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Icon } from "../Icon/Icon";
import { isTouchCapable } from "../../../utils/deviceUtil";

const SCROLL_OFFSET = 120;

const HorizontalListScroll: FC<PropsWithChildren> = ({ children }) => {
  const outer: MutableRefObject<any> = useRef();
  const inner: MutableRefObject<any> = useRef();

  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [hideRightArrow, setHideRightArrow] = useState(true);

  const outerClasses: string = "overflow-hidden overflow-x-auto scrollbar-none";
  const innerClasses = "flex w-max gap-x-2";

  useEffect(() => {
    if (!outer.current || !inner.current) {
      return () => undefined;
    }

    const outerRef = outer.current;

    const handleScroll = (): void => {
      const { scrollLeft } = outer.current;
      const diff = inner.current.getBoundingClientRect().width - outer.current.getBoundingClientRect().width;

      setHideLeftArrow(scrollLeft <= 0);
      setHideRightArrow(Math.round(diff) <= Math.round(scrollLeft));
    };

    outerRef.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);
    handleScroll();

    return () => {
      outerRef.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, [inner, outer]);

  const handleScroll = (direction: "left" | "right"): void => {
    if (!outer.current) {
      return;
    }
    const offset = direction === "right" ? SCROLL_OFFSET : -SCROLL_OFFSET;
    outer.current.scrollTo({ left: outer.current.scrollLeft + offset, behavior: "smooth" });
  };

  // Horizontal scroll buttons NOT needed for touch devices
  if (isTouchCapable()) {
    return (
      <div className={outerClasses}>
        <div className={innerClasses}>{children}</div>
      </div>
    );
  }

  return (
    <div className="relative">
      <ArrowButton direction="left" hidden={hideLeftArrow} onClick={() => handleScroll("left")} />
      <ArrowButton direction="right" hidden={hideRightArrow} onClick={() => handleScroll("right")} />
      <div className={outerClasses} ref={outer}>
        <div className={innerClasses} ref={inner}>
          {children}
        </div>
      </div>
    </div>
  );
};

interface ArrowButtonProps {
  direction: "left" | "right";
  hidden: boolean;
  onClick: () => void;
}

const ArrowButton: FC<ArrowButtonProps> = ({ direction, hidden, onClick }) => {
  const classnames = classNames(
    "absolute inset-y-0 top-1/2 flex size-8 -translate-y-1/2 items-center justify-center rounded-full bg-brand-100 text-gray-700 shadow transition active:!bg-brand-600 active:text-brand-50 pointer:hover:bg-brand-200",
    {
      "left-2": direction === "left",
      "right-2": direction === "right",
      "pointer-events-none opacity-0": hidden,
    },
  );
  return (
    <button onClick={onClick} className={classnames} aria-hidden="true" tabIndex={-1}>
      {direction === "left" ? <Icon name="ChevronLeftIcon" /> : <Icon name="ChevronRightIcon" />}
    </button>
  );
};

export default HorizontalListScroll;
