import { FC, useEffect, useMemo } from "react";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import { SplashScreen } from "@capacitor/splash-screen";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { QueryClient } from "@tanstack/react-query";
import { createAsyncStoragePersister } from "@tanstack/query-async-storage-persister";
import { HelmetProvider } from "react-helmet-async";
import TasksPage from "./pages/TasksPage";
import FoldersPage from "./pages/FoldersPage";
import DraftsPage from "./pages/DraftsPage";
import SentPage from "./pages/SentPage";
import StartPage from "./pages/StartPage";
import useAuth from "./hooks/useAuth";
import SettingsPage from "./pages/settings/SettingsPage";
import IntroPage from "./pages/IntroPage";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import DeveloperPage from "./pages/DeveloperPage";
import DirectUrlPage from "./pages/DirectUrlPage";
import SentSubmissionPage from "./pages/SentSubmissionPage";
import { createIDBPersister } from "./utils/persister";
import MigrationPage from "./pages/MigrationPage";
import AppState from "./components/AppState";
import CSP from "./components/CSP";
import ImpersonationWatermark from "./components/ImpersonationWatermark";
import RootPage from "./pages/RootPage";
import PrivateRoute from "./pages/PrivateRoute";
import NavigationPage from "./pages/NavigationPage";
import LoginCallbackPage from "./pages/LoginCallbackPage";
import LoadingPage from "./pages/LoadingPage";
import ProfilePage from "./pages/settings/ProfilePage";
import SupportPage from "./pages/settings/SupportPage";
import LanguagePage from "./pages/settings/LanguagePage";
import PasswordPage from "./pages/settings/PasswordPage";
import SettingsMigrationPage from "./pages/settings/SettingsMigrationPage";
import branding from "./utils/brandingUtil";
import BrandedStartPage from "./components/BrandedStartPage";
import { RedirectProvider } from "./context/RedirectContext";
import SubmissionPageResolver from "./pages/SubmissionPageResolver";
import { ErrorBoundary } from "./components/ErrorBoundary";
import useScrollActiveFieldIntoView from "./hooks/useScrollActiveFieldIntoView";
import PreviewPage from "./pages/PreviewPage";

const App: FC<{ queryClient: QueryClient }> = ({ queryClient }) => {
  const { authorization, isLoading, impersonatedUser, loggingIn } = useAuth();
  const isLoggedIn = authorization.type === "oauth";
  useScrollActiveFieldIntoView();

  useEffect(() => {
    if (!isLoading && Capacitor.isNativePlatform()) {
      setTimeout(SplashScreen.hide); // hide splash after render
    }
  }, [isLoading]);

  const persister = useMemo(
    () =>
      createAsyncStoragePersister({
        storage: createIDBPersister(),
        throttleTime: 5000,
        key: authorization.userId,
        serialize: (data) => {
          // eslint-disable-next-line no-param-reassign
          data.clientState.queries = data.clientState.queries.filter(
            (query) =>
              !query.queryKey.includes("datasources") &&
              !query.queryKey.includes("customer-resource") &&
              !query.queryKey.includes("formVersion"),
          );
          return JSON.stringify(data);
        },
      }),
    [authorization.userId],
  );

  if (isLoading || loggingIn) {
    return (
      <LoadingPage
        skipNavigation
        conditions={[
          { labelKey: "LOADING_ERROR_APP_LOADING_AUTH", value: isLoading },
          { labelKey: "LOADING_ERROR_APP_LOGGING_IN", value: loggingIn },
        ]}
      />
    );
  }

  const getStartPage = (): JSX.Element => {
    if (branding.isBranded()) {
      return <BrandedStartPage />;
    }

    if (localStorage.getItem("FINISHED_INTRO") === "true") {
      return <StartPage />;
    }

    return <IntroPage />;
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootPage />,
      errorElement: <Navigate to="/" />,
      children: [
        { index: true, element: <Navigate to="/folders" /> },
        { path: "login/callback", element: <LoginCallbackPage /> },
        { path: "signup", element: <IntroPage openSignup /> },
        {
          path: "login",
          element: getStartPage(),
        },
        { path: "intro/*", element: branding.isBranded() ? <Navigate to="/login" /> : <IntroPage /> },
        { path: "verify-email/*", element: <VerifyEmailPage /> },
        { path: "form/:token", element: <DirectUrlPage /> },
        { path: "form/:token/:formVersionId", element: <DirectUrlPage preview="default" /> },
        { path: "preview", element: <PreviewPage /> },
        { path: "submissions/:id/:token", element: <SubmissionPageResolver /> },
        { path: "migrate", element: isLoggedIn ? <MigrationPage /> : <Navigate to="/" /> },
        {
          element: <PrivateRoute />,
          children: [
            { path: "submissions/:id", element: <SubmissionPageResolver /> },
            { path: "sent/:id", element: <SentSubmissionPage /> },
            {
              element: <NavigationPage />,
              path: "/*",
              children: [
                { path: "folders/*", element: <FoldersPage /> },
                { path: "tasks/*", element: <TasksPage /> },
                { path: "drafts/*", element: <DraftsPage /> },
                { path: "sent/*", element: <SentPage /> },
                {
                  path: "settings/",
                  element: <SettingsPage />,
                  children: [
                    { path: "*", element: <Navigate to="/settings" /> },
                    { path: "profile/*", element: <ProfilePage /> },
                    { path: "password/*", element: <PasswordPage /> },
                    { path: "migration/*", element: <SettingsMigrationPage /> },
                    { path: "language/*", element: <LanguagePage /> },
                    { path: "support/*", element: <SupportPage /> },
                    { path: "developer/*", element: <DeveloperPage /> },
                  ],
                },
                { path: "developer/*", element: <DeveloperPage /> },
              ],
            },
          ],
        },
      ],
    },
  ]);

  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
      <HelmetProvider>
        <RedirectProvider>
          {impersonatedUser && <ImpersonationWatermark />}
          <CSP />
          <RouterProvider router={router} />
          {authorization.type === "oauth" && <ErrorBoundary>{() => <AppState />}</ErrorBoundary>}
        </RedirectProvider>
      </HelmetProvider>
    </PersistQueryClientProvider>
  );
};

export default App;
