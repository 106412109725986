/* eslint-disable consistent-return */
import { Capacitor } from "@capacitor/core";
import { AxiosProgressEvent } from "axios";
import { Directory } from "@capacitor/filesystem";
import mime from "mime";
import { useCallback } from "react";
import logger from "../utils/logger";
import { API_URL } from "../constants";
import { useMoreAppClient } from "../context/MoreAppContext";
import { FileResult } from "../types/Widget";
import useAuth from "./useAuth";
import { deleteFile, readFile, rmDir, writeFileBlob } from "../utils/fileSystemUtil";
import { filePathToUri, getExtensionBlob, getFilePath, uriToBlob } from "../utils/fileUtil";
import { UploadStatus } from "../types/Field";
import { seconds } from "../utils/timeUtil";

export type UploadResult = {
  fileResult: FileResult;
  uploadStatus: UploadStatus;
};

const LEGACY_GRIDFS_PREFIX = "gridfs://registrationFiles/";

const MAX_FILE_SIZE = 50 * 1024 * 1024;

export type UseFileHandlerResult = {
  storeFile: (blob: Blob, fileName: string, submissionId: string, extension?: string) => Promise<string>;
  uploadFile: (
    blob: Blob,
    id: string,
    submissionId: string,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
    abortSignal?: AbortSignal,
    fileName?: string,
    extension?: string,
  ) => Promise<UploadResult>;
  storeFileUri: (blobUri: string, submissionId: string, id: string, name?: string) => Promise<UploadResult>;
  uploadLocalFile: (
    fileId: string,
    submissionId: string,
    name?: string,
    extension?: string,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
    abortSignal?: AbortSignal,
  ) => Promise<UploadResult>;
  getFileUrl: (file: FileResult, submissionId: string, remotePath?: boolean) => Promise<string>;
  removeLocalFiles: (submissionId: string) => Promise<void>;
};

const useFileHandler = (): UseFileHandlerResult => {
  const client = useMoreAppClient();
  const { customerId, username } = useAuth();

  const storeFile = async (blob: Blob, fileName: string, submissionId: string, extension?: string): Promise<string> =>
    writeFileBlob({
      path: getFilePath(username!, customerId!, submissionId, fileName, extension),
      blob,
    });

  const uploadFile = async (
    blob: Blob,
    id: string,
    submissionId: string,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
    abortSignal?: AbortSignal,
    fileName?: string,
    extension?: string,
  ): Promise<UploadResult> => {
    const fileResult: FileResult = { id, name: fileName, extension };

    if (blob.size > MAX_FILE_SIZE) {
      return { fileResult, uploadStatus: "size_exceeded" };
    }

    try {
      const remoteId = await postBlob(blob, submissionId, onUploadProgress, abortSignal, fileName);
      return { fileResult: { ...fileResult, remoteId }, uploadStatus: "uploaded" };
    } catch (e) {
      if (abortSignal?.aborted) {
        return { fileResult, uploadStatus: "aborted" };
      }

      logger.warn("Could not upload file", id);
      return { fileResult, uploadStatus: "failed" }; // that's ok, we'll try again later
    }
  };

  const storeFileUri = async (
    blobUri: string,
    submissionId: string,
    id: string,
    name?: string,
  ): Promise<UploadResult> => {
    const blob = await uriToBlob(blobUri);
    const extension = getExtensionBlob(blob);
    const fileResult: FileResult = { id, name: name ?? id, extension };

    if (blob.size > MAX_FILE_SIZE) {
      return { fileResult, uploadStatus: "size_exceeded" };
    }

    try {
      await storeFile(blob, id, submissionId, extension);
    } catch (e) {
      logger.error("Could not store file", e);
      return { fileResult, uploadStatus: "error" };
    }

    return { fileResult, uploadStatus: "uploading" };
  };

  const getBlob = async (path: string, fileName?: string): Promise<Blob> => {
    const uri = await filePathToUri(path, getWebFile, fileName);
    return uriToBlob(uri);
  };

  const uploadLocalFile = async (
    fileId: string,
    submissionId: string,
    name?: string,
    extension?: string,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
    abortSignal?: AbortSignal,
  ): Promise<UploadResult> => {
    let blob: Blob;
    try {
      blob = await getBlob(getFilePath(username!, customerId!, submissionId, fileId, extension), name);
    } catch (e) {
      return { fileResult: { id: fileId, name, extension }, uploadStatus: "error" };
    }

    const result = await uploadFile(blob, fileId, submissionId, onUploadProgress, abortSignal, name, extension);
    if (!Capacitor.isNativePlatform() && result.uploadStatus === "uploaded") {
      try {
        await removeLocalFile(fileId, submissionId);
      } catch (e) {
        // Probably already deleted
      }
    }
    return result;
  };

  const getFileUrl = async (file: FileResult, submissionId: string, remotePath = false): Promise<string> => {
    // always use remote path for web or when the remote path is requested
    if ((!Capacitor.isNativePlatform() || remotePath) && file.remoteId) {
      return `${API_URL}/client/registrations/files/${file.remoteId}`;
    }

    try {
      const path = getFilePath(username!, customerId!, submissionId, file.id, file.extension);
      return await filePathToUri(path, getWebFile, file.name);
    } catch (e) {
      if (file.remoteId) {
        return `${API_URL}/client/registrations/files/${file.remoteId}`;
      }
      logger.error("Could not get file URL because it couldn't find the local path, and has no remoteId");
      throw e;
    }
  };

  const getWebFile = async (path: string, fileName?: string): Promise<string> => {
    const mimeType = fileName && mime.getType(fileName);
    const webFile = await readFile({ path, directory: Directory.Data });
    if (typeof webFile.data === "object") {
      // NEW (7.1.0): blob
      return URL.createObjectURL(webFile.data);
    }
    // LEGACY: base64 encoded string
    return `data:${mimeType || "image/jpeg"};base64,${webFile.data}`;
  };

  const removeLocalFile = async (fileName: string, submissionId: string, extension?: string): Promise<void> =>
    deleteFile({
      path: getFilePath(username!, customerId!, submissionId, fileName, extension),
    });

  const removeLocalFiles = useCallback(
    async (submissionId: string): Promise<void> =>
      rmDir({ path: `${username}/${customerId}/submissionFiles/${submissionId}` }).catch((e) => {
        logger.warn("Tried to delete local files", e); // That's ok, there are probably no files to delete
      }),
    [username, customerId],
  );

  const postBlob = async (
    blob: Blob,
    submissionId: string,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
    abortSignal?: AbortSignal,
    fileName?: string,
  ): Promise<any> => {
    const data = new FormData();
    data.append("file", blob, fileName ?? ""); // Use empty string instead of undefined to prevent file from showing up as 'blob.ext' in fileDetails
    data.append("customerNumber", `${customerId}`);
    data.append("hasuraSubmissionUuid", submissionId);
    const response = await client!.post("/api/v1.0/client/registrations/files", data, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress,
      signal: abortSignal,
      timeout: seconds(90), // Overriding the default. File uploads can take longer on unstable connections
    });
    return response.data.replace(LEGACY_GRIDFS_PREFIX, "");
  };

  return {
    storeFile,
    uploadFile,
    storeFileUri,
    uploadLocalFile,
    getFileUrl,
    removeLocalFiles,
  };
};

export default useFileHandler;
