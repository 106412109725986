import { t, TFunction } from "i18next";
import { v5 as uuidv5, validate } from "uuid";
import { UseFormSetError } from "react-hook-form";
import { clone, isNil, last } from "lodash-es";
import { FieldError } from "../context/MoreAppContext";
import { legacySubmissionId, removeWidgetVersionNumber } from "./stringUtil";
import { Widget } from "../types/Widget";
import WidgetText from "../components/widgets/WidgetText";
import WidgetTextarea from "../components/widgets/WidgetTextarea";
import WidgetNumber from "../components/widgets/WidgetNumber";
import WidgetEmail from "../components/widgets/WidgetEmail";
import WidgetHeader from "../components/widgets/WidgetHeader";
import WidgetLabel from "../components/widgets/WidgetLabel";
import WidgetPhone from "../components/widgets/WidgetPhone";
import WidgetRadio from "../components/widgets/WidgetRadio";
import WidgetDate from "../components/widgets/WidgetDate";
import WidgetDateTime from "../components/widgets/WidgetDateTime";
import WidgetTime from "../components/widgets/WidgetTime";
import WidgetSubform, { SubformEntry, WidgetSubformProperties } from "../components/widgets/WidgetSubform";
import WidgetCheckbox from "../components/widgets/WidgetCheckbox";
import WidgetSearch from "../components/widgets/WidgetSearch";
import WidgetBarcode from "../components/widgets/WidgetBarcode";
import WidgetLookup from "../components/widgets/WidgetLookup";
import WidgetSlider from "../components/widgets/WidgetSlider";
import WidgetRDW from "../components/widgets/WidgetRDW";
import WidgetImage from "../components/widgets/WidgetImage";
import WidgetSignature from "../components/widgets/WidgetSignature";
import WidgetPin, { WidgetPinProperties } from "../components/widgets/WidgetPin";
import WidgetPhoto from "../components/widgets/WidgetPhoto";
import WidgetRichText from "../components/widgets/WidgetRichText";
import WidgetPrice from "../components/widgets/WidgetPrice";
import WidgetFile from "../components/widgets/WidgetFile";
import WidgetHelp from "../components/widgets/WidgetHelp";
import WidgetRating from "../components/widgets/WidgetRating";
import WidgetStopwatch from "../components/widgets/WidgetStopwatch";
import WidgetSmiley from "../components/widgets/WidgetSmiley";
import WidgetTimeDifference from "../components/widgets/WidgetTimeDifference";
import WidgetIBAN from "../components/widgets/WidgetIBAN";
import WidgetPostcode from "../components/widgets/WidgetPostcode";
import WidgetCalculation from "../components/widgets/WidgetCalculation";
import WidgetCatalogue from "../components/widgets/WidgetCatalogue";
import WidgetDrawing from "../components/widgets/WidgetDrawing";
import WidgetVideo from "../components/widgets/WidgetVideo";
import WidgetReadOnlyText from "../components/widgets/WidgetReadOnlyText";
import WidgetBorderlessHeader from "../components/widgets/WidgetBorderlessHeader";
import WidgetParagraph from "../components/widgets/WidgetParagraph";
import WidgetCurrentLocation from "../components/widgets/WidgetCurrentLocation";
import { AbstractForm, FieldProperties, FormField, SubForm, WidgetProperties } from "../types/FormVersion";
import WidgetWeekNumber from "../components/widgets/WidgetWeekNumber";
import WidgetInstructionRichText from "../components/widgets/WidgetInstructionRichText";
import { Entry, Field, RememberedField, WidgetResult, WidgetResultMeta } from "../types/Field";
import WidgetDecimal from "../components/widgets/WidgetDecimal";
import WidgetLocation from "../components/widgets/WidgetLocation";
import { Form } from "../types/Folder";
import { FieldState, UniqueFieldId } from "../types/SubmissionState";

type WidgetInfo = {
  widgetKey: string;
  component: Widget<any, any>;
};

type WidgetValidationResult = (
  value: any,
  properties: any,
  t: TFunction<"translation", undefined>,
  meta: any,
  entries?: any,
  isFinal?: boolean,
) => string | undefined;

export const WidgetComponents: Partial<Record<string, WidgetInfo>> = {
  "com.moreapps:text": { widgetKey: "text", component: WidgetText },
  "com.moreapps:text_area": { widgetKey: "textarea", component: WidgetTextarea },
  "com.moreapps:number": { widgetKey: "number", component: WidgetNumber },
  "com.moreapps:email": { widgetKey: "email", component: WidgetEmail },
  "com.moreapps:header": { widgetKey: "header", component: WidgetHeader },
  "com.moreapps:label": { widgetKey: "label", component: WidgetLabel },
  "com.moreapps:phone": { widgetKey: "phone", component: WidgetPhone },
  "com.moreapps:radio": { widgetKey: "radio", component: WidgetRadio },
  "com.moreapps:date": { widgetKey: "date", component: WidgetDate },
  "com.moreapps:datetime": { widgetKey: "datetime", component: WidgetDateTime },
  "com.moreapps:time": { widgetKey: "time", component: WidgetTime },
  "com.moreapps:detail": { widgetKey: "subform", component: WidgetSubform },
  "com.moreapps:checkbox": { widgetKey: "checkbox", component: WidgetCheckbox },
  "com.moreapps:search": { widgetKey: "search", component: WidgetSearch },
  "com.moreapps:barcode": { widgetKey: "barcode", component: WidgetBarcode },
  "com.moreapps:lookup": { widgetKey: "lookup", component: WidgetLookup },
  "com.moreapps:slider": { widgetKey: "slider", component: WidgetSlider },
  "com.moreapps:rdw": { widgetKey: "rdw", component: WidgetRDW },
  "com.moreapps:image": { widgetKey: "image", component: WidgetImage },
  "com.moreapps:signature": { widgetKey: "signature", component: WidgetSignature },
  "com.moreapps:pin": { widgetKey: "pin", component: WidgetPin },
  "com.moreapps:photo": { widgetKey: "photo", component: WidgetPhoto },
  "com.moreapps:html": { widgetKey: "richText", component: WidgetRichText },
  "com.moreapps:location": { widgetKey: "location", component: WidgetLocation },
  "com.moreapps.plugin:smiley": { widgetKey: "smiley", component: WidgetSmiley },
  "com.moreapps.plugins:price": { widgetKey: "price", component: WidgetPrice },
  "com.dirkjanhoek:price": { widgetKey: "price", component: WidgetPrice },
  "com.moreapps.plugins:drawing": { widgetKey: "drawing", component: WidgetDrawing },
  "com.moreapps.plugins:file": { widgetKey: "file", component: WidgetFile },
  "com.moreapps.plugins:help": { widgetKey: "help", component: WidgetHelp },
  "com.moreapps.plugins:rating": { widgetKey: "rating", component: WidgetRating },
  "com.moreapps.plugins:stopwatch": { widgetKey: "stopwatch", component: WidgetStopwatch },
  "com.moreapps.plugins:timecalculation": { widgetKey: "timeDifference", component: WidgetTimeDifference },
  "com.moreapps.plugins:iban": { widgetKey: "iban", component: WidgetIBAN },
  "com.moreapps.plugins:zipcode": { widgetKey: "postcode", component: WidgetPostcode },
  "com.moreapps.plugins:calculation": { widgetKey: "calculation", component: WidgetCalculation },
  "com.moreapps.plugins:catalogue": { widgetKey: "catalogue", component: WidgetCatalogue },
  "com.moreapps.plugins:video": { widgetKey: "video", component: WidgetVideo },
  "com.dirkjanhoek:currentlocation": { widgetKey: "currentLocation", component: WidgetCurrentLocation },
  "nl.gildesoftware:readonlytext": { widgetKey: "readOnlyText", component: WidgetReadOnlyText },
  "nl.stijlaart.mats:borderlessheader": { widgetKey: "borderlessHeader", component: WidgetBorderlessHeader },
  "nl.robin:paragraph": { widgetKey: "paragraph", component: WidgetParagraph },
  "com.landien:weeknumber": { widgetKey: "weekNumber", component: WidgetWeekNumber },
  "com.landien:weeknumberc": { widgetKey: "weekNumber", component: WidgetWeekNumber },
  "com.landien:weeknumberfordedicatedcustomers": { widgetKey: "weekNumber", component: WidgetWeekNumber },
  "com.moreapps.plugins:bolsiusgpssearch": { widgetKey: "search", component: WidgetSearch },
  "com.samsonit.instructionhtml:instructionhtml": {
    widgetKey: "instructionRichText",
    component: WidgetInstructionRichText,
  },
  "clearsolutions:decimal": { widgetKey: "decimal", component: WidgetDecimal },
};

export const setFieldErrors = (fieldErrors: FieldError[], setError: UseFormSetError<any>): void => {
  fieldErrors.forEach((constraintViolation) => {
    const path = last(constraintViolation.path.split("."))!;
    setError(path, {
      type: "custom",
      message: t(constraintViolation.code),
    });
  });
};

export const getDefaultMeta = (
  field: FormField<any>,
  fieldId: UniqueFieldId,
  submissionId?: string,
  parentId?: UniqueFieldId,
  entryId?: string,
): Omit<WidgetResultMeta, "widget" | "hidden" | "evaluatedRules" | "order"> => ({
  formFieldId: field.uid,
  submissionId,
  parentId,
  fieldId,
  entryId,
  dataName: field.properties.data_name,
  compressed: false,
  humanEdited: false,
});

export const getCalculatedFieldId = (
  fieldUid: string,
  submissionId: string,
  entryId?: string,
  parentId?: UniqueFieldId,
): UniqueFieldId => {
  const id = validate(submissionId) ? submissionId : legacySubmissionId(submissionId);
  return uuidv5(parentId ? `${fieldUid}-${parentId}-${entryId}` : fieldUid, id) as UniqueFieldId;
};

export const highestOrder = (entries?: SubformEntry<any>[]): number =>
  Math.max(...(entries?.filter((e) => !e.deleted).map((el) => el.meta?.order ?? 0) ?? [0]), 0);

export const getInitialValue = <T extends WidgetProperties>(
  fieldId: UniqueFieldId,
  formField: FormField<T>,
  submissionId?: string,
  rememberedField?: RememberedField,
  entryId?: string,
  parentId?: UniqueFieldId,
): WidgetResult<unknown> => {
  const defaultValue = getDefaultValue(fieldId, formField, submissionId, entryId, parentId);
  return rememberedField ? { ...defaultValue, rawValue: clone(rememberedField.data) } : defaultValue;
};

export const getDefaultValue = <T extends WidgetProperties>(
  fieldId: UniqueFieldId,
  formField: FormField<T>,
  submissionId?: string,
  entryId?: string,
  parentId?: UniqueFieldId,
): WidgetResult<unknown> => {
  const widgetId = removeWidgetVersionNumber(formField.widget);
  const widgetComponent = getWidgetComponent(widgetId);
  if (widgetComponent === undefined) {
    throw new Error(`Widget with id '${widgetId}' does not exist`);
  }

  // All widgets are required to have a `defaultValue` function
  const meta = getDefaultMeta(formField, fieldId, submissionId, parentId, entryId);
  return widgetComponent.defaultValue(formField.properties, meta);
};

export const compareByLocale = (a: string, b: string): number =>
  a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" });

export const getFormVersionForField = (formVersions: AbstractForm[], formFieldId: string): AbstractForm | undefined =>
  formVersions.find((x) => x.fields.find((y) => y.uid === formFieldId));

export const getFieldFromFormVersions = (
  formVersions: AbstractForm[],
  formFieldId: string,
): FormField<any> | undefined =>
  formVersions.flatMap((formVersion) => formVersion.fields).find((x) => x.uid === formFieldId);

export const isUsable = (form: Form): boolean => form.status === "ACTIVE" && !!form.publishedVersion.formVersion; // Remove trashed, hidden and unpublished forms

const getWidgetComponent = (widget: string): Widget<any, any> | undefined => WidgetComponents[widget]?.component;

export const getWidgetOnUploadCompleteByWidgetKey: (key: string) => ((value: any) => Promise<any>) | undefined = (
  key: string,
) => Object.values(WidgetComponents).find((entry) => entry?.widgetKey === key)?.component?.onUploadComplete;

const getWidgetValidation = (widget: string): WidgetValidationResult | undefined =>
  getWidgetComponent(widget)?.validate;

export const getWidgetProperties = (widget: string, properties: WidgetProperties): WidgetProperties => {
  const mapPropertiesFn = getWidgetComponent(removeWidgetVersionNumber(widget))?.mapProperties;
  if (!isNil(mapPropertiesFn)) {
    return mapPropertiesFn(properties);
  }
  return properties;
};

export const validateFieldState = (
  fieldState: FieldState<WidgetProperties, WidgetResult<unknown>>,
  validateAll?: boolean,
): string | undefined => {
  if (!fieldState.visible) {
    return undefined;
  }
  const validator = getWidgetValidation(removeWidgetVersionNumber(fieldState.widget));
  if (!validator) {
    return undefined;
  }
  return validator(
    fieldState.value.rawValue,
    fieldState.properties,
    t,
    fieldState.value.meta,
    fieldState.value.entries,
    validateAll,
  );
};

export const getPinFormVersions = (
  formField: FormField<WidgetPinProperties>,
  fieldProperties: FieldProperties,
): SubForm[] =>
  (formField.properties.pins ?? [])
    ?.map((pin) => (pin.target_form_id ? fieldProperties[pin.target_form_id] : pin.form))
    .filter((item): item is SubForm => !!item);

export const getSubformVersion = (
  formField: FormField<WidgetSubformProperties>,
  fieldProperties: FieldProperties,
): SubForm =>
  formField.properties.target_form_id
    ? fieldProperties[formField.properties.target_form_id]
    : formField.properties.form;

export const getFormVersionForEntry = (
  field: Field,
  entry: Entry,
  formVersions: AbstractForm[],
  fieldProperties: FieldProperties,
): AbstractForm | undefined => {
  const formField = getFieldFromFormVersions(formVersions, field.formFieldId);
  if (formField?.widget === "com.moreapps:detail:1") {
    return getSubformVersion(formField, fieldProperties);
  }
  if (formField?.widget === "com.moreapps:pin:1") {
    return getPinFormVersions(formField, fieldProperties).find(({ uid }) => entry.meta.scope.target === uid);
  }
  return undefined;
};

/**
 * Detects empty cases like: undefined, null, [], "", {}
 * Skips falsy but valid form values like: false, 0 (zero)
 * @param value
 */
export const hasEmptyValue = (value: unknown): boolean =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);
