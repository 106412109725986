import { useState } from "react";
import { SemVer } from "semver";
import { getCurrentVersion } from "../utils/versionUtil";
import { useAsyncEffect } from "./useAsyncEffect";

type UseCurrentAppVersionResult = {
  currentVersion: SemVer | undefined;
};

const useCurrentAppVersion = (): UseCurrentAppVersionResult => {
  const [currentVersion, setCurrentVersion] = useState<SemVer>();

  useAsyncEffect(
    async () => {
      const current = (await getCurrentVersion()) ?? undefined;
      setCurrentVersion(current);
    },
    async () => {},
    [],
  );

  return { currentVersion };
};

export default useCurrentAppVersion;
