import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { round } from "lodash-es";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import { RangeInput } from "../../storybook/components/RangeInput/RangeInput";
import useSyncedState from "../../hooks/useSyncedState";
import WidgetContainer from "../WidgetContainer";

export interface WidgetSliderProperties {
  required: boolean;
  label_text: string;
  min?: number;
  max?: number;
  step?: number;
  default_value?: number;
}

const WidgetSlider: Widget<WidgetSliderProperties, WidgetResult<number>> = ({
  fieldState,
  setFieldState,
  readOnly,
}) => {
  const { t } = useTranslation();
  const [localState, setLocalState] = useSyncedState(fieldState.value.rawValue);

  const showClearButton = useMemo(() => {
    if (readOnly) {
      return false;
    }
    return !!(fieldState.value.rawValue || fieldState.value.rawValue === 0);
  }, [readOnly, fieldState.value.rawValue]);

  return (
    <WidgetContainer fieldState={fieldState} name="SLIDER_FIELD">
      <RangeInput
        name={fieldState.uniqueFieldId}
        value={localState}
        errorMessage={fieldState.error}
        onChange={(event) => setLocalState(event.target.value)}
        onBlur={() => setFieldState(localState)}
        onClear={() => setFieldState(undefined)}
        clearLabel={t("CLEAR")}
        showClearBtn={showClearButton}
        min={fieldState.properties.min}
        max={fieldState.properties.max}
        step={fieldState.properties.step}
        label={fieldState.properties.label_text}
        required={fieldState.properties.required}
        disabled={readOnly}
      />
    </WidgetContainer>
  );
};

WidgetSlider.defaultValue = (properties, defaultMeta): WidgetResult<number> => ({
  type: "number",
  rawValue: properties.default_value,
  meta: {
    widget: "slider",
    ...defaultMeta,
  },
});

WidgetSlider.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  const min = properties.min ?? -Infinity;
  const max = properties.max ?? Infinity;
  const step = properties.step ?? 1;
  const hasValue = val || val === 0;
  if (required && !hasValue) {
    return t("VALIDATION_REQUIRED");
  }

  if (hasValue) {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(val)) {
      return t("VALIDATION_NUMBER_NAN");
    }

    if (val < min) {
      return t("VALIDATION_NUMBER_MIN", { min });
    }
    if (val > max) {
      return t("VALIDATION_NUMBER_MAX", { max });
    }

    // Floating points are not infinitely precise, this means that modulos can fluctuate with a tiny amount.
    // Check whether the remainder comes close enough to zero or the step-size
    const remainder = Math.abs(round(val % step, 10));
    if (remainder !== 0 && remainder !== step) {
      return t("VALIDATION_NUMBER_STEP", { step });
    }
  }

  return undefined;
};

export default WidgetSlider;
