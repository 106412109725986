import { createContext, FC, PropsWithChildren, useEffect, useState } from "react";
import { DeviceInfo, getCurrentDevice } from "../utils/deviceUtil";
import logger from "../utils/logger";

export const DeviceInfoProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>({} as DeviceInfo);

  useEffect(() => {
    const setInfo = async (): Promise<void> => {
      const info = await getCurrentDevice();
      setDeviceInfo(info);
    };
    setInfo().catch((e) => logger.error("Could not set device info", e));
  }, []);

  return <DeviceInfoContext.Provider value={deviceInfo}>{children}</DeviceInfoContext.Provider>;
};

export const DeviceInfoContext = createContext<DeviceInfo>({} as DeviceInfo);
