import { isArray, isEmpty } from "lodash-es";
import { FormRule, FormRuleAction, FormRuleCondition, WidgetProperties } from "../types/FormVersion";
import { DataSourceEntry } from "../types/Datasource";
import { templateRegex } from "./interpolateUtil";
import { Field, fieldToWidgetResult, WidgetDataTypes } from "../types/Field";
import { FieldMap, RuleResult } from "../types/Rules";
import { CurrencyResult } from "../types/Widget";
import { getTemplatedContent } from "./templateUtil";
import { DateTimeValue } from "../storybook/components/DateTimeInput/DateTimeInput";
import { toIsoCurrency } from "./currencyUtil";
import { WidgetPriceProperties } from "../components/widgets/WidgetPrice";
import { SubmissionFormData } from "../components/Form";

const ALLOWED_TYPES = ["string", "number", "boolean", "object"];
const NATIVE_TEMPLATE_TYPES: WidgetDataTypes[] = ["datetime", "date"];

const getConditionValue = (
  condition: FormRuleCondition,
  scopedFields: Map<string, Field>,
  fields: Field[],
  username?: string,
): any => {
  switch (condition.type) {
    case "FIELD":
      return getFieldConditionValue(scopedFields, fields, condition);
    case "FIELD_DATA_SOURCE":
      // eslint-disable-next-line no-case-declarations
      const dataSourceItem = scopedFields.get(condition.fieldUid!)?.data as DataSourceEntry;
      return dataSourceItem?.data[condition?.fieldObjectKey || ""];
    case "USER":
      return username;
  }
  return null;
};

const isConditionMet = (
  condition: FormRuleCondition,
  scopedFields: Map<string, Field>,
  fields: Field[],
  username: string,
): boolean => {
  if (isNullish(condition.value)) {
    return false;
  }
  const value = getConditionValue(condition, scopedFields, fields, username);
  const filled = !isNullish(value);

  if (!ALLOWED_TYPES.includes(typeof value) && filled) {
    return false;
  }

  const strValue = !filled || isString(value) ? value : JSON.stringify(value);
  const conditionValue = condition.value.toString();
  switch (condition.key) {
    case "is":
      return filled && (strValue === conditionValue || (isArray(value) && value.includes(conditionValue)));
    case "startsWith":
      return filled && strValue.startsWith(conditionValue);
    case "endsWith":
      return filled && strValue.endsWith(conditionValue);
    case "contains":
      return filled && strValue.includes(conditionValue);
    case "greaterThan":
      return filled && gt(strValue, conditionValue);
    case "lessThan":
      return filled && lt(strValue, conditionValue);
    case "hasValue":
      return (filled && strValue !== "" && strValue !== "[]") === condition.value;
    default:
      return false;
  }
};

const isHumanReadable = (fields: Map<string, Field>, action: FormRuleAction): boolean => {
  const type = fields.get(action.fieldUid)?.type ?? "string";
  return !NATIVE_TEMPLATE_TYPES.includes(type);
};

const getAction = (action: FormRuleAction, conditionsMet: boolean, fields: FieldMap): RuleResult | undefined => {
  if (action.key === "visible") {
    const visible = conditionsMet ? !!action.value : !action.value;
    return { type: "SET_VISIBILITY", visible };
  }
  if (action.key === "value" && conditionsMet) {
    const inputData = getInputData(Array.from(fields.values()));
    const value =
      typeof action.value === "boolean"
        ? action.value
        : action.value
            .replace(templateRegex, (_match, path) => {
              const field = inputData[path.split(".")[0]];
              const humanReadable = isHumanReadable(fields, action);
              return getTemplatedContent(path, field, { humanReadable });
            })
            .trim();
    return { type: "SET_VALUE", value };
  }
  return undefined;
};

const getActions = (field: Field, rule: FormRule, conditionsMet: boolean, fields: Map<string, Field>): RuleResult[] =>
  rule.actions
    .filter((x) => x.fieldUid === field.formFieldId)
    .map((action) => getAction(action, conditionsMet, fields) as RuleResult)
    .filter((x) => x);

export const getRuleActions = (
  field: Field,
  rule: FormRule,
  scopedFields: Map<string, Field>,
  fields: Field[],
  username: string,
): RuleResult[] => {
  const conditionsMet =
    rule.type === "OR"
      ? rule.conditions.some((c) => isConditionMet(c, scopedFields, fields, username))
      : rule.conditions.every((c) => isConditionMet(c, scopedFields, fields, username));
  return getActions(field, rule, conditionsMet, scopedFields);
};

const getFieldConditionValue = (
  scopedFields: Map<string, Field>,
  fields: Field[],
  condition: FormRuleCondition,
): {} | null => {
  const field = scopedFields.get(condition.fieldUid!); // Always set if condition type is not USER
  if (isNullish(field?.data) && field?.entries.length === 0) {
    return null;
  }
  if (field && field.entries.filter((x) => !x.deleted)?.length > 0) {
    const fieldData = fields
      .filter((x) => x.parentId === field.id && !x._deleted) // eslint-disable-line no-underscore-dangle
      .map((x) => x.data);
    return isEmpty(fieldData) ? field.entries : fieldData;
  }
  switch (field?.type) {
    case "currency":
      return getCurrencyData(field);
    case "datetime":
      return getDateTimeData(field);
    default:
      return field?.data ?? null;
  }
};

const getCurrencyData = (field: Field): number | null => {
  const currencyValue = field.data as CurrencyResult;
  return currencyValue?.value ?? null;
};

const getDateTimeData = (field: Field): string | null => {
  const dateTimeValue = field.data as DateTimeValue;
  if (!dateTimeValue.date || !dateTimeValue.time) {
    return null;
  }
  return `${dateTimeValue.date} ${dateTimeValue.time}`;
};

export const getValueForType = (type: WidgetDataTypes, widgetProperties: WidgetProperties, value: Object): Object => {
  if (type === "currency") {
    const properties = widgetProperties as WidgetPriceProperties;
    return {
      ...value,
      currency: toIsoCurrency(properties.currency),
      decimalFormat: properties.decimal_mark,
    };
  }
  return value;
};

const lt = (a: string, b: string): boolean => parseFloat(replaceComma(a)) < parseFloat(replaceComma(b));
const gt = (a: string, b: string): boolean => parseFloat(replaceComma(a)) > parseFloat(replaceComma(b));
const isString = (value: any): boolean => typeof value === "string";
const isNullish = (value: any): boolean => value === null || value === undefined;
const replaceComma = (value: string): string => value.replace(",", ".");
const getInputData = (fields: Field[]): SubmissionFormData =>
  fields
    .filter((item) => item)
    .reduce((acc, field) => {
      const { dataName } = field;
      if (dataName) {
        acc[dataName] = fieldToWidgetResult(field);
      }
      return acc;
    }, {} as SubmissionFormData);
