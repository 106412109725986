import { WithDeleted } from "rxdb";
import { AppInfo, Connection, DeviceInfo } from "../utils/deviceUtil";
import { Location } from "../utils/locationUtil";
import { CustomThemeName } from "../storybook/themes";

export const StatusMessage = {
  uploadsFailed: "STATUS_MESSAGE_FAILED_UPLOADS",
} as const;

export type StatusMessageKey = keyof typeof StatusMessage;

export type RemoteSubmission = {
  id: string;
  customerId: number;
  formId: string;
  formVersionId: string;
  description?: string;
  status: "draft" | "final";
  createdAt: string;
  updatedAt: string;
  viewedAt?: string;
  submittedAt?: string;
  meta: Record<string, any>;
  form: SubmissionForm;
  task?: SubmissionTask;
  deleted: boolean;
};

export type Submission = {
  id: string;
  customerId: number;
  formId: string;
  formVersionId: string;
  description?: string;
  status: "draft" | "final";
  createdAt: string;
  updatedAt: string;
  viewedAt?: string;
  submittedAt?: string;
  meta: Partial<{
    app: AppInfo;
    device: DeviceInfo;
    connection: Connection;
    location?: Location;
    statusMessage?: StatusMessageKey;
  }>;
  form: SubmissionForm;
  type: "draft" | "task";
  task?: SubmissionTask;
  sendType?: "manual" | "remote_trigger";
};

export type SubmissionForm = {
  name: string;
  icon: string;
  iconColor: CustomThemeName;
  description: string;
};

export type SubmissionTask = {
  id: string;
  message: string;
  dueDate?: string;
};

export type SubmissionLocation = {
  coordinates?: {
    latitude: number;
    longitude: number;
  };
};

export const sentSubmissionToLocal = (doc: RemoteSubmission): Submission => ({
  id: doc.id,
  customerId: doc.customerId,
  formId: doc.formId,
  formVersionId: doc.formVersionId,
  description: doc.description,
  meta: doc.meta,
  form: doc.form,
  task: doc.task,
  createdAt: doc.createdAt,
  updatedAt: doc.updatedAt,
  viewedAt: doc.viewedAt,
  submittedAt: doc.submittedAt,
  status: doc.status,
  type: doc.task ? "task" : "draft",
});

export const submissionToRemoteHasura = (doc: WithDeleted<Submission>): RemoteSubmission => ({
  id: doc.id,
  customerId: doc.customerId,
  formId: doc.formId,
  formVersionId: doc.formVersionId,
  description: doc.description,
  meta: doc.meta,
  form: doc.form,
  task: doc.task,
  createdAt: doc.createdAt,
  updatedAt: doc.updatedAt,
  viewedAt: doc.viewedAt,
  submittedAt: doc.submittedAt,
  status: doc.status,
  deleted: doc._deleted, // eslint-disable-line no-underscore-dangle
});

export const submissionToRemote = (doc: Submission): RemoteSubmission => ({
  id: doc.id,
  customerId: doc.customerId,
  formId: doc.formId,
  formVersionId: doc.formVersionId,
  description: doc.description,
  meta: doc.meta,
  createdAt: doc.createdAt,
  updatedAt: doc.updatedAt,
  viewedAt: doc.viewedAt,
  submittedAt: doc.submittedAt,
  status: doc.status,
  form: doc.form,
  task: doc.task,
  deleted: false,
});

export const remoteSubmissionToLocal = (doc: WithDeleted<RemoteSubmission>): WithDeleted<Submission> => ({
  id: doc.id,
  customerId: doc.customerId,
  formId: doc.formId,
  formVersionId: doc.formVersionId,
  description: doc.description,
  status: doc.status,
  createdAt: doc.createdAt,
  updatedAt: doc.updatedAt,
  viewedAt: doc.viewedAt,
  submittedAt: doc.submittedAt,
  meta: doc.meta,
  form: doc.form,
  task: doc.task,
  sendType: "remote_trigger",
  type: doc.task ? "task" : "draft",
  _deleted: doc._deleted, // eslint-disable-line no-underscore-dangle
});

export type SubmissionSummary = {
  id: string;
  description: string;
  registrationDate: number;
  formId: string;
  formName: string;
  formDescription: string;
  formIcon: string;
  formIconColor: string;
  submittedBy: string;
  serialNumber: number;
  submitLocation?: SubmissionLocation;
  status: "PROCESSED" | "UNPROCESSED";
};
