import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { isNil, isString } from "lodash-es";
import { LocationResult, Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import { getLocation, getReverseLocation, Location as Loc } from "../../utils/locationUtil";
import { LOCATION_BASE_URL } from "../../constants";
import useDrawer from "../../hooks/useDrawer";
import LeafletMap from "./location/LeafletMap";
import { locationToHumanReadableString, toNumber } from "../../utils/stringUtil";
import Img from "../Img";
import useOnlineStatus from "../../hooks/useOnlineStatus";
import logger from "../../utils/logger";
import AddressDrawer from "./location/AddressDrawer";
import { Icon } from "../../storybook/components/Icon/Icon";
import { Text } from "../../storybook/components/Text/Text";
import { IconButton } from "../../storybook/components/IconButton/IconButton";
import { Label } from "../../storybook/components/Label/Label";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";
import { Drawer } from "../../storybook/components/Drawer/Drawer";
import { Feedback } from "../../storybook/components/Feedback/Feedback";
import useSyncedState from "../../hooks/useSyncedState";
import WidgetContainer from "../WidgetContainer";
import { useFocusId } from "../../hooks/useFocusId";

type FetchingState = "FETCHING" | "FAILED" | "FINISHED";

export interface WidgetLocationProperties {
  required: boolean;
  label_text: string;
  initial_current_location: boolean;
}

const WidgetLocation: Widget<WidgetLocationProperties, WidgetResult<LocationResult>> = ({
  fieldState,
  setFieldState,
  readOnly,
}) => {
  const { t } = useTranslation();
  const { rawValue } = fieldState.value;
  const [localState, setLocalState] = useSyncedState(rawValue);
  const [drawerOpen, setDrawerOpen] = useDrawer(`${fieldState.uid}-map`);
  const [editDrawerOpen, setEditDrawerOpen] = useDrawer(`${fieldState.uid}-edit`);
  const { isOnline } = useOnlineStatus();
  const [latLong, setLatLong] = useState<Loc | undefined>();
  const [fetchingState, setFetchingState] = useState<FetchingState>("FINISHED");
  const [isSaving, setSaving] = useState(false);
  const onCloseFocusId = useFocusId(fieldState);

  const onDrawerSubmit = async (values: LocationResult): Promise<void> => {
    if (!values.coordinates) {
      return;
    }
    const { latitude, longitude } = values.coordinates;
    const coordinates = {
      latitude: isString(latitude) ? toNumber(latitude, 6) : latitude,
      longitude: isString(longitude) ? toNumber(longitude, 6) : longitude,
    };
    setFieldState({ ...values, formattedValue: locationToHumanReadableString({ ...values, coordinates }) });
    setEditDrawerOpen(false);
    setDrawerOpen(false);
  };

  useEffect(() => {
    if (fieldState.properties.initial_current_location && !rawValue && !readOnly) {
      const saveCurrentLocation = async (): Promise<void> => {
        setFetchingState("FETCHING");
        let coordinates;

        try {
          coordinates = await getLocation();
          const locationResult = await getReverseLocation({
            lat: coordinates.latitude,
            lng: coordinates.longitude,
          });
          setFieldState(locationResult, { humanEdited: false });
          setFetchingState("FINISHED");
        } catch (e) {
          if (coordinates) {
            const formattedValue = locationToHumanReadableString({ coordinates });
            setFieldState({ coordinates, formattedValue }, { humanEdited: false });
            setFetchingState("FINISHED");
          } else {
            setFetchingState("FAILED");
          }
        } finally {
          setLatLong(coordinates);
        }
      };
      saveCurrentLocation().catch((e) => logger.error("Could not save current location", e));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps -- load current location on initial render

  const mapSrc = useMemo(() => {
    if (!rawValue?.coordinates) {
      return "/assets/image-error.svg";
    }
    const { latitude, longitude } = rawValue.coordinates;
    return `${LOCATION_BASE_URL}/v2/location/static?height=${256}&width=${544}&latitude=${latitude}&longitude=${longitude}`;
  }, [rawValue]);

  const mapImg = (className?: string): JSX.Element => (
    <div className="relative">
      {isOnline ? (
        <Img
          alt={rawValue?.formattedValue}
          className={classNames("aspect-location w-full rounded bg-gray-200", className)}
          src={mapSrc}
        />
      ) : (
        <div className="grid aspect-location w-full place-items-center rounded bg-gray-200">
          <Icon name="LocationMarkerIcon" type="solid" className="size-10 -translate-y-2 text-red-500" />
        </div>
      )}
      <Text size="sm" color="dark-light" className="mt-1">
        {rawValue?.formattedValue ?? ""}
      </Text>
    </div>
  );

  const mapElement = !readOnly ? (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className="relative"
      onClick={() => {
        if (!rawValue?.coordinates) {
          return;
        }
        setLocalState(rawValue);
        isOnline ? setDrawerOpen(!drawerOpen) : setEditDrawerOpen(!editDrawerOpen);
      }}
    >
      <div className="absolute right-2 top-2 z-20">
        <IconButton
          id={onCloseFocusId}
          aria-label={t("EDIT_LOCATION")}
          icon="PencilIcon"
          size="md"
          data-testid="edit-button"
        />
      </div>
      {mapImg(isOnline ? "cursor-pointer" : "")}
    </div>
  ) : (
    mapImg()
  );

  return (
    <WidgetContainer fieldState={fieldState} name="LOCATION_FIELD">
      <Label
        id={fieldState.uniqueFieldId}
        label={fieldState.properties.label_text}
        required={fieldState.properties.required}
        showClearBtn={!isNil(rawValue) && !readOnly}
        onClear={() => setFieldState(undefined)}
        clearLabel={t("CLEAR")}
      />
      {rawValue && rawValue.coordinates ? (
        mapElement
      ) : (
        <IconAndTextButton
          disabled={readOnly || fetchingState === "FETCHING"}
          label={!readOnly ? t("SELECT_LOCATION") : t("NO_LOCATION_ADDED")}
          loading={fetchingState === "FETCHING"}
          icon="MapIcon"
          onClick={() => (isOnline ? setDrawerOpen(!drawerOpen) : setEditDrawerOpen(!editDrawerOpen))}
          data-testid="location-button"
          block
        />
      )}
      <Drawer
        onCloseFocusId={onCloseFocusId}
        open={drawerOpen}
        header={{
          kind: "simple",
          title: t("SELECT_LOCATION"),
          button: {
            kind: "icon",
            icon: "XIcon",
            onClick: () => setDrawerOpen(false),
          },
        }}
        onClose={() => setDrawerOpen(false)}
        footer={{
          kind: "default",
          primaryButton: {
            label: t("SAVE"),
            onClick: async () => {
              if (localState) {
                setSaving(true);
                setFieldState(localState);
                setSaving(false);
              }
              setDrawerOpen(false);
            },
            disabled: !localState || isSaving,
            loading: isSaving,
          },
        }}
        contentPadding={false}
      >
        <LeafletMap
          userPosition={
            latLong && {
              lat: latLong.latitude,
              lng: latLong.longitude,
            }
          }
          markerPosition={localState}
          setMarkerPosition={setLocalState}
          onEdit={() => setEditDrawerOpen(true)}
          centerOnMarker={!!rawValue}
        />
        <AddressDrawer
          open={editDrawerOpen}
          setOpen={setEditDrawerOpen}
          onSubmit={onDrawerSubmit}
          defaultValues={localState}
        />
      </Drawer>
      {fieldState.error && <Feedback status="error" message={fieldState.error} />}
    </WidgetContainer>
  );
};

WidgetLocation.defaultValue = (_properties, defaultMeta: any): WidgetResult<LocationResult> => ({
  type: "location",
  meta: {
    widget: "location",
    ...defaultMeta,
  },
});

WidgetLocation.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }

  return undefined;
};

export default WidgetLocation;
