import i18n from "i18next";

export enum LanguageCode {
  EN = "en",
  NL = "nl",
  DE = "de",
  ES = "es",
  FR = "fr",
  PT = "pt",
  CA = "ca",
}

export const SUPPORTED_LANGUAGES = [
  { code: LanguageCode.EN, iso: "en-GB", label: "English" },
  { code: LanguageCode.NL, iso: "nl-NL", label: "Nederlands" },
  { code: LanguageCode.DE, iso: "de-DE", label: "Deutsch" },
  { code: LanguageCode.ES, iso: "es-ES", label: "Español" },
  { code: LanguageCode.FR, iso: "fr-FR", label: "Français" },
  { code: LanguageCode.PT, iso: "pt-PT", label: "Português" },
  { code: LanguageCode.CA, iso: "ca", label: "Català" },
];

export const DEFAULT_LANGUAGE = SUPPORTED_LANGUAGES[0];

export const languageToISO = (language: string): string => {
  const found = SUPPORTED_LANGUAGES.find((l) => l.code.toLowerCase() === language.toLowerCase());
  return found ? found.iso : DEFAULT_LANGUAGE.iso;
};

export const currentLanguage = (): LanguageCode =>
  LanguageCode[i18n.resolvedLanguage?.toUpperCase() as keyof typeof LanguageCode];

export const changeLanguage = async (languageCode: LanguageCode): Promise<void> => {
  await i18n.changeLanguage(languageCode);
};
