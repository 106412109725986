import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import useSentSubmission from "../hooks/useSentSubmission";
import useSubmissionCopy from "../hooks/useSubmissionCopy";
import OfflineContent from "../pages/errorpages/OfflineContent";
import useNavigateAsync from "../hooks/useNavigateAsync";
import StaticForm from "./StaticForm";
import SubmissionNotFoundContent from "../pages/errorpages/SubmissionNotFoundContent";
import useOnlineStatus from "../hooks/useOnlineStatus";
import { Drawer } from "../storybook/components/Drawer/Drawer";
import SpinnerWithMessage from "../storybook/components/Spinner/SpinnerWithMessage";

interface SubmissionHistoryPreviewDrawerProps {
  id: string;
  onClose: () => void;
}

const SubmissionHistoryPreviewDrawer: FC<SubmissionHistoryPreviewDrawerProps> = ({ id, onClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { isLoading, isNotFound, submission, fields, formData, formVersion } = useSentSubmission(id);
  const { copy } = useSubmissionCopy(submission, fields, formVersion?.formId);
  const { isOnline } = useOnlineStatus();
  const navigate = useNavigateAsync();

  const errorState = !isOnline ? (
    <OfflineContent centered />
  ) : isNotFound || !submission || !fields ? (
    <SubmissionNotFoundContent centered />
  ) : undefined;

  const copySubmission = async (): Promise<void> => {
    setLoading(true);
    try {
      const result = await copy();
      navigate(`/submissions/${result}`);
    } finally {
      setLoading(false);
    }
  };

  const showForm = submission && formVersion && !isLoading && !errorState;
  const showError = !isLoading && !!errorState;

  return (
    <Drawer
      header={{
        kind: "simple",
        title: t("SEARCH_SUBMISSIONS"),
        button: { kind: "icon", icon: "XIcon", onClick: onClose },
      }}
      open
      onClose={onClose}
      footer={
        !isNotFound
          ? {
              kind: "default",
              primaryButton: {
                label: t("CREATE"),
                loading,
                onClick: copySubmission,
              },
            }
          : undefined
      }
      contentPadding={false}
    >
      {isLoading && <SpinnerWithMessage message={t("RETRIEVING_SUBMISSION_DATA")} />}
      {showForm && (
        <StaticForm submissionId={submission.id} formVersion={formVersion} data={formData} readOnly padding />
      )}
      {showError && errorState}
    </Drawer>
  );
};

export default SubmissionHistoryPreviewDrawer;
