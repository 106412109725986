import { create, StoreApi, UseBoundStore } from "zustand";
import { RxDatabase } from "rxdb";
import { clone, cloneDeep, first, isEmpty, isNil, parseInt } from "lodash-es";
import { Subscription } from "rxjs";
import { FieldState, UniqueFieldId } from "../types/SubmissionState";
import { AbstractForm, FieldProperties, FormVersion, SubForm, WidgetProperties } from "../types/FormVersion";
import {
  fieldStateToField,
  fieldToFieldState,
  fieldToWidgetResult,
  RememberedField,
  WidgetResult,
} from "../types/Field";
import { findFormVersions, FormEngine, FormEngineRunStats } from "../utils/FormEngine";
import { DBCollections } from "../utils/databaseUtil";
import uuidv4 from "../utils/uuid";
import { SubformEntry } from "../components/widgets/WidgetSubform";
import { getRememberedFieldId } from "../utils/stringUtil";
import { getFieldFromFormVersions } from "../utils/formUtil";
import { sleep } from "../utils/sleepUtil";

export type FormState = {
  fields: FieldState<WidgetProperties, WidgetResult<unknown>>[];
  fieldRevisions: Record<UniqueFieldId, string>;
  rememberedFields: RememberedField[];
  description: string;
};

export type SubmissionState = {
  formState: FormState;
  deviceId: string;
  submissionId: string;
  formId: string;
  formVersion: AbstractForm;
  formVersions: AbstractForm[];
  fieldProperties: FieldProperties;
  formEngine: FormEngine;
  options: SubmissionStoreOptions;
  humanEdited: boolean;
  userId: string | undefined;
  lastRunStats: FormEngineRunStats | undefined;
  focusedField?: FocusedField;
  db?: RxDatabase<DBCollections>;
};

export type FocusedField = {
  uniqueFieldId: UniqueFieldId;
  entryId?: string;
};

export type SubmissionStoreOptions = {
  readOnly: boolean;
  persist: boolean;
  validate: boolean;
};

export type ValidateFormOptions = { treatPendingUploadsAsInvalid: boolean };

export type SubmissionStateActions = {
  loadForm: (
    formVersion: FormVersion,
    submissionId: string,
    deviceId: string,
    formId: string,
    userId: string | undefined,
    username: string | undefined,
    options: SubmissionStoreOptions,
    initialFormState: FormState,
  ) => void;
  setDb: (db: RxDatabase<DBCollections>) => void;
  setOptions: (options: SubmissionStoreOptions) => void;
  setFormState: (formState: FormState) => void;
  setFocussedField: (focusedField?: FocusedField) => void;
  setHumanEdited: (humanEdited: boolean) => void;
  updateField: (field: FieldState<WidgetProperties, WidgetResult<unknown>>, options?: { persist: boolean }) => void;
  persistFields: (fields: FieldState<WidgetProperties, WidgetResult<unknown>>[]) => Promise<void>;
  deleteFields: (uniqueFieldIds: UniqueFieldId[]) => Promise<void>;
  persistDescription: (description: string) => Promise<void>;
  addEntry: (uniqueFieldId: UniqueFieldId, formVersion?: SubForm, meta?: Record<string, unknown>) => string; // entryId TODO: add specific type
  removeEntry: (entryId: string, uniqueFieldId: UniqueFieldId) => void;
  updateEntry: (entry: SubformEntry<unknown>, uniqueFieldId: UniqueFieldId) => void;
  getFirstVisibleInvalidFieldId: (entryId?: string) => UniqueFieldId | undefined;
  getFieldStates: (uniqueFieldIds: UniqueFieldId[]) => FieldState<WidgetProperties, WidgetResult<unknown>>[];
  hasPendingUploads: () => boolean;
  validateForm: (entryId?: string, options?: ValidateFormOptions) => void;
  rememberFields: (formVersion?: AbstractForm, entryId?: string) => void;
  getFormState: () => FormState; // Used only for testing
  listenForExternalFieldChanges: (db: RxDatabase<DBCollections>) => void;
  stopListeningForExternalFieldChanges: () => void;
  updateFieldRevision: (id: UniqueFieldId, revision: string) => void;
};

// TODO: consider if we want to initialize our Zustand Store with props directly, using https://docs.pmnd.rs/zustand/guides/initialize-state-with-props
//  until then, we pass "dummy" values in props below, to avoid having to add ugly !'s everywhere
const dummyFormVersion: FormVersion = {
  fields: [],
  settings: { interaction: "IMMEDIATE_UPLOAD", saveMode: "ALL", icon: "" },
  rules: [],
  fieldProperties: {},
  id: "",
  formId: "",
  dependencies: [],
  meta: {
    created: new Date(),
    createdBy: "",
    lastUpdated: new Date(),
    lastUpdatedBy: "",
    status: "DRAFT",
  },
  theme: {
    id: "5ee1f55f54e4e995cc284b74",
    name: "Default",
  },
};
let fieldChangesSubscription: Subscription | undefined;

export type UseSubmissionStoreResult = UseBoundStore<StoreApi<SubmissionState & SubmissionStateActions>>;

const initialState: SubmissionState = {
  formState: { fields: [], description: "", rememberedFields: [], fieldRevisions: {} },
  deviceId: "",
  submissionId: "",
  formId: "",
  userId: "",
  formEngine: new FormEngine("", "", "", dummyFormVersion, "", { validate: true }),
  formVersion: dummyFormVersion,
  formVersions: [],
  fieldProperties: {},
  options: { readOnly: false, persist: true, validate: true },
  humanEdited: false,
  focusedField: undefined,
  db: undefined,
  lastRunStats: undefined,
};

const useSubmissionStore: UseSubmissionStoreResult = create<SubmissionState & SubmissionStateActions>((set, get) => ({
  ...initialState,
  loadForm: (
    formVersion: FormVersion,
    submissionId: string,
    deviceId: string,
    formId: string,
    userId: string | undefined,
    username: string | undefined,
    options: SubmissionStoreOptions,
    initialFormState: FormState,
  ): void =>
    set(() => {
      const formEngine = new FormEngine(submissionId, formId, deviceId, formVersion, username!, {
        validate: options.validate,
      });
      const formVersions = findFormVersions(formVersion);
      const humanEdited = !isNil(initialFormState.fields.find((field) => field.value.meta.humanEdited));
      const { updatedState: formState, mutatedFields } = formEngine.run(initialFormState, [], {
        validateAll: humanEdited,
        treatPendingUploadsAsInvalid: false,
      });

      if (options.persist) {
        // The initial run can lead to automatic mutations (rules, calculations, etc.) which should be persisted.
        // And in rare cases, loading an existing draft with local (offline) updates, can result in mutations on initial run.
        get().persistFields(mutatedFields.upserted);
      }

      // NOTE: the store is not automatically wiped when the component that loaded it was removed. So we need to "reset" all values
      return {
        formState,
        deviceId,
        submissionId,
        formId,
        userId,
        formEngine,
        formVersion,
        formVersions,
        options,
        humanEdited,
        focusedField: undefined,
        fieldProperties: formVersion.fieldProperties,
      };
    }),
  setDb: (db: RxDatabase<DBCollections>): void =>
    set((state) => {
      state.listenForExternalFieldChanges(db);
      return { db };
    }),
  setOptions: (options: SubmissionStoreOptions) => set(() => ({ options })),
  setFormState: (formState: FormState): void => set(() => ({ formState })),
  setFocussedField: (focusedField?: FocusedField): void => set(() => ({ focusedField })),
  setHumanEdited: (humanEdited: boolean) => set(() => ({ humanEdited })),
  addEntry: (uniqueFieldId: UniqueFieldId, formVersion?: AbstractForm, meta: Record<string, unknown> = {}): string => {
    const entryId = uuidv4();
    const { updatedState: formState, mutatedFields } = get().formEngine.run(get().formState, [
      { uniqueFieldId, type: "add_entry", entryId, formVersion, meta },
    ]);

    get().setFormState(formState);
    get().persistFields(mutatedFields.upserted);
    get().setHumanEdited(true);

    return entryId;
  },
  removeEntry: (entryId: string, uniqueFieldId: UniqueFieldId): void => {
    const { updatedState: formState, mutatedFields } = get().formEngine.run(get().formState, [
      { uniqueFieldId, type: "delete_entry", entryId },
    ]);

    get().setFormState(formState);
    get().persistFields(mutatedFields.upserted);
    get().deleteFields(mutatedFields.deleted);
    get().setHumanEdited(true);
  },
  updateEntry: (value: SubformEntry<unknown>, uniqueFieldId: UniqueFieldId): void => {
    const { updatedState: formState, mutatedFields } = get().formEngine.run(get().formState, [
      { uniqueFieldId, type: "update_entry", value },
    ]);

    get().setFormState(formState);
    get().persistFields(mutatedFields.upserted);
    get().setHumanEdited(true);
  },
  updateFieldRevision: (id: UniqueFieldId, revision: string): void =>
    set((state) => ({
      formState: { ...state.formState, fieldRevisions: { ...state.formState.fieldRevisions, [id]: revision } },
    })),
  persistFields: async (fields: FieldState<WidgetProperties, WidgetResult<unknown>>[]): Promise<void> => {
    const { db, options, userId } = get();
    if (!options.persist || isEmpty(fields)) {
      return;
    }
    if (isNil(db)) {
      throw new Error("Database not found, can't persist fields");
    }
    await Promise.all(
      // use `incrementalUpsert` instead of `bulkUpsert` to guarantee order and avoid 409 Conflict errors
      // See: https://rxdb.info/rx-collection.html#incrementalupsert
      fields.map(fieldStateToField).map(async (field) => {
        const { revision } = await db.fields.incrementalUpsert({ ...field, updatedBy: userId });
        get().updateFieldRevision(field.id, revision);
      }),
    );
  },
  deleteFields: async (uniqueFieldIds: UniqueFieldId[]): Promise<void> => {
    const { db, options } = get();
    if (!options.persist || isEmpty(uniqueFieldIds)) {
      return;
    }
    if (isNil(db)) {
      throw new Error("Database not found, can't delete fields");
    }

    await db.fields.bulkRemove(uniqueFieldIds);
  },
  persistDescription: async (description: string): Promise<void> => {
    const { db, submissionId, options } = get();
    if (!options.persist) {
      return;
    }
    if (isNil(db)) {
      throw new Error("Database not found, can't update submission description");
    }
    const submissionRx = await db.submissions.findOne(submissionId).exec();
    if (isNil(submissionRx)) {
      throw new Error("Submission not found, can't update submission description");
    }
    if (submissionRx.description !== description) {
      await submissionRx.incrementalPatch({ description }); // TODO: make sure this doesn't cause re-renders in 'SubmissionPage'
    }
  },
  updateField: (fieldState, options: { persist: boolean } = { persist: true }): void =>
    set((state) => {
      const { updatedState, mutatedFields, stats } = state.formEngine.run(state.formState, [
        { uniqueFieldId: fieldState.uniqueFieldId, value: fieldState.value, type: "update" },
      ]);

      if (options.persist) {
        state.persistFields(mutatedFields.upserted);
        state.persistDescription(updatedState.description);
      }

      const humanEdited = state.humanEdited || fieldState.value.meta.humanEdited;
      return { formState: updatedState, humanEdited, lastRunStats: stats };
    }),
  getFirstVisibleInvalidFieldId: (entryId?: string): UniqueFieldId | undefined =>
    first(
      get()
        .formState.fields.filter((f) => f.visible && f.value.meta.entryId === entryId && !isNil(f.error))
        .map((x) => x.uniqueFieldId),
    ),
  getFieldStates: (uniqueFieldIds: UniqueFieldId[]): FieldState<WidgetProperties, WidgetResult<unknown>>[] =>
    get().formState.fields.filter((fieldState) => uniqueFieldIds.includes(fieldState.uniqueFieldId)),
  hasPendingUploads: (): boolean =>
    get().formState.fields.some((fieldState) => fieldState.visible && isFieldPendingUpload(fieldState)),
  validateForm: (entryId?: string, options: ValidateFormOptions = { treatPendingUploadsAsInvalid: false }): void => {
    const { updatedState, mutatedFields } = get().formEngine.run(get().formState, [], {
      validateAll: true,
      entryId,
      ...options,
    });
    get().setFormState(updatedState);
    get().persistFields(mutatedFields.upserted);
    get().persistDescription(updatedState.description);
  },
  rememberFields: (formVersion?: AbstractForm, entryId?: string): void =>
    set((state) => {
      const usedFormVersion = formVersion ?? state.formVersion;
      const rememberInputFormFields = usedFormVersion.fields.filter((formField) => formField.properties.remember_input);
      const filledFields = state.formState.fields.filter((f) => f.value.meta.entryId === entryId);
      const rememberedFields: RememberedField[] = filledFields
        .filter((fieldState) => rememberInputFormFields.find((formField) => formField.uid === fieldState.uid))
        .map((rememberedField) => ({
          id: getRememberedFieldId(rememberedField.uid, state.formId),
          formId: state.formId,
          data: clone(rememberedField.value.rawValue), // avoids writing "proxy" objects to storage
          updatedAt: rememberedField.value.updatedAt,
          dataName: rememberedField.value.meta.dataName,
          widget: rememberedField.widget,
          type: rememberedField.value.type,
        }));

      if (isEmpty(rememberedFields)) {
        return state;
      }

      // @ts-ignore this format is needed because of the 'dash'
      state.db?.["remembered-fields"].bulkUpsert(rememberedFields);

      return { formState: { ...state.formState, rememberedFields } };
    }),
  getFormState: (): FormState => get().formState,
  stopListeningForExternalFieldChanges: (): void => {
    fieldChangesSubscription = undefined;
  },
  listenForExternalFieldChanges: (db: RxDatabase<DBCollections>): void => {
    if (!isNil(fieldChangesSubscription)) {
      return; // we only want exactly one subscription
    }

    fieldChangesSubscription = db.fields.$.subscribe(async ({ documentData: field }) => {
      // eslint-disable-next-line no-underscore-dangle
      if (field.status === "final" || field._deleted || get().submissionId !== field.submissionId) {
        return; // avoid running FormEngine for irrelevant updates (big performance win!)
      }

      await sleep(0); // otherwise this runs before 'fieldRevisions' were updated in 'persistField'

      // eslint-disable-next-line no-underscore-dangle
      if (getRevisionNumber(field._rev) <= getRevisionNumber(get().formState.fieldRevisions[field.id])) {
        return; // skip updates that were made on this device
      }
      get().updateFieldRevision(field.id, field._rev); // eslint-disable-line no-underscore-dangle

      const existingFieldState = get().formState.fields.find((f) => f.uniqueFieldId === field.id);

      // new field
      if (isNil(existingFieldState)) {
        const formField = getFieldFromFormVersions(get().formVersions, field.formFieldId);
        if (isNil(formField)) {
          return;
        }
        const newFieldState = fieldToFieldState(field, formField.properties);
        get().formState.fields.push(newFieldState);
        return;
      }

      // updated field
      const updateValue = cloneDeep(fieldToWidgetResult(field)); // avoid errors due to this being a proxy object
      const updatedFieldState = { ...existingFieldState, value: updateValue };
      get().updateField(updatedFieldState, { persist: false }); // only update state, to prevent ping-pong persists
      get().setHumanEdited(true);
    });
  },
}));

const getRevisionNumber = (revision?: string): number => parseInt(revision?.split("-")[0] ?? "-1");

const isFieldPendingUpload = (fieldState: FieldState<WidgetProperties, WidgetResult<any>>): boolean =>
  !isNil(fieldState.value.meta.uploadStatus) && fieldState.value.meta.uploadStatus !== "uploaded";

export default useSubmissionStore;
