import { Dispatch, SetStateAction, useEffect, useState } from "react";

const useSyncedState = <T extends any>(rawValue: T): [T, Dispatch<SetStateAction<T>>] => {
  // The initial value should be 'copied' to the local state, so we don't have to use 2 setters all the time (setFieldState and setLocalState)
  const [localState, setLocalState] = useState(rawValue);
  useEffect(() => setLocalState(rawValue), [rawValue]);
  return [localState, setLocalState];
};

export default useSyncedState;
