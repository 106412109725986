import { UseQueryResult } from "@tanstack/react-query";
import { PinConfig } from "../components/widgets/WidgetPin";
import { FieldProperties, SubForm } from "../types/FormVersion";
import { fromPinIconUrl } from "./iconUtil";
import { MoreAppError } from "../context/MoreAppContext";
import { CustomerResource } from "../hooks/useResource";
import { ChipOption } from "../storybook/components/Chips/Chips";
import { PinLocation } from "../types/Widget";

export const getFormId = (p: PinConfig): string | undefined => p.target_form_id ?? (p.form && p.form.uid);

export type Dimensions = { width: number; height: number };

export const getLocation = (
  image: HTMLImageElement | undefined,
  location: Record<string, number>,
  originalDimensions: Dimensions,
  scale = 1,
  offset = false,
): PinLocation => {
  if (!image) {
    return { x: 0, y: 0 };
  }

  const factor = image.getBoundingClientRect().height / originalDimensions.height;
  const xOffset = (image.width - originalDimensions.width * factor) / 2;
  return {
    x: (location.x * factor) / scale + (offset ? xOffset : 0),
    y: (location.y * factor) / scale,
  };
};

export const getFormVersion = (
  formId: string,
  pins: PinConfig[],
  fieldProperties: FieldProperties,
): SubForm | undefined => {
  const foundForm = pins.find((p) => p.form && p.form.uid === formId)?.form;
  if (foundForm) {
    return foundForm;
  }
  const foundLinkedFormId = pins.find((p) => p.target_form_id === formId)?.target_form_id;
  if (foundLinkedFormId) {
    return fieldProperties[foundLinkedFormId];
  }
  return undefined;
};

export const calculateClickCoords = (e: any, originalDimensions: Dimensions, img?: HTMLImageElement): PinLocation => {
  if (!img) {
    return { x: 0, y: 0 };
  }

  const bounds = img.getBoundingClientRect();
  return {
    x: ((e.clientX - bounds.left) / bounds.width) * originalDimensions.width,
    y: ((e.clientY - bounds.top) / bounds.height) * originalDimensions.height,
  };
};

export const getPinOption = (
  pin: PinConfig,
  resources: Partial<UseQueryResult<CustomerResource, MoreAppError>>[],
): ChipOption => {
  if (pin.icon.type === "URL") {
    const icon = fromPinIconUrl(pin.icon.value);
    return {
      label: pin.name ?? "",
      icon: icon?.icon,
      iconColor: icon?.color,
      value: pin.name || "",
      kind: "icon",
    };
  }
  const resource = resources.find((x) => x.data?.id === pin.icon.value);
  if (pin.icon.type === "RESOURCE" && resource?.data?.dataUrl) {
    return {
      label: pin.name ?? "",
      value: pin.name || "",
      src: resource.data.dataUrl,
      kind: "image",
    };
  }
  return {
    label: pin.name ?? "",
    value: pin.name || "",
    kind: "label",
  };
};
