import { useContext } from "react";
import { Geolocation, type PermissionStatus } from "@capacitor/geolocation";
import { Capacitor } from "@capacitor/core";
import { PermissionsContext } from "../context/PermissionsContext";

type UsePermissionsResult = {
  locationPermissionsGranted: boolean;
  requestLocationPermissions: () => Promise<PermissionStatus>;
  checkLocationPermissions: () => Promise<PermissionStatus>;
};

const usePermissions = (): UsePermissionsResult => {
  const { locationPermissions, setLocationPermissions } = useContext(PermissionsContext);

  const requestLocationPermissions = async (): Promise<PermissionStatus> => {
    if (!Capacitor.isNativePlatform()) {
      throw Error("Not able to request location permissions in browser");
    }
    const status = await Geolocation.requestPermissions();
    setLocationPermissions(status);
    return status;
  };

  const checkLocationPermissions = async (): Promise<PermissionStatus> => {
    const status = await Geolocation.checkPermissions();
    setLocationPermissions(status);
    return status;
  };

  return {
    locationPermissionsGranted:
      locationPermissions?.location === "granted" || locationPermissions?.coarseLocation === "granted",
    requestLocationPermissions,
    checkLocationPermissions,
  };
};

export default usePermissions;
