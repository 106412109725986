import { useLocalStorage } from "usehooks-ts";
import { useEffect, useMemo, useState } from "react";
import { MIGRATION_VERSION, getToBeMigrated } from "../database/migration/00_legacy-app-to-current/migrationUtil";
import useAuth from "./useAuth";

export type UseMigrationResult = {
  shouldMigrate: boolean | undefined;
  isLoggedIn: boolean;
  migrationVersion: number;
  setMigrationVersion: (version: number) => void;
};

const useMigration = (): UseMigrationResult => {
  const { authorization } = useAuth();

  const [migrationVersion, setMigrationVersion] = useLocalStorage<number>(
    `${authorization.userId}.migrationVersion`,
    0,
  );
  const [shouldMigrate, setShouldMigrate] = useState<boolean>();
  const isLoggedIn = useMemo(() => {
    if (!authorization.userId) {
      return false;
    }
    return !!authorization?.accessToken && authorization.type === "oauth";
  }, [authorization?.userId, authorization?.type]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async (): Promise<void> => {
      if (migrationVersion) {
        // Check if migration is the latest version
        setShouldMigrate(migrationVersion < MIGRATION_VERSION);
      } else if (authorization.username) {
        // Otherwise check if all old submissions are migrated
        const submissions = await getToBeMigrated(authorization.username);
        setShouldMigrate(submissions.length > 0);
        if (submissions.length === 0) {
          setMigrationVersion(MIGRATION_VERSION);
        }
      }
    })();
  }, [migrationVersion, isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  return { shouldMigrate, isLoggedIn, migrationVersion, setMigrationVersion };
};

export default useMigration;
