import { useTranslation } from "react-i18next";
import Page from "./Page";
import { Title } from "../storybook/components/Title/Title";
import { TextButton } from "../storybook/components/TextButton/TextButton";
import useLogin from "../hooks/useLogin";
import branding from "../utils/brandingUtil";
import { NON_DESCRIPTIVE_IMAGE } from "../utils/a11yUtil";

const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) {
    newWindow.opener = null;
  }
};

const BrandedStartPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { login } = useLogin();

  return (
    <Page>
      <div className="m-auto flex h-full max-w-94 flex-col items-center px-4 pb-8 pt-17">
        <img
          src="/assets/logo.png"
          alt={t("START_SCREEN_LOGO_ALT", { name: branding.name })}
          className="mb-8 h-18 w-60 shrink-0 object-contain"
        />
        <Title
          as="h2"
          size="3xl"
          children={t("START_SCREEN_BRANDED_TEXT")}
          className="mb-8 shrink-0 text-center 2xs:mb-14"
        />
        <img
          src="/assets/intro/step1.svg"
          alt={NON_DESCRIPTIVE_IMAGE}
          className="mb-8 size-66 min-h-0 shrink object-contain 2xs:mb-14"
        />
        <TextButton label={t("LOGIN")} variant="primary" block onClick={login} className="h-12 shrink-0" />
        {branding.startPageButton && (
          <TextButton
            label={branding.startPageButton!.label}
            variant="transparentBrand"
            block
            onClick={() => openInNewTab(branding.startPageButton!.url)}
            className="mt-4 h-12 shrink-0"
          />
        )}
      </div>
    </Page>
  );
};

export default BrandedStartPage;
