import { useContext } from "react";
import { DrawerContext } from "../context/DrawerContext";

const useDrawer = (id: string): [boolean, (state: boolean) => void] => {
  const { open, close, isOpen } = useContext(DrawerContext);

  return [isOpen(id), (state: boolean): void => (state ? open(id) : close(id))];
};

export default useDrawer;
