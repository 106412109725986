import React, { FC, MutableRefObject, useRef } from "react";
import { useTranslation } from "react-i18next";
import useCustomers, { Customer } from "../hooks/useCustomers";
import useAuth from "../hooks/useAuth";
import { compareByLocale } from "../utils/formUtil";
import { Icon } from "../storybook/components/Icon/Icon";
import { Title } from "../storybook/components/Title/Title";
import { Text } from "../storybook/components/Text/Text";
import { Indicator } from "../storybook/components/Indicator/Indicator";
import { Drawer } from "../storybook/components/Drawer/Drawer";

interface WorkspaceSelectDrawerProps {
  isWorkspaceSelect: boolean;
  draftCount: Record<number, number>;
  taskCount: Record<number, number>;
  newTaskCount: Record<number, number>;
  sendingCount: Record<number, number>;
  onClose: () => void;
}

const WorkspaceSelectDrawer: FC<WorkspaceSelectDrawerProps> = ({
  isWorkspaceSelect,
  draftCount,
  taskCount,
  newTaskCount,
  sendingCount,
  onClose,
}) => {
  const { t } = useTranslation();
  const initialFocus: MutableRefObject<any> = useRef(null);
  const { data: customers } = useCustomers();
  const { setWorkspace, customerId } = useAuth();

  const getTaskCount = (customer: Customer): number => taskCount[customer.customerId] ?? 0;
  const getDraftCount = (customer: Customer): number => draftCount[customer.customerId] ?? 0;
  const getSendingCount = (customer: Customer): number => sendingCount[customer.customerId] ?? 0;

  const listItems = customers
    ?.sort((a, b) => compareByLocale(a.name, b.name))
    .map((customer) => (
      <button
        key={customer.customerId}
        className="flex w-full items-center gap-2 px-6 py-5 text-left active:!bg-gray-900/10 pointer:hover:bg-gray-900/5"
        onClick={() => {
          setWorkspace(customer);
          onClose();
        }}
      >
        <div className="flex size-10 shrink-0 items-center justify-center rounded-lg border bg-gray-200">
          <Icon name="BriefcaseIcon" className="text-gray-400" />
        </div>
        <div className="grow">
          <Title as="h3" size="lg" children={customer.name} className="line-clamp-1 break-all" />
          <Text color="medium" size="xs" weight="normal">
            {getTaskCount(customer) > 0 && (
              <span className="mr-2">
                {newTaskCount[customer.customerId] > 0 && <Indicator size="sm" className="mr-1" />}
                {t("WORKSPACE_TASKS_DESCRIPTION", { count: getTaskCount(customer) })}
              </span>
            )}
            {getDraftCount(customer) > 0 && (
              <span className="mr-2">{t("WORKSPACE_DRAFTS_DESCRIPTION", { count: getDraftCount(customer) })}</span>
            )}
            {getSendingCount(customer) > 0 && (
              <>{t("WORKSPACE_SENDING_DESCRIPTION", { count: getSendingCount(customer) })}</>
            )}
          </Text>
        </div>
        <Icon
          name="CheckIcon"
          className={`shrink-0 ${customer.customerId === customerId ? "text-brand-500" : "text-transparent"}`}
        />
      </button>
    ));

  return (
    <Drawer
      open={isWorkspaceSelect}
      header={{
        kind: "simple",
        title: t("WORKSPACE_SELECT_TITLE"),
        button: { kind: "icon", icon: "XIcon", onClick: onClose },
      }}
      initialFocus={initialFocus}
      onClose={onClose}
      autoHeight
      contentPadding={false}
    >
      {listItems}
    </Drawer>
  );
};

export default WorkspaceSelectDrawer;
