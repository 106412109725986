import { useState } from "react";
import { FileResult, Widget } from "../../types/Widget";
import { validateUpload } from "../../utils/validationUtil";
import { UploadStatus, WidgetResult } from "../../types/Field";
import FileUploadButton from "../FileUploadButton";
import FileFeedback from "./file/FileFeedback";
import WidgetContainer from "../WidgetContainer";
import InsufficientPermissionsModal from "../InsufficientPermissionsModal";

export interface WidgetFileProperties {
  allowed_file_type: string;
  label_text: string;
  required: boolean;
}

const WidgetFile: Widget<WidgetFileProperties, WidgetResult<FileResult>> = ({
  fieldState,
  setFieldState,
  readOnly,
}) => {
  const [showPermissionsModal, setShowPermissionsModal] = useState<boolean>(false);

  const persistWithUploadStatus = (fileResult?: FileResult, uploadStatus?: UploadStatus): void => {
    setFieldState(fileResult, { uploadStatus });
  };

  return (
    <WidgetContainer fieldState={fieldState} name="FILE_FIELD">
      <FileUploadButton
        labelText={fieldState.properties.label_text}
        required={fieldState.properties.required}
        allowedMimeType={fieldState.properties.allowed_file_type}
        isDisabled={readOnly}
        readOnly={readOnly}
        persistWithUploadStatus={persistWithUploadStatus}
        fieldState={fieldState}
        showPermissionsModal={() => setShowPermissionsModal(true)}
      />
      <FileFeedback fieldState={fieldState} />
      {showPermissionsModal && (
        <InsufficientPermissionsModal show={showPermissionsModal} onClose={() => setShowPermissionsModal(false)} />
      )}
    </WidgetContainer>
  );
};

WidgetFile.defaultValue = (_properties, defaultMeta: any): WidgetResult<FileResult> => ({
  type: "file",
  meta: {
    widget: "file",
    ...defaultMeta,
  },
});

WidgetFile.validate = (val, properties, t, meta): string | undefined => {
  if (properties.required && !val) {
    return t("VALIDATION_REQUIRED");
  }
  return validateUpload(val, meta.uploadStatus, t);
};

export default WidgetFile;
