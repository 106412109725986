export interface DataSourceEntry {
  dataSourceId: string;
  id: string;
  data: Record<string, string>;
  version: string;
}

export interface DataSource {
  id: string;
  entries: DataSourceEntry[];
  fallback: boolean;
}

export interface DataSourceMeta {
  id: string;
  customerId: number;
  version: string;
  updatedAt: string;
}

export class DataSourceNotFoundError extends Error {}
