import { Widget } from "../../types/Widget";
import { isEmail } from "../../utils/stringUtil";
import { WidgetResult } from "../../types/Field";
import { TextInput } from "../../storybook/components/TextInput/TextInput";
import useSyncedState from "../../hooks/useSyncedState";
import WidgetContainer from "../WidgetContainer";

export interface WidgetEmailProperties {
  required: boolean;
  label_text: string;
  text_placeholder?: string;
  text_default_value?: string;
}

const WidgetEmail: Widget<WidgetEmailProperties, WidgetResult<string>> = ({ fieldState, setFieldState, readOnly }) => {
  const [localState, setLocalState] = useSyncedState(fieldState.value.rawValue);

  return (
    <WidgetContainer fieldState={fieldState} name="EMAIL_FIELD">
      <TextInput
        name={fieldState.uniqueFieldId}
        value={localState}
        errorMessage={fieldState.error}
        disabled={readOnly}
        onChange={(e) => setLocalState(e.target.value)}
        onBlur={() => setFieldState(localState)}
        type="email"
        label={fieldState.properties.label_text}
        placeholder={fieldState.properties.text_placeholder}
        required={fieldState.properties.required}
      />
    </WidgetContainer>
  );
};

WidgetEmail.defaultValue = (properties, defaultMeta): WidgetResult<string> => ({
  type: "string",
  rawValue: properties.text_default_value,
  meta: {
    widget: "email",
    ...defaultMeta,
  },
});

WidgetEmail.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }

  // Minimal email validation
  if (val && !isEmail(val)) {
    return t("VALIDATION_EMAIL_INVALID");
  }

  return undefined;
};

export default WidgetEmail;
