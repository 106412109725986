import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import useAuth from "./useAuth";

type UseLoginResult = {
  login: () => Promise<void>;
};

const useLogin = (): UseLoginResult => {
  const { login: l } = useAuth();
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const login = (): Promise<void> => l(() => setLoggedIn(true));

  // This effect is needed due to changes in react-router. Because of a page reload/re-render, we need the updated navigation function.
  useEffect(() => {
    if (loggedIn) {
      navigate("/", { replace: true });
    }
  }, [loggedIn, navigate]);

  return { login };
};

export default useLogin;
