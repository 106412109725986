import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import "swiper/css";
import SwiperClass from "swiper/types/swiper-class";
import Page from "../components/Page";
import {
  getToBeMigrated,
  migrate,
  MIGRATION_VERSION,
} from "../database/migration/00_legacy-app-to-current/migrationUtil";
import useAuth from "../hooks/useAuth";
import { useMoreAppClient } from "../context/MoreAppContext";
import useMigration from "../hooks/useMigration";
import { fakeUxDelay } from "../utils/uxUtil";
import useNavigateAsync from "../hooks/useNavigateAsync";
import { Text } from "../storybook/components/Text/Text";
import { Title } from "../storybook/components/Title/Title";
import { TextButton } from "../storybook/components/TextButton/TextButton";
import branding from "../utils/brandingUtil";

const MigrationPage = (): JSX.Element => {
  const navigate = useNavigateAsync();
  const { t } = useTranslation();
  const { username } = useAuth();
  const [swiper, setSwiper] = useState<SwiperClass>();
  const { setMigrationVersion } = useMigration();
  const [percentage, setPercentage] = useState(0);
  const client = useMoreAppClient();
  const next = (): void | undefined => swiper?.slideNext();

  const sectionClasses = "max-w-sm mx-auto flex flex-col space-y-8 items-center text-center z-10 md:max-w-lg";
  const pageClasses = "p-4 justify-center";

  useEffect(() => {
    (async (): Promise<void> => {
      const needsMigration = username && (await getToBeMigrated(username)).length === 0;
      if (needsMigration) {
        setMigrationVersion(MIGRATION_VERSION);
        navigate("/");
      }
    })();
  }, [username]); // eslint-disable-line react-hooks/exhaustive-deps

  const landingPage = (
    <SwiperSlide>
      <Page className={pageClasses}>
        <section className={sectionClasses}>
          <img
            src={branding.isBranded() ? "/assets/logo.png" : "/assets/migration/step1.svg"}
            alt={t("DRAG_AND_DROP_FORMS_ALT")}
          />
          <Title as="h2" size="3xl" children={t("MIGRATION_PAGE_1_TITLE")} />
          <div className="w-full">
            <TextButton label={t("MIGRATION_PAGE_1_CTA")} variant="primary" block onClick={next} />
          </div>
        </section>
      </Page>
    </SwiperSlide>
  );

  const infoPage = (
    <SwiperSlide>
      <Page className={pageClasses}>
        <section className={sectionClasses}>
          <img src="/assets/migration/step2.svg" alt={t("DRAG_AND_DROP_FORMS_ALT")} />
          <Title as="h2" size="3xl" children={t("MIGRATION_PAGE_2_TITLE")} />
          <Text color="medium">{t("MIGRATION_PAGE_2_TEXT")}</Text>
          <div className="w-full">
            <TextButton
              label={t("MIGRATION_PAGE_2_CTA")}
              variant="primary"
              block
              onClick={() => {
                next();
                fakeUxDelay(() => {
                  (async (): Promise<void> => {
                    await migrate(username!, client!, undefined, setPercentage);
                    setMigrationVersion(MIGRATION_VERSION);
                    fakeUxDelay(next, "short");
                  })();
                }, "short");
              }}
            />
          </div>
        </section>
      </Page>
    </SwiperSlide>
  );

  const migrationPage = (
    <SwiperSlide>
      <Page className={pageClasses}>
        <section className={sectionClasses}>
          <div className="relative">
            <img src="/assets/migration/step3.svg" alt={t("DRAG_AND_DROP_FORMS_ALT")} />
            <div className="mx-auto mb-2 mt-3 h-2.5 rounded-full bg-gray-100">
              <div
                className="h-2.5 rounded-full bg-brand-500"
                style={{ width: `${percentage}%`, transition: "0.5s" }}
              />
            </div>
            <div className="flex items-end justify-center pb-14 text-xs tracking-wider text-brand-500">
              {Math.round(percentage)}%
            </div>
          </div>
          <Title as="h2" size="3xl" children={t("MIGRATION_PAGE_3_TITLE")} />
          <Text color="medium">{t("MIGRATION_PAGE_3_TEXT")}</Text>
        </section>
      </Page>
    </SwiperSlide>
  );

  const finishedPage = (
    <SwiperSlide>
      <Page className={pageClasses}>
        <section className={sectionClasses}>
          <img src="/assets/migration/step4.svg" alt={t("DRAG_AND_DROP_FORMS_ALT")} />
          <Title as="h2" size="3xl" children={t("MIGRATION_PAGE_4_TITLE")} />
          <Text color="medium">{t("MIGRATION_PAGE_4_TEXT")}</Text>
          <div className="w-full">
            <TextButton label={t("MIGRATION_PAGE_4_CTA")} variant="primary" block onClick={() => navigate("/")} />
          </div>
        </section>
      </Page>
    </SwiperSlide>
  );

  return (
    <Swiper
      modules={[Pagination]}
      className="h-screen whitespace-pre-line"
      preventInteractionOnTransition
      allowTouchMove={false}
      onSwiper={(swiperInstance) => setSwiper(swiperInstance)}
    >
      {landingPage}
      {infoPage}
      {migrationPage}
      {finishedPage}
    </Swiper>
  );
};

export default MigrationPage;
