import { FC, PropsWithChildren } from "react";
import classNames from "classnames";

export interface TitleProps extends PropsWithChildren {
  className?: string;
  size?: "3xl" | "2xl" | "xl" | "lg" | "sm" | "xs";
  color?: "dark" | "medium" | "light";
  as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

export const Title: FC<TitleProps> = ({ children, size = "xl", color = "dark", className, as, ...props }) => {
  const Tag = as;
  const classes = classNames(
    "overflow-hidden break-words",
    {
      "text-gray-100": color === "light",
      "text-gray-400": color === "medium",
      "text-gray-700": color === "dark",
      "text-3xl font-bold": size === "3xl",
      "text-2xl font-bold": size === "2xl",
      "text-xl font-bold": size === "xl",
      "text-lg font-semibold leading-6": size === "lg",
      "text-sm font-semibold": size === "sm",
      "text-xs font-semibold leading-6 tracking-wider uppercase": size === "xs",
    },
    className,
  );

  return (
    <Tag className={classes} {...props}>
      {children}
    </Tag>
  );
};
