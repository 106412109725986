import { QueryObserverResult, useQuery } from "@tanstack/react-query";
import { MoreAppError, useMoreAppClient } from "../context/MoreAppContext";
import { FormVersion } from "../types/FormVersion";
import useFormVersionCollection from "./useFormVersionCollection";

export type UseFormVersionResult = {
  formVersion: FormVersion | undefined;
  isLoadingFormVersion: boolean;
  isFetchingFormVersion: boolean;
  errorFormVersion: MoreAppError | null;
  refetchFormVersion: () => Promise<QueryObserverResult<FormVersion, MoreAppError>>;
};

const useFormVersion = (id?: string, customerId?: number, formId?: string): UseFormVersionResult => {
  const client = useMoreAppClient();
  const formVersionCollection = useFormVersionCollection();

  const getFormVersion = async (): Promise<FormVersion> => {
    const response = await client!.get<FormVersion>(
      `/api/v1.0/forms/customer/${customerId}/forms/${formId}/versions/${id}`,
    );

    if (response.data.meta.status === "FINAL") {
      await formVersionCollection?.upsert({
        id,
        customerId,
        formId,
        formVersion: response.data,
      });
    }
    return response.data;
  };

  const queryResult = useQuery<FormVersion, MoreAppError>({
    queryKey: ["formVersion", formId, id],
    queryFn: async () => {
      const entry = await formVersionCollection?.findOne().where("id").eq(id).exec();
      if (!entry) {
        return getFormVersion();
      }
      return entry.formVersion;
    },
    enabled: !!id && !!customerId && !!formId && !!formVersionCollection,
    staleTime: Infinity,
    networkMode: "always",
    retry: (failureCount, error) => {
      if (error.status === 403) {
        return false;
      }
      return failureCount < 3;
    },
  });

  return {
    formVersion: queryResult.data,
    isLoadingFormVersion: queryResult.isLoading,
    isFetchingFormVersion: queryResult.isFetching,
    refetchFormVersion: queryResult.refetch,
    errorFormVersion: queryResult.error,
  };
};

export default useFormVersion;
