import { QueryConstructor } from "rxdb-hooks/dist/useRxData";
import { AnyRxQuery, RxQueryResultDoc, useRxData, UseRxQueryOptions } from "rxdb-hooks";
import { Override } from "rxdb/dist/types/types";
import { Submission } from "../types/Submission";

const useLocalSubmissions = (
  queryConstructor: QueryConstructor<Submission> = (collection): AnyRxQuery<Submission> => collection.find(),
  options?: Override<UseRxQueryOptions, { json?: false }>,
): RxQueryResultDoc<Submission> => useRxData<Submission>("submissions", queryConstructor, options);

export default useLocalSubmissions;
