import { CustomThemeName } from "../storybook/themes";

export interface Folder {
  id: string;
  customerId: number;
  meta: FolderMeta;
  status: "ACTIVE" | "HIDDEN" | "UNKNOWN";
  forms: Form[];
}

export interface FolderMeta {
  description: string;
  icon?: string;
  name: string;
  image: string;
}

export interface FormMeta {
  description: string;
  icon: string;
  iconColor: CustomThemeName;
  name: string;
  language: string;
  tags: string[];
}

export interface FormPublishedVersion {
  formVersion: string;
  publishedBy: string;
  publishedDate: Date;
}

export interface Form {
  id: string;
  meta: FormMeta;
  publishedVersion: FormPublishedVersion;
  status: "ACTIVE" | "HIDDEN" | "TRASH" | "UNKNOWN";
}

export type PublishedForm = Form & Pick<Folder, "customerId">;

export class FoldersNotFoundError extends Error {}
