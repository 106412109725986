import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useState } from "react";
import { ToastProps } from "../storybook/components/Toast/Toast";

export const ToastsProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [toast, setToast] = useState<ToastProps>();
  return <ToastsContext.Provider value={{ toast, setToast }}>{children}</ToastsContext.Provider>;
};

interface ToastsState {
  toast?: ToastProps;
  setToast: Dispatch<SetStateAction<ToastProps | undefined>>;
}

export const ToastsContext = createContext<ToastsState>({} as ToastsState);
