import { useState } from "react";
import { isNil } from "lodash-es";
import { Form } from "../types/Folder";
import { FormVersion } from "../types/FormVersion";
import { useEffectOnce } from "./useEffectOnce";
import { seconds } from "../utils/timeUtil";

type BaseFormResult = {
  form?: Form;
  formVersion?: FormVersion;
  customerId?: number;
  hasToken: boolean;
};

type PendingFormResult = BaseFormResult & {
  status: "pending";
};

type ClosedFormResult = BaseFormResult & {
  status: "closed";
};

type PreviewFormResult = BaseFormResult & {
  form: Form;
  formVersion: FormVersion;
  customerId: number;
  status: "active";
};

type FormResult = PendingFormResult | PreviewFormResult | ClosedFormResult;

const usePreviewForm = (): FormResult => {
  const [result, setResult] = useState<FormResult>({ status: "pending", hasToken: false });

  const onReceivedMessage = (event: MessageEvent): void => {
    // check the message source origin as a security measure
    if (!document.referrer.startsWith(event.origin)) {
      return;
    }

    try {
      const payload = JSON.parse(event.data);

      if (payload.type === "payload") {
        setResult((prevState) => ({
          ...prevState,
          form: payload.form,
          formVersion: payload.formVersion,
          customerId: payload.customerId,
          status: "active",
          hasToken: !isNil(payload.token) ?? prevState.hasToken,
        }));
      }
      if (payload.type === "token") {
        setResult((prevState) => ({
          ...prevState,
          hasToken: true,
        }));
        sessionStorage.setItem("token", payload.token);
      }
      if (payload.type === "closed") {
        setResult({ status: "closed", hasToken: false });
        sessionStorage.clear();
      }
    } catch (e) {
      // Ignore events that aren't meant for this listener
    }
  };

  useEffectOnce(() => {
    window.addEventListener("message", onReceivedMessage);
    // Let parent tab/window know we're open for business
    const parentWindow = window.opener ?? window.parent;
    if (!parentWindow.closed) {
      parentWindow.postMessage("onPreviewShow", document.referrer);
    } else {
      setResult({ status: "closed", hasToken: false });
      sessionStorage.clear();
    }

    // Set up interval to check if parentWindow has closed
    const checkParentClosedInterval = setInterval(() => {
      if (parentWindow.closed) {
        setResult({ status: "closed", hasToken: false });
        sessionStorage.clear();
      }
    }, seconds(1)); // Check every second
    return (): void => {
      window.removeEventListener("message", onReceivedMessage);
      clearInterval(checkParentClosedInterval);
    };
  });

  return result;
};

export default usePreviewForm;
