import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import { ReadOnlyTextArea } from "../../storybook/components/ReadOnlyTextArea/ReadonlyTextArea";
import WidgetContainer from "../WidgetContainer";

export interface WidgetReadOnlyTextProperties {
  label_text: string;
  showheader: boolean;
}

const WidgetReadOnlyText: Widget<WidgetReadOnlyTextProperties, WidgetResult<string>> = ({ fieldState }) => (
  <WidgetContainer name="READ_ONLY_TEXT_FIELD" fieldState={fieldState}>
    <ReadOnlyTextArea
      id={fieldState.uid}
      value={fieldState.value?.rawValue}
      label={fieldState.properties.showheader ? fieldState.properties.label_text : undefined}
    />
  </WidgetContainer>
);

WidgetReadOnlyText.defaultValue = (_properties, defaultMeta): WidgetResult<string> => ({
  type: "string",
  meta: {
    widget: "readOnlyText",
    ...defaultMeta,
  },
});

WidgetReadOnlyText.validate = (): undefined => undefined;

export default WidgetReadOnlyText;
