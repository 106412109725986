import { IconName } from "../storybook/components/Icon/Icon";
import { IconColor } from "../storybook/components/Chips/Chips";

const mapping: Record<string, IconName> = {
  ionic: "StopIcon",
  "arrow-up-a": "ArrowUpIcon",
  "arrow-right-a": "ArrowRightIcon",
  "arrow-down-a": "ArrowDownIcon",
  "arrow-left-a": "ArrowLeftIcon",
  "arrow-up-b": "ChevronUpIcon",
  "arrow-right-b": "ChevronRightIcon",
  "arrow-down-b": "ChevronDownIcon",
  "arrow-left-b": "ChevronLeftIcon",
  "arrow-up-c": "ArrowUpIcon",
  "arrow-right-c": "ArrowRightIcon",
  "arrow-down-c": "ArrowDownIcon",
  "arrow-left-c": "ArrowLeftIcon",
  "arrow-swap": "SwitchHorizontalIcon",
  "arrow-expand": "ArrowsExpandIcon",
  "chevron-up": "ChevronUpIcon",
  "chevron-right": "ChevronRightIcon",
  "chevron-down": "ChevronDownIcon",
  "chevron-left": "ChevronLeftIcon",
  "navicon-round": "MenuIcon",
  navicon: "MenuIcon",
  drag: "MenuIcon",
  "log-in": "LoginIcon",
  "log-out": "LogoutIcon",
  "checkmark-round": "CheckIcon",
  checkmark: "CheckIcon",
  "checkmark-circled": "CheckCircleIcon",
  "close-round": "XIcon",
  close: "XIcon",
  "close-circled": "XCircleIcon",
  "plus-round": "PlusIcon",
  plus: "PlusIcon",
  "plus-circled": "PlusCircleIcon",
  "minus-round": "MinusIcon",
  minus: "MinusIcon",
  "minus-circled": "MinusCircleIcon",
  information: "InformationCircleIcon",
  "information-circled": "InformationCircleIcon",
  help: "QuestionMarkCircleIcon",
  "help-circled": "QuestionMarkCircleIcon",
  "backspace-outline": "BackspaceIcon",
  backspace: "BackspaceIcon",
  "help-buoy": "SupportIcon",
  alert: "ExclamationIcon",
  "alert-circled": "ExclamationCircleIcon",
  refresh: "RefreshIcon",
  loop: "RefreshIcon",
  home: "HomeIcon",
  search: "SearchIcon",
  flag: "FlagIcon",
  star: "StarIcon",
  heart: "HeartIcon",
  "gear-a": "CogIcon",
  "gear-b": "CogIcon",
  settings: "CogIcon",
  edit: "PencilIcon",
  "trash-a": "TrashIcon",
  "trash-b": "TrashIcon",
  document: "DocumentIcon",
  "document-text": "DocumentTextIcon",
  clipboard: "ClipboardIcon",
  scissors: "ScissorsIcon",
  funnel: "FilterIcon",
  bookmark: "BookmarkIcon",
  email: "MailIcon",
  "email-unread": "MailIcon",
  folder: "FolderIcon",
  filing: "CollectionIcon",
  archive: "InboxInIcon",
  reply: "ReplyIcon",
  "reply-all": "ReplyIcon",
  share: "ShareIcon",
  "paper-airplane": "PaperAirplaneIcon",
  link: "LinkIcon",
  paperclip: "PaperClipIcon",
  compose: "PencilAltIcon",
  briefcase: "BriefcaseIcon",
  at: "AtSymbolIcon",
  pound: "HashtagIcon",
  cloud: "CloudIcon",
  upload: "CloudUploadIcon",
  more: "DotsHorizontalIcon",
  grid: "ViewGridIcon",
  calendar: "CalendarIcon",
  clock: "ClockIcon",
  navigate: "PaperAirplaneIcon",
  location: "LocationMarkerIcon",
  map: "MapIcon",
  "lock-combination": "LockClosedIcon",
  locked: "LockClosedIcon",
  unlocked: "LockOpenIcon",
  key: "KeyIcon",
  "arrow-graph-up-right": "TrendingUpIcon",
  "arrow-graph-down-right": "TrendingDownIcon",
  "stats-bars": "ChartBarIcon",
  "pie-graph": "ChartPieIcon",
  chatbubble: "ChatIcon",
  "chatbubble-working": "ChatIcon",
  chatbubbles: "ChatAlt2Icon",
  chatbox: "ChatAltIcon",
  "chatbox-working": "ChatAltIcon",
  chatboxes: "ChatAlt2Icon",
  person: "UserIcon",
  "person-add": "UserAddIcon",
  "person-stalker": "UsersIcon",
  wifi: "WifiIcon",
  calculator: "CalculatorIcon",
  camera: "CameraIcon",
  eye: "EyeIcon",
  "eye-disabled": "EyeOffIcon",
  flash: "LightningBoltIcon",
  "qr-scanner": "QrcodeIcon",
  image: "PhotographIcon",
  images: "PhotographIcon",
  monitor: "DesktopComputerIcon",
  laptop: "DesktopComputerIcon",
  ipad: "DeviceTabletIcon",
  iphone: "DeviceMobileIcon",
  ipod: "DeviceMobileIcon",
  printer: "PrinterIcon",
  code: "CodeIcon",
  "code-working": "CodeIcon",
  "code-download": "CodeIcon",
  videocamera: "VideoCameraIcon",
  "music-note": "MusicNoteIcon",
  "radio-waves": "StatusOnlineIcon",
  speakerphone: "SpeakerphoneIcon",
  "mic-a": "MicrophoneIcon",
  "mic-b": "MicrophoneIcon",
  "mic-c": "MicrophoneIcon",
  "volume-high": "VolumeUpIcon",
  "volume-medium": "VolumeUpIcon",
  "volume-low": "VolumeUpIcon",
  "volume-mute": "VolumeOffIcon",
  levels: "AdjustmentsIcon",
  play: "PlayIcon",
  pause: "PauseIcon",
  stop: "StopIcon",
  bag: "ShoppingBagIcon",
  card: "CreditCardIcon",
  cash: "CashIcon",
  pricetag: "TagIcon",
  pricetags: "TagIcon",
  thumbsup: "ThumbUpIcon",
  thumbsdown: "ThumbDownIcon",
  "happy-outline": "EmojiHappyIcon",
  happy: "EmojiHappyIcon",
  "sad-outline": "EmojiSadIcon",
  sad: "EmojiSadIcon",
  podium: "ChartBarIcon",
  university: "AcademicCapIcon",
  beaker: "BeakerIcon",
  "erlenmeyer-flask": "BeakerIcon",
  earth: "GlobeIcon",
  planet: "GlobeIcon",
  lightbulb: "LightBulbIcon",
  cube: "CubeIcon",
  flame: "FireIcon",
  fireball: "FireIcon",
  bonfire: "FireIcon",
  "model-s": "CarIcon",
  plane: "PaperAirplaneIcon",
  jet: "PaperAirplaneIcon",
  "ios-ionic-outline": "StopIcon",
  "ios-arrow-back": "ChevronLeftIcon",
  "ios-arrow-forward": "ChevronRightIcon",
  "ios-arrow-up": "ChevronUpIcon",
  "ios-arrow-right": "ChevronRightIcon",
  "ios-arrow-down": "ChevronDownIcon",
  "ios-arrow-left": "ChevronLeftIcon",
  "ios-arrow-thin-up": "ArrowUpIcon",
  "ios-arrow-thin-right": "ArrowRightIcon",
  "ios-arrow-thin-down": "ArrowDownIcon",
  "ios-arrow-thin-left": "ArrowLeftIcon",
  "ios-checkmark-empty": "CheckIcon",
  "ios-checkmark-outline": "CheckCircleIcon",
  "ios-checkmark": "CheckCircleIcon",
  "ios-plus-empty": "PlusIcon",
  "ios-plus-outline": "PlusCircleIcon",
  "ios-plus": "PlusCircleIcon",
  "ios-close-empty": "XIcon",
  "ios-close-outline": "XCircleIcon",
  "ios-close": "XCircleIcon",
  "ios-minus-empty": "MinusIcon",
  "ios-minus-outline": "MinusCircleIcon",
  "ios-minus": "MinusCircleIcon",
  "ios-information-empty": "InformationCircleIcon",
  "ios-information-outline": "InformationCircleIcon",
  "ios-information": "InformationCircleIcon",
  "ios-help-empty": "QuestionMarkCircleIcon",
  "ios-help-outline": "QuestionMarkCircleIcon",
  "ios-help": "QuestionMarkCircleIcon",
  "ios-search": "SearchIcon",
  "ios-search-strong": "SearchIcon",
  "ios-star": "StarIcon",
  "ios-star-half": "StarIcon",
  "ios-star-outline": "StarIcon",
  "ios-heart": "HeartIcon",
  "ios-heart-outline": "HeartIcon",
  "ios-more": "DotsHorizontalIcon",
  "ios-more-outline": "DotsHorizontalIcon",
  "ios-home": "HomeIcon",
  "ios-home-outline": "HomeIcon",
  "ios-cloud": "CloudIcon",
  "ios-cloud-outline": "CloudIcon",
  "ios-cloud-upload": "CloudUploadIcon",
  "ios-cloud-upload-outline": "CloudUploadIcon",
  "ios-cloud-download": "CloudDownloadIcon",
  "ios-cloud-download-outline": "CloudDownloadIcon",
  "ios-upload": "UploadIcon",
  "ios-upload-outline": "UploadIcon",
  "ios-download": "DownloadIcon",
  "ios-download-outline": "DownloadIcon",
  "ios-refresh": "RefreshIcon",
  "ios-refresh-outline": "RefreshIcon",
  "ios-refresh-empty": "RefreshIcon",
  "ios-reload": "RefreshIcon",
  "ios-loop-strong": "RefreshIcon",
  "ios-loop": "RefreshIcon",
  "ios-bookmarks": "BookmarkAltIcon",
  "ios-bookmarks-outline": "BookmarkAltIcon",
  "ios-book": "BookOpenIcon",
  "ios-book-outline": "BookOpenIcon",
  "ios-flag": "FlagIcon",
  "ios-flag-outline": "FlagIcon",
  "ios-browsers": "DuplicateIcon",
  "ios-browsers-outline": "DuplicateIcon",
  "ios-at": "AtSymbolIcon",
  "ios-at-outline": "AtSymbolIcon",
  "ios-pricetag": "TagIcon",
  "ios-pricetag-outline": "TagIcon",
  "ios-pricetags": "TagIcon",
  "ios-pricetags-outline": "TagIcon",
  "ios-cart": "ShoppingCartIcon",
  "ios-cart-outline": "ShoppingCartIcon",
  "ios-chatboxes": "ChatAlt2Icon",
  "ios-chatboxes-outline": "ChatAlt2Icon",
  "ios-chatbubble": "ChatIcon",
  "ios-chatbubble-outline": "ChatIcon",
  "ios-cog": "CogIcon",
  "ios-cog-outline": "CogIcon",
  "ios-gear": "CogIcon",
  "ios-gear-outline": "CogIcon",
  "ios-settings": "AdjustmentsIcon",
  "ios-settings-strong": "AdjustmentsIcon",
  "ios-filing": "InboxIcon",
  "ios-filing-outline": "InboxIcon",
  "ios-box": "ArchiveIcon",
  "ios-box-outline": "ArchiveIcon",
  "ios-compose": "PencilAltIcon",
  "ios-compose-outline": "PencilAltIcon",
  "ios-trash": "TrashIcon",
  "ios-trash-outline": "TrashIcon",
  "ios-copy": "DocumentDuplicateIcon",
  "ios-copy-outline": "DocumentDuplicateIcon",
  "ios-email": "MailIcon",
  "ios-email-outline": "MailIcon",
  "ios-undo": "ReplyIcon",
  "ios-undo-outline": "ReplyIcon",
  "ios-paperplane": "PaperAirplaneIcon",
  "ios-paperplane-outline": "PaperAirplaneIcon",
  "ios-folder": "FolderIcon",
  "ios-folder-outline": "FolderIcon",
  "ios-paper": "DocumentIcon",
  "ios-paper-outline": "DocumentIcon",
  "ios-list": "ViewListIcon",
  "ios-list-outline": "ViewListIcon",
  "ios-world": "GlobeIcon",
  "ios-world-outline": "GlobeIcon",
  "ios-alarm": "ClockIcon",
  "ios-alarm-outline": "ClockIcon",
  "ios-stopwatch": "ClockIcon",
  "ios-stopwatch-outline": "ClockIcon",
  "ios-timer": "ClockIcon",
  "ios-timer-outline": "ClockIcon",
  "ios-clock": "ClockIcon",
  "ios-clock-outline": "ClockIcon",
  "ios-time": "ClockIcon",
  "ios-time-outline": "ClockIcon",
  "ios-calendar": "CalendarIcon",
  "ios-calendar-outline": "CalendarIcon",
  "ios-photos": "DuplicateIcon",
  "ios-photos-outline": "DuplicateIcon",
  "ios-albums": "DuplicateIcon",
  "ios-albums-outline": "DuplicateIcon",
  "ios-camera": "CameraIcon",
  "ios-camera-outline": "CameraIcon",
  "ios-eye": "EyeIcon",
  "ios-eye-outline": "EyeIcon",
  "ios-bolt": "LightningBoltIcon",
  "ios-bolt-outline": "LightningBoltIcon",
  "ios-grid-view": "ViewGridIcon",
  "ios-grid-view-outline": "ViewGridIcon",
  "ios-barcode": "QrcodeIcon",
  "ios-barcode-outline": "QrcodeIcon",
  "ios-briefcase": "BriefcaseIcon",
  "ios-briefcase-outline": "BriefcaseIcon",
  "ios-calculator": "CalculatorIcon",
  "ios-calculator-outline": "CalculatorIcon",
  "ios-keypad": "ViewGridIcon",
  "ios-keypad-outline": "ViewGridIcon",
  "ios-telephone": "PhoneIcon",
  "ios-telephone-outline": "PhoneIcon",
  "ios-location": "LocationMarkerIcon",
  "ios-locatoutline": "LocationMarkerIcon",
  "ios-navigate": "PaperAirplaneIcon",
  "ios-navigate-outline": "PaperAirplaneIcon",
  "ios-locked": "LockClosedIcon",
  "ios-locked-outline": "LockClosedIcon",
  "ios-unlocked": "LockOpenIcon",
  "ios-unlocked-outline": "LockOpenIcon",
  "ios-monitor": "DesktopComputerIcon",
  "ios-monitor-outline": "DesktopComputerIcon",
  "ios-printer": "PrinterIcon",
  "ios-printer-outline": "PrinterIcon",
  "ios-person": "UserIcon",
  "ios-person-outline": "UserIcon",
  "ios-personadd": "UserAddIcon",
  "ios-personadd-outline": "UserAddIcon",
  "ios-people": "UserGroupIcon",
  "ios-people-outline": "UserGroupIcon",
  "ios-musical-notes": "MusicNoteIcon",
  "ios-musical-note": "MusicNoteIcon",
  "ios-bell": "BellIcon",
  "ios-bell-outline": "BellIcon",
  "ios-mic": "MicrophoneIcon",
  "ios-mic-outline": "MicrophoneIcon",
  "ios-volume-high": "VolumeUpIcon",
  "ios-volume-low": "VolumeOffIcon",
  "ios-play": "PlayIcon",
  "ios-play-outline": "PlayIcon",
  "ios-pause": "PauseIcon",
  "ios-pause-outline": "PauseIcon",
  "ios-videocam": "VideoCameraIcon",
  "ios-videocam-outline": "VideoCameraIcon",
  "ios-film": "FilmIcon",
  "ios-film-outline": "FilmIcon",
  "ios-flask": "BeakerIcon",
  "ios-flask-outline": "BeakerIcon",
  "ios-lightbulb": "LightBulbIcon",
  "ios-lightbulb-outline": "LightBulbIcon",
  "ios-flame": "FireIcon",
  "ios-flame-outline": "FireIcon",
  "ios-sunny": "SunIcon",
  "ios-sunny-outline": "SunIcon",
  "ios-moon": "MoonIcon",
  "ios-moon-outline": "MoonIcon",
  "android-arrow-up": "ArrowUpIcon",
  "android-arrow-forward": "ArrowRightIcon",
  "android-arrow-down": "ArrowDownIcon",
  "android-arrow-back": "ArrowLeftIcon",
  "android-arrow-dropup": "ChevronUpIcon",
  "android-arrow-dropup-circle": "ArrowCircleUpIcon",
  "android-arrow-dropright": "ChevronRightIcon",
  "android-arrow-dropright-circle": "ArrowCircleRightIcon",
  "android-arrow-dropdown": "ChevronDownIcon",
  "android-arrow-dropdown-circle": "ArrowCircleDownIcon",
  "android-arrow-dropleft": "ChevronLeftIcon",
  "android-arrow-dropleft-circle": "ArrowCircleLeftIcon",
  "android-add": "PlusIcon",
  "android-add-circle": "PlusCircleIcon",
  "android-remove": "MinusIcon",
  "android-remove-circle": "MinusCircleIcon",
  "android-close": "XIcon",
  "android-cancel": "XCircleIcon",
  "android-checkmark-circle": "CheckCircleIcon",
  "android-done": "CheckIcon",
  "android-done-all": "CheckIcon",
  "android-menu": "MenuIcon",
  "android-more-horizontal": "DotsHorizontalIcon",
  "android-more-vertical": "DotsVerticalIcon",
  "android-refresh": "RefreshIcon",
  "android-sync": "RefreshIcon",
  "android-wifi": "WifiIcon",
  "android-call": "PhoneIcon",
  "android-apps": "ViewGridIcon",
  "android-settings": "CogIcon",
  "android-options": "AdjustmentsIcon",
  "android-funnel": "FilterIcon",
  "android-search": "SearchIcon",
  "android-home": "HomeIcon",
  "android-cloud-outline": "CloudIcon",
  "android-cloud": "CloudIcon",
  "android-download": "CloudDownloadIcon",
  "android-upload": "CloudUploadIcon",
  "android-cloud-done": "CloudIcon",
  "android-cloud-circle": "CloudIcon",
  "android-favorite-outline": "HeartIcon",
  "android-favorite": "HeartIcon",
  "android-star-outline": "StarIcon",
  "android-star-half": "StarIcon",
  "android-star": "StarIcon",
  "android-calendar": "CalendarIcon",
  "android-alarm-clock": "ClockIcon",
  "android-time": "ClockIcon",
  "android-stopwatch": "ClockIcon",
  "android-navigate": "PaperAirplaneIcon",
  "android-pin": "LocationMarkerIcon",
  "android-map": "MapIcon",
  "android-car": "CarIcon",
  "android-bus": "CarIcon",
  "android-train": "CarIcon",
  "android-plane": "PaperAirplaneIcon",
  "android-cart": "ShoppingCartIcon",
  "android-camera": "CameraIcon",
  "android-image": "PhotographIcon",
  "android-create": "PencilIcon",
  "android-mail": "MailIcon",
  "android-drafts": "MailOpenIcon",
  "android-send": "PaperAirplaneIcon",
  "android-archive": "InboxInIcon",
  "android-delete": "TrashIcon",
  "android-attach": "PaperClipIcon",
  "android-share": "ShareIcon",
  "android-share-alt": "ShareIcon",
  "android-bookmark": "BookmarkIcon",
  "android-document": "DocumentIcon",
  "android-clipboard": "ClipboardIcon",
  "android-list": "ViewListIcon",
  "android-folder-open": "FolderIcon",
  "android-folder": "FolderIcon",
  "android-print": "PrinterIcon",
  "android-open": "LoginIcon",
  "android-exit": "LogoutIcon",
  "android-expand": "ArrowsExpandIcon",
  "android-globe": "GlobeIcon",
  "android-chat": "ChatAlt2Icon",
  "android-textsms": "ChatAltIcon",
  "android-happy": "EmojiHappyIcon",
  "android-sad": "EmojiSadIcon",
  "android-person": "UserIcon",
  "android-people": "UsersIcon",
  "android-person-add": "UserAddIcon",
  "android-contact": "UserIcon",
  "android-contacts": "UsersIcon",
  "android-lock": "LockClosedIcon",
  "android-unlock": "LockOpenIcon",
  "android-microphone": "MicrophoneIcon",
  "android-notifications-none": "BellIcon",
  "android-notifications": "BellIcon",
  "android-volume-mute": "VolumeOffIcon",
  "android-volume-down": "VolumeUpIcon",
  "android-volume-up": "VolumeUpIcon",
  "android-volume-off": "VolumeOffIcon",
  "android-hand": "HandIcon",
  "android-desktop": "DesktopComputerIcon",
  "android-laptop": "DesktopComputerIcon",
  "android-phone-portrait": "DeviceMobileIcon",
  "android-phone-landscape": "DeviceMobileIcon",
  "android-bulb": "LightBulbIcon",
  "android-sunny": "SunIcon",
  "android-alert": "ExclamationCircleIcon",
  "android-warning": "ExclamationIcon",
  "social-rss": "RssIcon",
  "social-rss-outline": "RssIcon",
  "social-usd": "CurrencyDollarIcon",
  "social-usd-outline": "CurrencyDollarIcon",
  "social-yen": "CurrencyYenIcon",
  "social-yen-outline": "CurrencyYenIcon",
  "social-euro": "CurrencyEuroIcon",
  "social-euro-outline": "CurrencyEuroIcon",
};

export const fromPlatformIcon = (key: string, fallback: IconName = "DocumentIcon"): IconName =>
  mapping[key] || fallback;

/**
 * We're (or hopefully some day have been) hardcoding urls for default icons. These have to be fetched, which is an issue if you're offline.
 * Instead, these get mapped to default icons. Also, http and https are used interchangeably throughout our database.
 * @param url
 * @returns sane icon option
 */
export const fromPinIconUrl = (url: string): { icon: IconName; color: Exclude<IconColor, "default"> } => {
  switch (url) {
    case "http://cdn.moreapp.com/more-icons/comment_purple.png":
    case "https://cdn.moreapp.com/more-icons/comment_purple.png":
      return { icon: "ChatIcon", color: "purple" };
    case "http://cdn.moreapp.com/more-icons/exclamation_orange.png":
    case "https://cdn.moreapp.com/more-icons/exclamation_orange.png":
      return { icon: "ExclamationIcon", color: "orange" };
    case "http://cdn.moreapp.com/more-icons/question_blue.png":
    case "https://cdn.moreapp.com/more-icons/question_blue.png":
      return { icon: "QuestionMarkCircleIcon", color: "blue" };
    case "http://cdn.moreapp.com/more-icons/times_red.png":
    case "https://cdn.moreapp.com/more-icons/times_red.png":
      return { icon: "XIcon", color: "red" };
    case "http://cdn.moreapp.com/more-icons/check_green.png":
    case "https://cdn.moreapp.com/more-icons/check_green.png":
    default:
      return { icon: "CheckIcon", color: "green" };
  }
};
