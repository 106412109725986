import { FC, useEffect, useState } from "react";
import { Circle, CircleMarker } from "react-leaflet";
import { LatLng } from "leaflet";
import { Geolocation } from "@capacitor/geolocation";
import { Capacitor } from "@capacitor/core";
import usePermissions from "../../../hooks/usePermissions";
import { getLocation, Location } from "../../../utils/locationUtil";
import { useAsyncEffect } from "../../../hooks/useAsyncEffect";
import { noop, noopAsync } from "../../../utils/noop";

const LocationMarker: FC = () => {
  const [location, setLocation] = useState<Location | null>(null);
  const latLng = location && new LatLng(location.latitude, location.longitude);
  const [watchId, setWatchId] = useState<string>();
  const { locationPermissionsGranted } = usePermissions();

  useAsyncEffect(
    async () => {
      // Get position before watching it, so we can already set a position based on old location
      if ((locationPermissionsGranted && Capacitor.isNativePlatform()) || !Capacitor.isNativePlatform()) {
        const current = await getLocation();
        setLocation(current);
        const id = await Geolocation.watchPosition(
          { enableHighAccuracy: true, timeout: 60_000 },
          (position) => position && setLocation(position.coords),
        );
        setWatchId(id);
      }
    },
    noopAsync,
    [locationPermissionsGranted],
  );

  useEffect(() => (watchId ? (): Promise<void> => Geolocation.clearWatch({ id: watchId }) : noop), [watchId]);

  return latLng ? (
    <CircleMarker center={latLng} radius={8} stroke fillColor="#0EA5E9" fillOpacity={1} color="#ffffff">
      <Circle center={latLng} radius={location?.accuracy} fillOpacity={0.5} fillColor="#BAE6FD" stroke={false} />
    </CircleMarker>
  ) : null;
};

export default LocationMarker;
