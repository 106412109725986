// Check convert-currency in the scripts repository for creating a new version of this
export const ISOCurrency: Record<string, string> = {
  AED: "د.إ.",
  AFN: "Af",
  ALL: "L",
  AMD: "֏",
  ANG: "ƒ",
  AOA: "Kz",
  ARS: "AR$",
  AUD: "AU$",
  AWG: "ƒ",
  AZN: "ман",
  BAM: "KM",
  BBD: "BBD$",
  BDT: "৳",
  BGN: "лв.",
  BHD: "BD",
  BIF: "FBu",
  BMD: "$",
  BND: "B$",
  BOB: "Bs.",
  BRL: "R$",
  BSD: "$",
  BTN: "Nu.",
  BWP: "P",
  BYN: "Br",
  BZD: "BZ$",
  CAD: "CA$",
  CDF: "FC",
  CHF: "Fr.",
  CKD: "$",
  CLP: "CL$",
  CNY: "CN¥",
  COP: "CO$",
  CRC: "₡",
  CUC: "CUC$",
  CUP: "$MN",
  CVE: "CV$",
  CZK: "Kč",
  DJF: "Fdj",
  DKK: "kr.",
  DOP: "RD$",
  DZD: "DA",
  EGP: "E£",
  EHP: "Ptas.",
  ERN: "Nkf",
  ETB: "Br",
  EUR: "€",
  FJD: "FJ$",
  FKP: "FK£",
  FOK: "kr",
  GBP: "£",
  GEL: "₾",
  GGP: "£",
  GHS: "GH₵",
  GIP: "£",
  GMD: "D",
  GNF: "FG",
  GTQ: "Q",
  GYD: "G$",
  HKD: "HK$",
  HNL: "L",
  HRK: "kn",
  HTG: "G",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  IMP: "£",
  INR: "Rs.",
  IQD: "د.ع.",
  IRR: "﷼",
  ISK: "kr",
  JEP: "£",
  JMD: "J$",
  JOD: "JD",
  JPY: "¥",
  KES: "KSh",
  KGS: "с",
  KHR: "៛",
  KID: "$",
  KMF: "CF",
  KPW: "₩",
  KRW: "₩",
  KWD: "KD",
  KYD: "CI$",
  KZT: "₸",
  LAK: "₭N",
  LBP: "LL.",
  LKR: "Rs.",
  LRD: "L$",
  LSL: "L",
  LYD: "LD",
  MAD: "DH",
  MDL: "L",
  MGA: "Ar",
  MKD: "den",
  MMK: "Ks",
  MNT: "₮",
  MOP: "MOP$",
  MRU: "UM",
  MUR: "Rs.",
  MVR: "MRf",
  MWK: "MK",
  MXN: "MX$",
  MYR: "RM",
  MZN: "MTn",
  NAD: "N$",
  NGN: "₦",
  NIO: "C$",
  NOK: "kr",
  NPR: "Rs.",
  NZD: "NZ$",
  OMR: "OR",
  PAB: "B/.",
  PEN: "S/.",
  PGK: "K",
  PHP: "₱",
  PKR: "Rs.",
  PLN: "zł",
  PND: "$",
  PRB: "р.",
  PYG: "₲",
  QAR: "QR",
  RON: "L",
  RSD: "din",
  RUB: "₽",
  RWF: "FRw",
  SAR: "SR",
  SBD: "SI$",
  SCR: "Rs.",
  SDG: "£SD",
  SEK: "kr",
  SGD: "S$",
  SHP: "£",
  SLL: "Le",
  SLS: "Sl",
  SOS: "Sh.So.",
  SRD: "Sr$",
  SSP: "SS£",
  STN: "Db",
  SVC: "₡",
  SYP: "LS",
  SZL: "L",
  THB: "฿",
  TJS: "SM",
  TMT: "m.",
  TND: "DT",
  TOP: "T$",
  TRY: "TL",
  TTD: "TT$",
  TVD: "$",
  TWD: "NT$",
  TZS: "TSh",
  UAH: "₴",
  UGX: "USh",
  USD: "$",
  UYU: "$U",
  UZS: "сум",
  VED: "Bs.",
  VES: "Bs.F",
  VND: "₫",
  VUV: "VT",
  WST: "T",
  XAF: "Fr",
  XCD: "$",
  XOF: "₣",
  XPF: "₣",
  YER: "YR",
  ZAR: "R",
  ZMW: "ZK",
  ZWB: "",
  ZWL: "Z$",
  Abkhazia: "",
  Artsakh: "դր.",
};

export type Currency =
  | "euro"
  | "pound"
  | "dollar"
  | "yen"
  | "franc"
  | "ruble"
  | "krona"
  | "renminbi"
  | "won"
  | "lira"
  | "rupee";

// Legacy to ISO 4217 mapping
export const currencyMapping: Record<Currency, string> = {
  euro: "EUR",
  pound: "GBP", // British
  dollar: "USD", // United States
  yen: "JPY", // Japanese
  franc: "CHF", // Swiss
  ruble: "RUB", // Russian
  krona: "SEK", // Swedish
  renminbi: "CNY", // Chinese
  won: "KRW", // South korean
  lira: "TRY", // Turkish
  rupee: "INR", // Indian
};
