import { ImageOptions } from "@capacitor/camera";

export enum PhotoQuality {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
  BEST = "best",
}

export const PhotoQualitySetting: Record<PhotoQuality, Partial<ImageOptions>> = {
  [PhotoQuality.LOW]: {
    quality: 40,
    width: 1024,
    height: 1024,
  },
  [PhotoQuality.MEDIUM]: {
    quality: 90,
    width: 1024,
    height: 1024,
  },
  [PhotoQuality.HIGH]: {
    quality: 90,
    width: 1920,
    height: 1920,
  },
  [PhotoQuality.BEST]: {
    quality: 90,
  },
};
