(async (): Promise<void> => {
  // eslint-disable-next-line no-restricted-globals
  const currentUrl = location.href;
  const hashIndex = currentUrl.indexOf("#/");
  if (hashIndex >= 0) {
    const body = {
      severity: "WARNING",
      message: "Used legacy URL that required a redirect",
      source: "app",
      data: { path: currentUrl },
    };

    try {
      await fetch("https://log-collector.moreapp.cloud/log", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      window.location.href = currentUrl.substring(hashIndex).replaceAll("#/", "");
    }
  }
})();
