import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import useAuth from "./useAuth";
import { useMoreAppClient } from "../context/MoreAppContext";
import { Form } from "../types/Folder";

const useForm = (id?: string): UseQueryResult<Form, Error> => {
  const { customerId } = useAuth();
  const client = useMoreAppClient();
  return useQuery<Form, Error>({
    queryKey: ["form", id],
    queryFn: async () => {
      const response = await client!.get(`/api/v1.0/forms/customer/${customerId}/forms/${id}`);
      return response.data;
    },
    enabled: !!id,
  });
};

export default useForm;
