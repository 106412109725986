import { useRxData } from "rxdb-hooks";
import { RxDocument } from "rxdb";
import { RememberedSearchQuery } from "../types/Field";

type UserLocalRememberedSearchQueryResult = {
  rememberedQuery: RxDocument<RememberedSearchQuery>[];
  isFetchingRememberedQuery: boolean;
};

const useLocalRememberedSearchQuery = (formFieldId: string, formId: string): UserLocalRememberedSearchQueryResult => {
  const rxData = useRxData<RememberedSearchQuery>("remembered-search-query", (collection) =>
    collection.find().where("id").eq(formFieldId).where("formId").eq(formId),
  );

  return { rememberedQuery: rxData.result, isFetchingRememberedQuery: rxData.isFetching };
};

export default useLocalRememberedSearchQuery;
