import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { first, isEmpty, isNil, startCase } from "lodash-es";
import useBarcodeScanner from "../../hooks/useBarcodeScanner";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import { DataSourceEntry } from "../../types/Datasource";
import { getEnabledFields } from "../../utils/datasourceUtil";
import { getSearchEntryHumanText } from "../../utils/stringUtil";
import SearchValue from "./search/SearchValue";
import { InsufficientPermissionError } from "../../hooks/useCamera";
import InsufficientPermissionsModal from "../InsufficientPermissionsModal";
import SearchDrawer from "./search/SearchDrawer";
import useDrawer from "../../hooks/useDrawer";
import { Label } from "../../storybook/components/Label/Label";
import { Text } from "../../storybook/components/Text/Text";
import { WidgetContentButton } from "../../storybook/components/WidgetContentButton/WidgetContentButton";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";
import { IconButton } from "../../storybook/components/IconButton/IconButton";
import { Feedback } from "../../storybook/components/Feedback/Feedback";
import { Drawer } from "../../storybook/components/Drawer/Drawer";
import { DefinitionList } from "../../storybook/components/DefinitionList/DefinitionList";
import WidgetContainer from "../WidgetContainer";
import useLocalRememberedSearchQuery from "../../hooks/useLocalRememberedSearchQuery";
import useRememberedQueryCollection from "../../hooks/useRememberedQueryCollection";
import useStateFormId from "../../state/useStateFormId";
import { useFocusId } from "../../hooks/useFocusId";

export type DatasourceColor = { color: string; query: string };

export interface WidgetSearchProperties {
  required: boolean;
  label_text: string;
  default_value?: string;
  remember_search?: boolean;
  allow_barcode?: boolean;
  data_source_configuration: {
    id: string;
    mapping: Record<string, boolean>;
  };
  filter_fields?: string[];
  colors?: DatasourceColor[];
}

const WidgetSearch: Widget<WidgetSearchProperties, WidgetResult<DataSourceEntry>> = ({
  fieldState,
  setFieldState,
  readOnly,
  entry,
}) => {
  const mapping = fieldState.properties?.data_source_configuration?.mapping;
  const hasDatasource = fieldState.properties?.data_source_configuration?.id;

  const { t } = useTranslation();
  const { rawValue } = fieldState.value;
  const formId = useStateFormId();
  const { rememberedQuery, isFetchingRememberedQuery } = useLocalRememberedSearchQuery(fieldState.uid, formId);
  const rememberedQueryCollection = useRememberedQueryCollection();
  const [query, setQuery] = useState("");
  const [showPermissionsModal, setPermissionsModal] = useState(false);
  const { startScan, isScanSupported, isScannerInstalling } = useBarcodeScanner();
  const [scanResult, setScanResult] = useState<string | undefined>();
  const enabledEntryFields = useMemo(() => getEnabledFields(mapping), [mapping]);
  const [isSearchOpen, setSearchOpen] = useDrawer(`search-${fieldState.uid}`);
  const [activeOpen, setActiveOpen] = useDrawer(`active-${fieldState.uid}`);
  const [activeItem, setActiveItem] = useState<DataSourceEntry | undefined>();
  const onCloseFocusId = useFocusId(fieldState);

  const handleChange = async (newVal: DataSourceEntry): Promise<void> => {
    if (fieldState.properties.remember_search && rememberedQueryCollection) {
      await rememberedQueryCollection.upsert({ id: fieldState.uid, formId, query });
    }
    setFieldState(newVal);
    setSearchOpen(false);
  };

  const closeActiveDrawer = (): void => {
    setActiveItem(undefined);
    setActiveOpen(false);
  };

  const startScanning = async (): Promise<void> => {
    try {
      const result = await startScan();
      setScanResult(result);
      setQuery(""); // Don't use old/default query when using barcode
      setSearchOpen(true);
    } catch (e) {
      if (e instanceof InsufficientPermissionError) {
        setPermissionsModal(true);
        return;
      }
      throw e;
    }
  };

  const openSearch = (): void => {
    let initialQuery = fieldState.properties.default_value ?? "";
    if (fieldState.properties.remember_search && !isEmpty(rememberedQuery)) {
      initialQuery = first(rememberedQuery)?.query ?? initialQuery;
    }
    setQuery(initialQuery);
    setSearchOpen(true);
  };

  return (
    <WidgetContainer fieldState={fieldState} name="SEARCH_FIELD">
      <Label
        id={fieldState.uniqueFieldId}
        label={fieldState.properties.label_text}
        required={fieldState.properties.required}
        showClearBtn={!readOnly && !isNil(rawValue)}
        onClear={() => setFieldState(undefined)}
        clearLabel={t("CLEAR")}
      />
      {!isNil(rawValue) ? (
        <>
          <WidgetContentButton
            id={onCloseFocusId}
            singleLine
            items={[
              {
                label: t("VIEW"),
                onClick: (): void => {
                  setActiveItem(rawValue);
                  setActiveOpen(true);
                },
              },
              ...(!readOnly && hasDatasource ? [{ label: t("EDIT"), onClick: () => setSearchOpen(true) }] : []),
            ]}
          >
            {getSearchEntryHumanText(rawValue.data, enabledEntryFields)}
          </WidgetContentButton>
          <DefinitionList
            className="mt-1"
            cutOffLength={4}
            definitions={enabledEntryFields
              .filter((key) => rawValue.data[key])
              .map((key) => ({
                term: startCase(key),
                description: <SearchValue value={rawValue.data[key]} />,
              }))}
            showMoreLabel={t("SHOW_MORE_BUTTON")}
            showLessLabel={t("SHOW_LESS_BUTTON")}
          />
        </>
      ) : (
        <div className="flex space-x-2">
          <IconAndTextButton
            disabled={readOnly || !hasDatasource || isFetchingRememberedQuery}
            block
            icon="SearchIcon"
            label={!readOnly ? t("SEARCH_SELECT_ITEM") : t("NO_ITEM_SELECTED")}
            onClick={() => openSearch()}
          />
          {fieldState.properties.allow_barcode && (
            <IconButton
              aria-label={t("OPEN_BARCODE_SCANNER")}
              icon="QrcodeIcon"
              onClick={startScanning}
              disabled={!isScanSupported || !hasDatasource}
              data-testid="barcode-button"
              loading={isScannerInstalling}
            />
          )}
        </div>
      )}
      {fieldState.error && <Feedback status="error" message={fieldState.error} />}
      {/* Data Source List */}
      <SearchDrawer
        onCloseFocusId={onCloseFocusId}
        isOpen={isSearchOpen}
        setOpen={setSearchOpen}
        query={query}
        setQuery={setQuery}
        props={fieldState}
        enabledEntryFields={enabledEntryFields}
        entryId={entry?.id}
        onChange={handleChange}
        scanResult={scanResult}
        setScanResult={setScanResult}
      />

      {/* Data Source Detail */}
      <Drawer
        open={activeOpen}
        header={{
          kind: "simple",
          title: getSearchEntryHumanText(activeItem ? activeItem.data : {}, enabledEntryFields),
          button: {
            kind: "icon",
            icon: "XIcon",
            onClick: () => closeActiveDrawer(),
          },
        }}
        footer={{
          kind: "default",
          primaryButton: {
            label: t("CLOSE"),
            onClick: () => closeActiveDrawer(),
          },
        }}
        onClose={() => closeActiveDrawer()}
      >
        {activeItem &&
          activeItem.data &&
          Object.entries(activeItem.data)
            .filter(([key]) => enabledEntryFields.includes(key))
            .map(([key, value]) => (
              <div key={key} className="flex items-center justify-between space-x-2 border-b border-gray-200 py-3">
                <Text color="medium">{startCase(key)}</Text>
                <Text>
                  <SearchValue value={value} />
                </Text>
              </div>
            ))}
      </Drawer>
      {showPermissionsModal && (
        <InsufficientPermissionsModal show={showPermissionsModal} onClose={() => setPermissionsModal(false)} />
      )}
    </WidgetContainer>
  );
};

WidgetSearch.defaultValue = (_properties, defaultMeta): WidgetResult<DataSourceEntry> => ({
  type: "object",
  meta: {
    widget: "search",
    ...defaultMeta,
  },
});

WidgetSearch.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }
  return undefined;
};

export default WidgetSearch;
