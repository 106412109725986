import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounceValue } from "usehooks-ts";
import useCustomers from "../hooks/useCustomers";
import useAuth from "../hooks/useAuth";
import WorkspaceSelectDrawer from "./WorkspaceSelectDrawer";
import useWorkspaceCounter from "../hooks/useWorkspaceCounter";
import useOnlineStatus from "../hooks/useOnlineStatus";
import useDatabase from "../hooks/useDatabase";
import { Header, HeaderButtonProps } from "../storybook/components/Header/Header";
import { Banner } from "../storybook/components/Banner/Banner";

export interface WorkspaceHeaderProps {
  defaultTitle: string;
  actions?: HeaderButtonProps[];
}

const WorkspaceHeader: FC<WorkspaceHeaderProps> = ({ defaultTitle, actions }) => {
  const { customerName, isLoading: isLoadingAuth } = useAuth();
  const { data: customers } = useCustomers();
  const { t } = useTranslation();
  const [isCustomerSelection, setCustomerSelection] = useState(false);
  const { hasNewTasks, taskCount, draftCount, newTaskCount, sendingCount } = useWorkspaceCounter();
  const { isOnline } = useOnlineStatus();
  const { isInitiallySynced } = useDatabase();
  const [showInitiallySyncing] = useDebounceValue(!isLoadingAuth && isOnline && !isInitiallySynced, 3000);
  const onTitleClick = (): void => {
    if (customers && customers.length > 1) {
      setCustomerSelection(true);
    }
  };

  const showInternetDisconnected = !isOnline;
  const showSyncDisconnected = false;

  const banner = (
    <>
      {showInternetDisconnected && <Banner icon="StatusOfflineIcon" label={t("INTERNET_DISCONNECTED")} />}
      {showSyncDisconnected && <Banner type="warning" label={t("SYNC_DISCONNECTED")} />}
      {showInitiallySyncing && <Banner icon="CloudDownloadIcon" type="warning" label={t("INITIAL_SYNC")} />}
    </>
  );

  return (
    <>
      <Header
        className="bg-white mt-safe"
        title={customers && customers.length > 1 && customerName ? customerName : defaultTitle}
        onClick={() => onTitleClick()}
        showOnClickBtn={customers && customers.length > 1}
        showIndicator={hasNewTasks}
        actions={actions}
        banner={banner}
        indicatorLabel={t("OPEN_TASKS_INDICATOR")}
      />
      <WorkspaceSelectDrawer
        isWorkspaceSelect={isCustomerSelection}
        taskCount={taskCount}
        draftCount={draftCount}
        newTaskCount={newTaskCount}
        sendingCount={sendingCount}
        onClose={() => setCustomerSelection(false)}
      />
    </>
  );
};

export default WorkspaceHeader;
