import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import { Title } from "../../storybook/components/Title/Title";
import WidgetContainer from "../WidgetContainer";

type Size = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export interface WidgetHeaderProperties {
  label_text: string;
  size: Size;
}

const headingSizeMapping: Record<Size, string> = {
  h1: "3xl",
  h2: "2xl",
  h3: "xl",
  h4: "lg",
  h5: "sm",
  h6: "xs",
};

const WidgetHeader: Widget<WidgetHeaderProperties, WidgetResult<void>> = ({ fieldState }) => {
  const size = headingSizeMapping[fieldState.properties.size];
  const tag = fieldState.properties.size === "h1" ? "h2" : fieldState.properties.size;

  return (
    <WidgetContainer fieldState={fieldState} name="HEADER_FIELD">
      <Title as={tag} size={size as any}>
        {fieldState.properties.label_text}
      </Title>
    </WidgetContainer>
  );
};

WidgetHeader.defaultValue = (_properties, defaultMeta: any): WidgetResult<void> => ({
  type: "none",
  meta: {
    widget: "header",
    ...defaultMeta,
  },
});

WidgetHeader.validate = (): undefined => undefined;

export default WidgetHeader;
