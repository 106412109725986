import { useContext } from "react";
import { ensureSdk } from "../utils/barcodeScannerUtil";
import Logger from "../utils/logger";
import useDeviceInfo from "./useDeviceInfo";
import { BarcodeScannerContext } from "../context/BarcodeScannerContext";
import { useEffectOnce } from "./useEffectOnce";

const useEnsureBarcodeScannerSdk = (): void => {
  const { isScannerInstalling, setIsScannerInstalling } = useContext(BarcodeScannerContext);
  const { platform } = useDeviceInfo();

  useEffectOnce(() => {
    ensureSdk(platform, isScannerInstalling, setIsScannerInstalling).catch(Logger.error);
  });
};

export default useEnsureBarcodeScannerSdk;
