import type { ComponentType, FC, SVGProps } from "react";

type IconProps = ComponentType<SVGProps<SVGElement>>;

export const CalendarTimeIcon: FC<IconProps> = (props): JSX.Element => (
  <svg {...props} viewBox="0 0 16 16" stroke="auto">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.4 1.8C3.4 1.35817 3.75817 1 4.2 1C4.64183 1 5 1.35817 5 1.8V2.6H9.8V1.8C9.8 1.35817 10.1582 1 10.6 1C11.0418 1 11.4 1.35817 11.4 1.8V2.6H12.2C13.0837 2.6 13.8 3.31634 13.8 4.2V6.85695C13.0973 6.38109 12.267 6.07931 11.3712 6.01357C11.39 5.94558 11.4 5.87396 11.4 5.8C11.4 5.35817 11.0418 5 10.6 5H4.2C3.75817 5 3.4 5.35817 3.4 5.8C3.4 6.24183 3.75817 6.6 4.2 6.6H8.62308C7.06095 7.44565 5.99997 9.09892 5.99997 11C5.99997 12.0375 6.31594 13.0011 6.8569 13.8H2.6C1.71634 13.8 1 13.0837 1 12.2V4.2C1 3.31634 1.71634 2.6 2.6 2.6H3.4V1.8Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 15C13.2091 15 15 13.2091 15 11C15 8.79086 13.2091 7 11 7C8.79083 7 6.99997 8.79086 6.99997 11C6.99997 13.2091 8.79083 15 11 15ZM11.5 9C11.5 8.72386 11.2761 8.5 11 8.5C10.7238 8.5 10.5 8.72386 10.5 9V11C10.5 11.1326 10.5526 11.2598 10.6464 11.3536L12.0606 12.7678C12.2559 12.963 12.5725 12.963 12.7677 12.7678C12.963 12.5725 12.963 12.2559 12.7677 12.0607L11.5 10.7929V9Z"
    />
  </svg>
);

export const CarIcon: FC<IconProps> = (props): JSX.Element => (
  <svg {...props}>
    <path
      strokeWidth={0.1}
      d="M20.985 10.3491C20.9743 10.2995 20.9586 10.2511 20.9381 10.2047L19.1672 6.07219C18.7589 5.11734 17.7656 4.5 16.6355 4.5H7.36453C6.23531 4.5 5.24109 5.11734 4.83328 6.07219L3.06047 10.2047C3.02018 10.2979 2.99959 10.3984 3 10.5V18.75C3 18.9489 3.07902 19.1397 3.21967 19.2803C3.36032 19.421 3.55109 19.5 3.75 19.5H5.25C5.44891 19.5 5.63968 19.421 5.78033 19.2803C5.92098 19.1397 6 18.9489 6 18.75V18H18V18.75C18 18.9489 18.079 19.1397 18.2197 19.2803C18.3603 19.421 18.5511 19.5 18.75 19.5H20.25C20.4489 19.5 20.6397 19.421 20.7803 19.2803C20.921 19.1397 21 18.9489 21 18.75V10.5C21.0001 10.4493 20.995 10.3987 20.985 10.3491ZM6.75 15C6.45333 15 6.16332 14.912 5.91664 14.7472C5.66997 14.5824 5.47771 14.3481 5.36418 14.074C5.25065 13.7999 5.22094 13.4983 5.27882 13.2074C5.3367 12.9164 5.47956 12.6491 5.68934 12.4393C5.89912 12.2296 6.16639 12.0867 6.45736 12.0288C6.74833 11.9709 7.04993 12.0006 7.32402 12.1142C7.59811 12.2277 7.83238 12.42 7.9972 12.6666C8.16202 12.9133 8.25 13.2033 8.25 13.5C8.25 13.8978 8.09196 14.2794 7.81066 14.5607C7.52935 14.842 7.14782 15 6.75 15ZM17.25 15C16.9533 15 16.6633 14.912 16.4166 14.7472C16.17 14.5824 15.9777 14.3481 15.8642 14.074C15.7506 13.7999 15.7209 13.4983 15.7788 13.2074C15.8367 12.9164 15.9796 12.6491 16.1893 12.4393C16.3991 12.2296 16.6664 12.0867 16.9574 12.0288C17.2483 11.9709 17.5499 12.0006 17.824 12.1142C18.0981 12.2277 18.3324 12.42 18.4972 12.6666C18.662 12.9133 18.75 13.2033 18.75 13.5C18.75 13.8978 18.592 14.2794 18.3107 14.5607C18.0294 14.842 17.6478 15 17.25 15ZM4.88719 9.75L6.21047 6.66328C6.38015 6.26672 6.84375 6 7.36453 6H16.6355C17.1558 6 17.6198 6.26672 17.7895 6.66328L19.1128 9.75H4.88719Z"
    />
  </svg>
);

export const CircleIcon: FC<IconProps> = (props): JSX.Element => (
  <svg {...props}>
    <circle cx="12" cy="12" r="9" />
  </svg>
);

export const CurrentLocationIcon: FC<IconProps> = (props): JSX.Element => (
  <svg {...props}>
    <path
      d="M11.9999 2C13.1044 2 13.9999 2.89544 13.9999 4.00003V4.76969C16.5344 5.46921 18.5308 7.46563 19.2303 10.0001H20C21.1046 10.0001 22 10.8956 22 12.0002C22 13.1047 21.1046 14.0002 20 14.0002H19.2304C18.5308 16.5347 16.5344 18.5312 13.9999 19.2307V20.0003C13.9999 21.1049 13.1044 22.0003 11.9999 22.0003C10.8953 22.0003 9.99982 21.1049 9.99982 20.0003V19.2307C7.4653 18.5312 5.46886 16.5347 4.76934 14.0002H3.99973C2.89514 14.0002 1.99969 13.1047 1.99969 12.0002C1.99969 10.8956 2.89514 10.0001 3.99973 10.0001H4.76936C5.46889 7.46563 7.46532 5.4692 9.99982 4.76968V4.00003C9.99982 2.89544 10.8953 2 11.9999 2ZM11.9999 16.8001C14.6508 16.8001 16.7999 14.6511 16.7999 12.0001C16.7999 9.34915 14.6508 7.20012 11.9999 7.20012C9.34891 7.20012 7.19988 9.34915 7.19988 12.0001C7.19988 14.6511 9.34891 16.8001 11.9999 16.8001Z"
      strokeWidth={0.1}
    />
    <circle cx={12} cy={12} r={3} />
  </svg>
);

export const PlusMinusIcon: FC<IconProps> = (props): JSX.Element => (
  <svg {...props} fill="none" stroke="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 16.0059C14 15.4536 14.4477 15.0059 15 15.0059H21C21.5523 15.0059 22 15.4536 22 16.0059C22 16.5582 21.5523 17.0059 21 17.0059H15C14.4477 17.0059 14 16.5582 14 16.0059Z"
      fill="currentColor"
    />
    <mask id="plus-mask" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 12.0036C5 12.5559 5.44772 13.0036 6 13.0036C6.55228 13.0036 7 12.5559 7 12.0036V10.0023H9C9.55228 10.0023 10 9.55457 10 9.00229C10 8.45 9.55228 8.00229 9 8.00229H7V6.00095C7 5.44866 6.55228 5.00095 6 5.00095C5.44772 5.00095 5 5.44866 5 6.00095V8.00229H3C2.44772 8.00229 2 8.45 2 9.00229C2 9.55457 2.44772 10.0023 3 10.0023H5V12.0036Z"
      />
    </mask>
    <path
      d="M7 10.0023V8.00229H5V10.0023H7ZM7 8.00229H5V10.0023H7V8.00229ZM5 8.00229V10.0023H7V8.00229H5ZM5 10.0023H7V8.00229H5V10.0023ZM6 11.0036C6.55228 11.0036 7 11.4514 7 12.0036H3C3 13.6605 4.34315 15.0036 6 15.0036V11.0036ZM5 12.0036C5 11.4514 5.44772 11.0036 6 11.0036V15.0036C7.65685 15.0036 9 13.6605 9 12.0036H5ZM5 10.0023V12.0036H9V10.0023H5ZM9 8.00229H7V12.0023H9V8.00229ZM8 9.00229C8 8.45 8.44772 8.00229 9 8.00229V12.0023C10.6569 12.0023 12 10.6591 12 9.00229H8ZM9 10.0023C8.44772 10.0023 8 9.55457 8 9.00229H12C12 7.34543 10.6569 6.00229 9 6.00229V10.0023ZM7 10.0023H9V6.00229H7V10.0023ZM5 6.00095V8.00229H9V6.00095H5ZM6 7.00095C5.44772 7.00095 5 6.55323 5 6.00095H9C9 4.34409 7.65685 3.00095 6 3.00095V7.00095ZM7 6.00095C7 6.55323 6.55228 7.00095 6 7.00095V3.00095C4.34315 3.00095 3 4.34409 3 6.00095H7ZM7 8.00229V6.00095H3V8.00229H7ZM3 10.0023H5V6.00229H3V10.0023ZM4 9.00229C4 9.55457 3.55228 10.0023 3 10.0023V6.00229C1.34315 6.00229 0 7.34543 0 9.00229H4ZM3 8.00229C3.55228 8.00229 4 8.45 4 9.00229H0C0 10.6591 1.34315 12.0023 3 12.0023V8.00229ZM5 8.00229H3V12.0023H5V8.00229ZM7 12.0036V10.0023H3V12.0036H7Z"
      fill="currentColor"
      mask="url(#plus-mask)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.354 5.10044C15.8626 5.31562 16.1006 5.90246 15.8856 6.41117L10.4206 19.3395C10.2056 19.8482 9.6189 20.0862 9.11028 19.871C8.60165 19.6558 8.36365 19.069 8.57869 18.5603L14.0437 5.63192C14.2587 5.12321 14.8454 4.88526 15.354 5.10044Z"
      fill="currentColor"
    />
  </svg>
);
