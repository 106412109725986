import { useEffect, useState } from "react";
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";
import logger from "../utils/logger";
import useWindowFocus from "./useWindowFocus";

type StorageItemResult<T> = [T, (value: T) => Promise<void>];

/**
 * Based on https://github.com/capacitor-community/react-hooks/blob/main/packages/storage/src/useStorage.ts
 * but using `SecureStoragePlugin` instead of `Storage`
 */
const useStorageItem = <T>(key: string, initialValue?: T): StorageItemResult<T | undefined> => {
  const [storedValue, setStoredValue] = useState<T>();
  const windowFocused = useWindowFocus();

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const result = await SecureStoragePlugin.get({ key });
        if (result.value === undefined && initialValue !== undefined) {
          await setValue(initialValue);
        } else if (result.value) {
          setStoredValue(JSON.parse(result.value));
        } else {
          setStoredValue(undefined);
        }
      } catch (e) {
        if (initialValue !== undefined) {
          logger.debug(`Failed to load key: ${key}`, e);
          await setValue(initialValue);
        }
      }
    })();
  }, [SecureStoragePlugin, setStoredValue, key, windowFocused]); // eslint-disable-line react-hooks/exhaustive-deps

  const setValue = async (value: T | undefined): Promise<void> => {
    try {
      const valueAsString = JSON.stringify(value);

      logger.debug(`Storing: ${key} - ${valueAsString}`);
      setStoredValue(value);
      if (!value) {
        await SecureStoragePlugin.remove({ key });
      } else {
        await SecureStoragePlugin.set({ key, value: valueAsString });
      }
    } catch (e) {
      logger.error(`Failed to store with key: ${key}`, e);
    }
  };

  return [storedValue, setValue];
};

export default useStorageItem;
