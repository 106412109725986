import { RxDatabase, RxDocumentData } from "rxdb";
import { isEmpty } from "lodash-es";
import { Field, HasuraField } from "../types/Field";
import { DBCollections } from "./databaseUtil";
import { getSortedFields } from "./submissionUtil";

export const getSortedFieldsWithParentsFirst = async (
  fieldsWithUpdate: Field[],
  db: RxDatabase<DBCollections>,
): Promise<Field[]> => {
  // When a field hasn't been synced to the remote before, there is a chance that the parent field/entries hasn't yet been set up correctly.
  // To mitigate this, we will include the parent fields for the fields which haven't been synced yet.
  // If the field was synced before, this shouldn't be necessary, so skip it.
  const fieldIds = fieldsWithUpdate.map((x) => x.id);
  const syncedFieldMeta = await db.fieldmeta.find().where("id").in(fieldIds).where("remote").eq(true).exec();
  const syncedFieldsMap = new Map(syncedFieldMeta.map((field) => [field.id, field]));
  const syncedFields = fieldsWithUpdate.filter((field) => syncedFieldsMap.has(field.id));
  const localFields = fieldsWithUpdate.filter((field) => !syncedFieldsMap.has(field.id));

  // Filter out orphaned local fields
  const localFieldsWithParents = await getValidFieldsWithParents(localFields, db);

  // Combine and sort
  const fields = [...syncedFields, ...localFieldsWithParents];
  const uniqueFields = deduplicateFields(fields);
  return getSortedFields(uniqueFields);
};

export const removeOrphanedFields = (fields: Field[]): Field[] => {
  const subformFields = fields.filter((field) => field.entries?.length > 0);
  const allEntryIds = subformFields.flatMap((field) => field.entries.map((entry) => entry.id));
  const orphanFieldIds = fields
    .filter((field) => field.entryId && !allEntryIds.includes(field.entryId))
    .map((field) => field.id);

  if (!isEmpty(orphanFieldIds)) {
    // Recursively removing orphans from the updated set of fields
    return removeOrphanedFields(fields.filter((field) => !orphanFieldIds.includes(field.id)));
  }

  return fields;
};

export const getValidFieldsWithParents = async (fields: Field[], db: RxDatabase<DBCollections>): Promise<Field[]> => {
  const fieldParentIds = getParentFieldIds(fields);
  const fieldParents = await getParentFields(db, fieldParentIds);
  const allParents = await getRecursiveParents(db, fieldParents);
  const allParentsMap = new Map(allParents.map((field) => [field.id, field]));

  const updateFields: Field[] = [];
  fields.forEach((field) => updateFields.push(...getFieldWithParents(field, allParentsMap)));

  return updateFields;
};

const getParentFields = async (db: RxDatabase<DBCollections>, parentIds: string[]): Promise<RxDocumentData<Field>[]> =>
  [...(await db.fields.findByIds(parentIds).exec()).values()].map((field) => field._data); // eslint-disable-line no-underscore-dangle

const getParentFieldIds = (fields: Field[]): string[] => fields.map((x) => x.parentId).filter((x) => x) as string[];

const getRecursiveParents = async (
  db: RxDatabase<DBCollections>,
  parentFields: RxDocumentData<Field>[],
): Promise<RxDocumentData<Field>[]> => {
  const parentIds = getParentFieldIds(parentFields);
  const newParents = await getParentFields(db, parentIds);
  if (!isEmpty(newParents)) {
    return [...(await getRecursiveParents(db, newParents)), ...parentFields];
  }
  return parentFields;
};

const deduplicateFields = (fields: Field[]): Field[] =>
  fields.reduce((prev, curr) => {
    if (prev.some((i) => i.id === curr.id)) {
      return prev;
    }
    return [...prev, curr];
  }, [] as Field[]);

export const getFieldsForSubmissionIds = (fields: Field[], submissionIds: string[]): Field[] =>
  fields.filter((field) => submissionIds.includes(field.submissionId));

const getFieldWithParents = (field: Field, parentMap: Map<string, Field>): Field[] => {
  if (field.parentId) {
    const parent = parentMap.get(field.parentId);
    if (parent?.entries.find((entry) => entry.id === field.entryId)) {
      const parents = getFieldWithParents(parent, parentMap);
      // Only return the entire chain if all parents in the chain are valid
      if (!isEmpty(parents)) {
        return [...parents, field];
      }
    }
    // If the parent is not found or the entry is not in the parent, return an empty array
    return [];
  }
  // If there is no parentId, return an array with only the current field, we're at the root
  return [field];
};

export const scrubHasuraFields = (fields: HasuraField[]): HasuraField[] =>
  fields.map((field) => ({
    ...field,
    data: undefined,
    meta: { ...field.meta, deviceId: undefined, evaluatedRules: undefined },
  }));
