import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import HtmlContent from "../HtmlContent";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";
import { IconButton } from "../../storybook/components/IconButton/IconButton";
import { Modal } from "../../storybook/components/Modal/Modal";
import WidgetContainer from "../WidgetContainer";

export interface WidgetHelpProperties {
  label_text: never;
  required: boolean;
  title: string;
  explanation: string;
  use_help_text: boolean;
  use_help_text_value?: string;
}

const WidgetHelp: Widget<WidgetHelpProperties, WidgetResult<void>> = ({ fieldState }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  return (
    <WidgetContainer fieldState={fieldState} name="HELP_FIELD" className="-mb-4 mt-3 flex justify-end">
      {fieldState.properties.use_help_text ? (
        <IconAndTextButton
          label={fieldState.properties.use_help_text_value ?? ""}
          variant="transparentBrand"
          size="md"
          icon="QuestionMarkCircleIcon"
          iconAlign="right"
          onClick={() => setShowModal(true)}
        />
      ) : (
        <IconButton
          aria-label={t("OPEN_HELP_EXPLANATION")}
          variant="transparentBrand"
          size="md"
          icon="QuestionMarkCircleIcon"
          onClick={() => setShowModal(true)}
        />
      )}

      <Modal
        title={fieldState.properties.title}
        content={{
          kind: "freestyle",
          children: <HtmlContent html={fieldState.properties.explanation} />,
        }}
        open={showModal}
        onClose={() => setShowModal(false)}
        buttons={[
          {
            variant: "default",
            label: t("CLOSE"),
            onClick: () => setShowModal(false),
          },
        ]}
      />
    </WidgetContainer>
  );
};

WidgetHelp.defaultValue = (_properties, defaultMeta: any): WidgetResult<void> => ({
  type: "none",
  meta: {
    widget: "help",
    ...defaultMeta,
  },
});

WidgetHelp.validate = (): undefined => undefined;

export default WidgetHelp;
