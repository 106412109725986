import { FC, useState } from "react";
import { useNavigate } from "react-router";
import { dehydrate, useQueryClient } from "@tanstack/react-query";
import { useLocalStorage } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import Content from "../components/Content";
import logger from "../utils/logger";
import useAuth from "../hooks/useAuth";
import { migrate } from "../database/migration/00_legacy-app-to-current/migrationUtil";
import { useMoreAppClient } from "../context/MoreAppContext";
import useToasts from "../hooks/useToasts";
import ImpersonateUserDrawer from "../components/ImpersonateUserDrawer";
import useApiPermission from "../hooks/useApiPermission";
import Page from "../components/Page";
import { Text } from "../storybook/components/Text/Text";
import { Header } from "../storybook/components/Header/Header";
import { Title } from "../storybook/components/Title/Title";
import { TextButton } from "../storybook/components/TextButton/TextButton";
import useDevMode from "../hooks/useDevMode";

const DeveloperPage: FC = () => {
  const queryClient = useQueryClient();
  const { authorization, impersonatedUser } = useAuth();
  const client = useMoreAppClient();
  const navigate = useNavigate();
  const { showToast } = useToasts();
  const [, setDevMode] = useDevMode();
  const [sorting, setSorting] = useLocalStorage("alphabeticalSorting", false);
  const [showImpersonationModal, setShowImpersonationModal] = useState(false);
  const { isSuperAdmin } = useApiPermission();
  const { t } = useTranslation();

  const currentCommit = process.env.VITE_COMMIT_SHA;

  return (
    <Page>
      <Header
        className="mt-safe"
        title="Developer"
        backBtn={{ ariaLabel: t("BACK"), onClick: () => navigate("/settings") }}
      />
      <Content>
        <Text>
          Current commit: <b>{currentCommit}</b>
        </Text>
        <Text>
          Alphabetical Sorting: <b>{sorting ? "Yes" : "No"}</b>
        </Text>
        {impersonatedUser && (
          <Text>
            Impersonating: <b>{impersonatedUser}</b>
          </Text>
        )}
        <Title as="h4" size="lg">
          General
        </Title>
        <TextButton
          label="Throw new error"
          onClick={() => {
            throw new Error("Break!");
          }}
        />
        <TextButton
          label="Disable Developer mode"
          onClick={() => {
            setDevMode(false);
          }}
        />
        <Title as="h4" size="lg">
          React Query
        </Title>
        <TextButton
          label="Dump React Query state to logs"
          onClick={() => {
            logger.log("React Query state", dehydrate(queryClient, {}));
          }}
        />
        <TextButton label="Clear React Query state" onClick={() => queryClient.resetQueries()} />
        <Title as="h4" size="lg">
          Migration
        </Title>
        <TextButton label="Migrate registrations" onClick={() => migrate(authorization.username!, client!)} />
        <Title as="h4" size="lg">
          Toasts
        </Title>
        <TextButton label="Show toast" onClick={() => showToast({ message: "Testerdetest" })} />
        <Title as="h4" size="lg">
          Sorting
        </Title>
        <TextButton label="Toggle sorting on alphabet" onClick={() => setSorting(!sorting)} />
        {isSuperAdmin && (
          <>
            <Title as="h4" size="lg">
              Impersonate
            </Title>
            <TextButton label="Impersonate User" onClick={() => setShowImpersonationModal(true)} />
            <ImpersonateUserDrawer open={showImpersonationModal} onClose={() => setShowImpersonationModal(false)} />
            <TextButton label="Clear" onClick={() => setShowImpersonationModal(true)} />
          </>
        )}
      </Content>
    </Page>
  );
};

export default DeveloperPage;
