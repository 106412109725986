import { FC, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "usehooks-ts";
import useApiPermission from "../hooks/useApiPermission";
import useFolders from "../hooks/useFolders";
import ErrorDisplay from "../components/ErrorDisplay";
import Content from "../components/Content";
import { fromPlatformIcon } from "../utils/iconUtil";
import useAuth from "../hooks/useAuth";
import AddFormsDrawer from "../components/AddFormsDrawer";
import { createNewSubmission } from "../utils/submissionUtil";
import SearchDrawer from "../components/SearchDrawer";
import ResourceImage from "../components/ResourceImage";
import SubmissionHistoryDrawer from "../components/SubmissionHistoryDrawer";
import EmptyContent from "../components/EmptyContent";
import { compareByLocale, isUsable } from "../utils/formUtil";
import Page from "../components/Page";
import useNavigateAsync from "../hooks/useNavigateAsync";
import useDrawer from "../hooks/useDrawer";
import useDeviceOrientation from "../hooks/useDeviceOrientation";
import { NavItem } from "../storybook/components/NavItem/NavItem";
import { TextButton } from "../storybook/components/TextButton/TextButton";
import { Spinner } from "../storybook/components/Spinner/Spinner";
import { Header } from "../storybook/components/Header/Header";
import { Title } from "../storybook/components/Title/Title";
import PullToRefresh from "../components/PullToRefresh";
import logger from "../utils/logger";
import { isTouchCapable } from "../utils/deviceUtil";
import { resolveTheme } from "../storybook/themes";
import useSubmissionCollection from "../hooks/useSubmissionCollection";

const FormsPage: FC = () => {
  const { t } = useTranslation();
  const { error, data: folders, refetch, isLoading } = useFolders();
  const navigate = useNavigateAsync();
  const [showAddForm, setShowAddForm] = useState(false);
  const submissions = useSubmissionCollection();
  const { customerId } = useAuth();
  const params = useParams<{ id: string }>();
  const [search, setSearch] = useDrawer("search-form");
  const [alphabeticalSorting] = useLocalStorage("alphabeticalSorting", false);
  const { isFormCreator } = useApiPermission();
  const { landscapeIndent } = useDeviceOrientation(false);

  const folder = useMemo(() => {
    if (isLoading || folders === undefined) {
      return undefined;
    }
    return folders.find((f) => f.id === params.id) || navigate("/folders");
  }, [folders, params.id, isLoading, navigate]);

  const [submissionSearchOpen, setSubmissionSearchOpen] = useDrawer("history");
  const [submissionSearch, setSubmissionSearch] = useState<{ formId: string; formVersionId: string } | undefined>();
  const onCloseSubmissionSearch = useCallback(() => setSubmissionSearchOpen(false), [setSubmissionSearchOpen]);

  const isRefreshable = useMemo(() => isTouchCapable() && !search && !showAddForm, [search, showAddForm]);

  const forms = useMemo(() => {
    if (!submissions || !customerId) {
      return undefined;
    }
    return folder?.forms
      .filter(isUsable)
      .sort((a, b) => (alphabeticalSorting ? compareByLocale(a.meta.name, b.meta.name) : 0))
      .map((form) => (
        <NavItem
          className="py-1"
          key={form.id}
          icon={{
            name: fromPlatformIcon(form.meta.icon),
            theme: resolveTheme(form.meta.iconColor),
          }}
          btnAriaLabel={`${t("OPEN_FORM_OPTIONS")}, ${form.meta.name}`}
          label={form.meta.name}
          description={form.meta.description}
          onClick={async () => {
            const submission = await createNewSubmission(form, customerId, submissions);
            navigate(`/submissions/${submission.id}`);
          }}
          menuItems={[
            {
              label: t("HISTORY_BUTTON"),
              onClick: (): void => {
                setSubmissionSearch({
                  formId: form.id,
                  formVersionId: form.publishedVersion.formVersion,
                });
                setSubmissionSearchOpen(true);
              },
            },
          ]}
        />
      ));
  }, [alphabeticalSorting, customerId, folder?.forms, navigate, setSubmissionSearchOpen, submissions, t]);

  if (!folder) {
    return null;
  }

  const emptyUserContent = (
    <EmptyContent
      title={t("FOLDER_EMPTY_TITLE")}
      description={t("FOLDER_EMPTY_DESCRIPTION")}
      imgSrc="assets/forms/empty.svg"
    />
  );

  const emptyState = (
    <EmptyContent
      title={t("FOLDERS_EMPTY_TITLE")}
      description={t("FOLDERS_EMPTY_DESCRIPTION")}
      imgSrc="assets/forms/empty.svg"
      imgAlt={t("FOLDERS_EMPTY_ALT")}
    >
      <TextButton variant="primary" label={t("FOLDERS_EMPTY_CTA")} onClick={() => setShowAddForm(true)} />
    </EmptyContent>
  );

  const formsContent = (): JSX.Element => {
    if (!forms) {
      return (
        <div className="mt-3" data-testid="spinner">
          <Spinner className="mx-auto" />
        </div>
      );
    }
    if (forms.length > 0) {
      return <div className={`mt-3 ${landscapeIndent}`}>{forms}</div>;
    }
    return isFormCreator ? emptyState : emptyUserContent;
  };

  return (
    <Page className="overflow-y-auto">
      <Header
        className="shrink-0 mt-safe"
        title={folder.meta.name}
        actions={[
          {
            "aria-label": t("SEARCH"),
            kind: "icon",
            className: "lg:hidden",
            icon: "SearchIcon",
            onClick: () => setSearch(true),
          },
        ]}
        backBtn={{ ariaLabel: t("BACK"), onClick: () => navigate("/folders") }}
      />
      <SearchDrawer open={search} onClose={() => setSearch(false)} initialCategory="FORMS" />
      <SubmissionHistoryDrawer
        open={submissionSearchOpen}
        formId={submissionSearch?.formId}
        formVersionId={submissionSearch?.formVersionId}
        onClose={onCloseSubmissionSearch}
      />
      <PullToRefresh disabled={!isRefreshable} onRefresh={() => refetch()}>
        <Content padding={false}>
          <>
            {error && <ErrorDisplay error={error} />}
            <Title as="h2" size="xs" className="sr-only">
              {t("FORMS")}
            </Title>
            {folder.meta.image && <ResourceImage resourceId={folder.meta.image} />}
            {formsContent()}
          </>
        </Content>
      </PullToRefresh>
      {showAddForm && (
        <AddFormsDrawer
          open={showAddForm}
          folderId={folder.id}
          onClose={() => {
            setShowAddForm(false);
            refetch().catch((e) => logger.error("Unable to refresh", e));
          }}
        />
      )}
    </Page>
  );
};
export default FormsPage;
