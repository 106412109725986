import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MoreAppError } from "../context/MoreAppContext";
import useToasts from "./useToasts";

const useShowToastAndGoHomeOnInvalidFormVersion = (errorFormVersion: MoreAppError | null): void => {
  const { t } = useTranslation();
  const location = useLocation();
  const { showToast } = useToasts();
  const navigate = useNavigate();

  useEffect(() => {
    if (errorFormVersion) {
      showToast({
        message: errorFormVersion.status === 403 ? t("TASKS_NO_ACCESS") : t("TASKS_FAILED_TO_LOAD"),
      });
      location.key !== "default" ? navigate(-1) : navigate("/folders", { replace: true });
    }
  }, [errorFormVersion, showToast, navigate, location, t]);
};

export default useShowToastAndGoHomeOnInvalidFormVersion;
