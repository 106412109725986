import { CameraSource } from "@capacitor/camera";
import {
  ReactZoomPanPinchContentRef,
  ReactZoomPanPinchState,
  TransformComponent,
  TransformWrapper,
  useTransformEffect,
} from "react-zoom-pan-pinch";
import { FC, MouseEvent, PropsWithChildren, ReactElement, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Img from "../../Img";
import Pin from "./Pin";
import useFocus from "../../../hooks/useFocus";
import { ActiveEntry, FileResult, PinResultMeta } from "../../../types/Widget";
import { PinConfig, WidgetPinProperties } from "../WidgetPin";
import uuidv4 from "../../../utils/uuid";
import { SubformEntry } from "../WidgetSubform";
import {
  calculateClickCoords,
  Dimensions,
  getFormId,
  getFormVersion,
  getLocation,
  getPinOption,
} from "../../../utils/pinUtil";
import { UploadStatus } from "../../../types/Field";
import useDatasourceImages from "../../../hooks/useDatasourceImages";
import useCamera, { InsufficientPermissionError } from "../../../hooks/useCamera";
import logger from "../../../utils/logger";
import useFileHandler from "../../../hooks/useFileHandler";
import InsufficientPermissionsModal from "../../InsufficientPermissionsModal";
import { useResources } from "../../../hooks/useResource";
import { Drawer } from "../../../storybook/components/Drawer/Drawer";
import { Chips } from "../../../storybook/components/Chips/Chips";
import { Modal } from "../../../storybook/components/Modal/Modal";
import { IconButton } from "../../../storybook/components/IconButton/IconButton";
import { DropdownMenu } from "../../../storybook/components/DropdownMenu/DropdownMenu";
import PdfImageUpload, { ConvertStatus, PdfImageUploadMethods } from "./PdfImageUpload";
import useOnlineStatus from "../../../hooks/useOnlineStatus";
import DatasourceImageSelect from "../search/DatasourceImageSelect";
import { UniqueFieldId } from "../../../types/SubmissionState";
import { fileTypes, getExtensionUri, isExtensionAllowedMimeType } from "../../../utils/fileUtil";
import useActionAddEntry from "../../../state/useActionAddEntry";
import useStateFieldProperties from "../../../state/useStateFieldProperties";

interface PinAddDrawerProps {
  uniqueFieldId: UniqueFieldId;
  entries: SubformEntry<any>[];
  submissionId: string;
  setActiveEntry: (activeEntry: ActiveEntry | undefined) => void;
  open: boolean;
  onClose: () => void;
  pins: PinConfig[];
  widgetProps: WidgetPinProperties;
  imgUrl?: string;
  windowSize: Dimensions;
  addSelectedImage: (url: string) => void;
  onUpload: (fileResult: FileResult, uploadStatus: UploadStatus) => void;
}

const fallbackImageClassName = "fallback-image";

type ImageSource = "camera" | "photos" | "pdf" | "datasource";
const PinAddDrawer: FC<PropsWithChildren<PinAddDrawerProps>> = ({
  uniqueFieldId,
  entries,
  submissionId,
  setActiveEntry,
  open,
  pins,
  widgetProps,
  onClose,
  imgUrl,
  windowSize,
  addSelectedImage,
  onUpload,
  children,
}) => {
  const { t } = useTranslation();
  const [pinDrawerRef] = useFocus();
  const [selectedPin, setSelectedPin] = useState<PinConfig>(pins[0]);
  const [imageSource, setImageSource] = useState<ImageSource>();
  const [img, setImg] = useState<HTMLImageElement>();
  const imgRef = useCallback((ref: HTMLImageElement) => ref && setImg(ref), []);
  const [selectImageDrawer, setSelectImageDrawer] = useState(false);
  const images = useDatasourceImages(submissionId);
  const { getPhoto } = useCamera();
  const { storeFileUri } = useFileHandler();
  const { isOnline } = useOnlineStatus();
  const pdfRef = useRef<PdfImageUploadMethods>(null);
  const [, setConvertState] = useState<ConvertStatus>("pending");
  const addEntry = useActionAddEntry();
  const fieldProperties = useStateFieldProperties();

  const resourceIds = useMemo(
    () => pins?.filter((pin) => pin.icon?.type === "RESOURCE").map((pin) => pin.icon.value) ?? [],
    [pins],
  );
  const resources = useResources(resourceIds);
  const zoomPanPinchRef = useRef<ReactZoomPanPinchContentRef>(null);

  const [originalDimensions, setOriginalDimensions] = useState({ width: 0, height: 0 });
  const [showPermissionsModal, setPermissionsModal] = useState(false);

  const isFallback = !img || img.classList.contains(fallbackImageClassName);

  const addPhoto = async (source: CameraSource): Promise<void> => {
    try {
      const photo = await getPhoto(source);
      const id = uuidv4();

      const extension = await getExtensionUri(photo.webPath!);
      if (!extension || !isExtensionAllowedMimeType(extension, fileTypes.images)) {
        return;
      }

      const { fileResult, uploadStatus } = await storeFileUri(photo.webPath!, submissionId, id);
      onUpload(fileResult, uploadStatus);
    } catch (error: any) {
      if (error instanceof InsufficientPermissionError) {
        setPermissionsModal(true);
        return;
      }
      if (error.message !== "USER_CANCELLED") {
        logger.error("Failed to add Pin-photo in drawer", error);
      }
    } finally {
      setImageSource(undefined);
    }
  };

  const handleAdd = (e: MouseEvent<HTMLDivElement>): void => {
    const formId = getFormId(selectedPin);
    const formVersion = getFormVersion(formId!, pins, fieldProperties);
    if (!selectedPin) {
      return;
    }

    const meta: PinResultMeta = {
      location: calculateClickCoords(e, originalDimensions, img),
      scope: { name: selectedPin.name ?? "", target: formId },
      icon: selectedPin.icon,
    };
    const entryId = addEntry(uniqueFieldId, formVersion, meta);
    if (formVersion) {
      setActiveEntry({ id: entryId });
    }
  };

  const getOptionalSelectImageButton = useMemo(
    () => (images.length > 0 ? [{ label: t("SELECT_PHOTO"), onClick: () => setImageSource("datasource") }] : []),
    [images], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getOptionalUploadPdfButton = useMemo(
    () => (isOnline ? [{ label: t("UPLOAD_PDF"), onClick: () => setImageSource("pdf") }] : []),
    [isOnline], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const editImageBtn = useMemo(
    () =>
      widgetProps.allow_change_image && (
        <div className="absolute right-4 top-4">
          <DropdownMenu
            menuButton={() => <IconButton aria-label={t("CHANGE_IMAGE")} icon="PencilIcon" />}
            items={[
              { label: t("CAPTURE_PHOTO"), onClick: () => setImageSource("camera") },
              { label: t("BROWSE_PHOTOS"), onClick: () => setImageSource("photos") },
              ...getOptionalUploadPdfButton,
              ...getOptionalSelectImageButton,
            ]}
          />
        </div>
      ),
    [getOptionalUploadPdfButton, getOptionalSelectImageButton, t, widgetProps.allow_change_image],
  );
  return (
    <Drawer
      open={open}
      header={{
        kind: "simple",
        title: widgetProps.label_text,
        button: { kind: "icon", icon: "XIcon", onClick: onClose },
        content: (
          <div ref={pinDrawerRef} className="px-5 pb-4">
            <Chips
              selected={selectedPin.name}
              onSelect={(value) => setSelectedPin(pins.find((x) => x.name === value) ?? pins[0])}
              options={pins.map((p) => getPinOption(p, resources))}
            />
          </div>
        ),
      }}
      footer={{ kind: "default", primaryButton: { label: t("SAVE"), onClick: onClose } }}
      onClose={onClose}
      initialFocus={pinDrawerRef}
      className="bg-gray-100"
      contentPadding={false}
    >
      <div className="relative size-full">
        <TransformWrapper ref={zoomPanPinchRef} centerOnInit doubleClick={{ disabled: true }} wheel={{ step: 1 }}>
          <TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div onClick={handleAdd}>
              <Img
                src={imgUrl}
                ref={imgRef}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                alt={t("PIN_IMAGE")}
                fallbackClassName={fallbackImageClassName}
                onLoad={() => {
                  setOriginalDimensions({
                    width: img?.naturalWidth ?? 0,
                    height: img?.naturalHeight ?? 0,
                  });

                  zoomPanPinchRef.current?.resetTransform(0);
                }}
              />
              {windowSize &&
                !isFallback &&
                entries
                  .filter((entry) => !entry.deleted)
                  .map((pin, index) => (
                    <PinWrapper
                      key={index}
                      component={(state) => (
                        <Pin
                          location={getLocation(img, pin.meta.location, originalDimensions, state.scale)}
                          number={pin.meta.order}
                          icon={pin.meta.icon}
                          keepScale
                        />
                      )}
                    />
                  ))}
            </div>
          </TransformComponent>
          {editImageBtn}
        </TransformWrapper>
      </div>
      {children} {/* Form drawer */}
      <Modal
        title={t("CHANGE_IMAGE")}
        content={{ kind: "message", message: t("CHANGE_IMAGE_CONFIRM") }}
        open={!!imageSource}
        onClose={() => setImageSource(undefined)}
        buttons={[
          { label: t("CANCEL"), onClick: () => setImageSource(undefined) },
          {
            label: t("CHANGE"),
            variant: "primary",
            onClick: (): void => {
              switch (imageSource) {
                case "camera":
                  addPhoto(CameraSource.Camera).catch((e) => logger.error("Unable to change photo with camera", e));
                  break;
                case "photos":
                  addPhoto(CameraSource.Photos).catch((e) => logger.error("Unable to change photo with gallery", e));
                  break;
                case "pdf":
                  pdfRef.current?.onClick();
                  setImageSource(undefined);
                  break;
                case "datasource":
                  setSelectImageDrawer(true);
                  setImageSource(undefined);
                  break;
                default:
              }
            },
          },
        ]}
      />
      <DatasourceImageSelect
        open={selectImageDrawer}
        setOpen={setSelectImageDrawer}
        images={images}
        onSelect={addSelectedImage}
      />
      <PdfImageUpload
        submissionId={submissionId}
        persistWithUploadStatus={onUpload}
        setConvertState={setConvertState}
        ref={pdfRef}
      />
      {showPermissionsModal && (
        <InsufficientPermissionsModal show={showPermissionsModal} onClose={() => setPermissionsModal(false)} />
      )}
    </Drawer>
  );
};

type PinWrapperProps = {
  component: (state: ReactZoomPanPinchState) => ReactElement<any, any>;
};

const PinWrapper: FC<PinWrapperProps> = ({ component }) => {
  const [transformState, setTransformState] = useState<ReactZoomPanPinchState>();

  useTransformEffect(({ state }) => {
    setTransformState({ ...state });
  });

  return transformState ? component(transformState) : null;
};

export default PinAddDrawer;
