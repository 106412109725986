import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { isNil } from "lodash-es";
import { PinResultMeta } from "../../../types/Widget";
import { SubformEntry } from "../WidgetSubform";
import { calculateClickCoords } from "../../../utils/pinUtil";
import { Text } from "../../../storybook/components/Text/Text";
import { Drawer } from "../../../storybook/components/Drawer/Drawer";
import { UniqueFieldId } from "../../../types/SubmissionState";
import useActionUpdateEntry from "../../../state/useActionUpdateEntry";

interface PinMoveDrawerProps {
  uniqueFieldId: UniqueFieldId;
  entry?: SubformEntry<PinResultMeta>;
  onClose: () => void;
  imgUrl?: string;
}

const PinMoveDrawer: FC<PinMoveDrawerProps> = ({ entry, imgUrl, onClose, uniqueFieldId }) => {
  const { t } = useTranslation();
  const [originalDimensions, setOriginalDimensions] = useState({ width: 0, height: 0 });
  const [img, setImg] = useState<HTMLImageElement>();
  const imgRef = useCallback((ref: HTMLImageElement) => ref && setImg(ref), []);
  const updateEntry = useActionUpdateEntry();

  const handleMove = (e: any): void => {
    if (isNil(entry)) {
      throw new Error("Pin: Can't move entry as entry is undefined");
    }
    const updatedEntry: SubformEntry<PinResultMeta> = {
      ...entry,
      meta: { ...entry.meta, location: calculateClickCoords(e, originalDimensions, img) },
    };
    updateEntry(updatedEntry, uniqueFieldId);
    onClose();
  };

  return (
    <Drawer
      open={!isNil(entry)}
      header={{
        kind: "simple",
        title: t("MOVE_PIN", { pin: entry?.meta.order }),
        button: {
          kind: "icon",
          icon: "XIcon",
          onClick: () => onClose(),
        },
        content: (
          <Text className="pb-4 text-center" color="medium">
            {t("CHOOSE_PIN_LOCATION")}
          </Text>
        ),
      }}
      onClose={() => onClose()}
    >
      <TransformWrapper centerOnInit>
        <TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div onClick={(e) => handleMove(e)}>
            <img
              src={imgUrl}
              ref={imgRef}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
              alt={t("PIN_IMAGE")}
              onLoad={() =>
                setOriginalDimensions({
                  width: img?.naturalWidth ?? 0,
                  height: img?.naturalHeight ?? 0,
                })
              }
            />
          </div>
        </TransformComponent>
      </TransformWrapper>
    </Drawer>
  );
};

export default PinMoveDrawer;
