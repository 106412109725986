import { FC } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TFunction } from "i18next";
import type { ObjectSchema } from "yup";
import Content from "./Content";
import useAuth from "../hooks/useAuth";
import { useMoreAppClient } from "../context/MoreAppContext";
import { Drawer } from "../storybook/components/Drawer/Drawer";
import { TextInput } from "../storybook/components/TextInput/TextInput";

type Impersonation = {
  username: string;
};

interface ImpersonateUserDrawerProps {
  open: boolean;
  onClose: () => void;
}

const validationSchema = (t: TFunction): ObjectSchema<{ username: string }> =>
  yup.object({ username: yup.string().required(t("VALIDATION_REQUIRED")) });

const ImpersonateUserDrawer: FC<ImpersonateUserDrawerProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { setImpersonatedUser } = useAuth();
  const client = useMoreAppClient();
  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm<Impersonation>({
    resolver: yupResolver(validationSchema(t)),
  });

  const closeDrawer = (): void => onClose();

  const onSubmit = async ({ username }: Impersonation): Promise<void> => {
    try {
      await client?.get(`/api/v1.0/admin/users/${username}`);
      setImpersonatedUser(username);
      closeDrawer();
    } catch (e) {
      setError("username", { message: "Couldn't impersonate (wrong username?)" }, { shouldFocus: true });
    }
  };

  return (
    <Drawer
      open={open}
      header={{
        kind: "simple",
        title: "Impersonate User",
        button: { kind: "icon", icon: "XIcon", onClick: closeDrawer },
      }}
      onClose={closeDrawer}
      footer={{
        kind: "default",
        primaryButton: {
          label: "Impersonate",
          disabled: isSubmitting,
          onClick: handleSubmit(onSubmit),
        },
      }}
    >
      <Content>
        <form className="mt-6 flex flex-col space-y-6">
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <TextInput
                name={field.name}
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value}
                type="text"
                label={t("USERNAME")}
                errorMessage={errors.username?.message}
                inputRef={field.ref}
              />
            )}
          />
        </form>
      </Content>
    </Drawer>
  );
};

export default ImpersonateUserDrawer;
