import { CSSProperties, FC } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useTranslation } from "react-i18next";
import { IconButton } from "../storybook/components/IconButton/IconButton";
import ResourceImage from "./ResourceImage";
import { Drawer } from "../storybook/components/Drawer/Drawer";
import useDrawer from "../hooks/useDrawer";

type ClickableImageProps = {
  resourceId: string;
  style?: CSSProperties;
};

const ClickableImage: FC<ClickableImageProps> = ({ resourceId, style }) => {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useDrawer(resourceId);

  return (
    <>
      <button
        aria-label={t("VIEW_ENTIRE_IMAGE")}
        onClick={() => setDrawerOpen(true)}
        className="relative block w-full overflow-hidden rounded-lg bg-gray-100 outline-none hover:bg-gray-200 focus-visible:ring"
      >
        <div className="absolute right-4 top-4">
          <IconButton aria-label={t("VIEW_ENTIRE_IMAGE")} icon="EyeIcon" />
        </div>
        <ResourceImage className="mx-auto w-full" resourceId={resourceId} style={style} />
      </button>
      <Drawer
        open={drawerOpen}
        contentPadding={false}
        header={{
          kind: "simple",
          title: t("VIEW_IMAGE"),
          button: {
            kind: "icon",
            icon: "XIcon",
            onClick: () => setDrawerOpen(false),
          },
        }}
        onClose={() => setDrawerOpen(false)}
      >
        <TransformWrapper centerOnInit>
          <TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
            <ResourceImage
              resourceId={resourceId}
              style={{
                ...style,
                maxWidth: "100%",
                maxHeight: "100%",
                width: "1000px",
                height: "1000px",
                objectFit: "scale-down",
              }}
            />
          </TransformComponent>
        </TransformWrapper>
      </Drawer>
    </>
  );
};

export default ClickableImage;
