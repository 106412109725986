import { Geolocation } from "@capacitor/geolocation";
import { LatLngLiteral } from "leaflet";
import axios from "axios";
import { SearchResult } from "leaflet-geosearch/lib/providers/provider";
import addressFormatter from "@fragaria/address-formatter";
import { OpenCageProvider } from "leaflet-geosearch";
import { SubmissionLocation } from "../types/Submission";
import { LocationResult } from "../types/Widget";
import { LOCATION_BASE_URL } from "../constants";
import { locationToHumanReadableString } from "./stringUtil";

export type Location = {
  latitude: number;
  longitude: number;
  accuracy: number;
};

export interface OpenCageResult {
  components: {
    town?: string;
    village?: string;
    city?: string;
    country: string;
    postcode?: string;
    road?: string;
    country_code?: string;
    house_number?: string;
    _type: string;
    unmappedFields: any;
  };
}

interface ReverseLocation {
  descriptiveName?: string;
  road?: string;
  houseNumber?: string;
  city?: string;
  postcode?: string;
  country?: string;
}

interface ReversedResult {
  latitude: number;
  longitude: number;
  provider: "OPENCAGE" | "GOOGLEMAPS";
  results: ReverseLocation[];
}

const WATCH_OPTIONS = { maximumAge: 90 * 1000, timeout: 10 * 1000, enableHighAccuracy: true };

export const getReverseLocation = async (coordinates: LatLngLiteral): Promise<LocationResult> => {
  const { data, status } = await axios.get<ReversedResult>(`/v2/location/reverse`, {
    params: { latitude: coordinates.lat, longitude: coordinates.lng },
    baseURL: LOCATION_BASE_URL,
  });

  const result = {
    coordinates: {
      latitude: coordinates.lat,
      longitude: coordinates.lng,
    },
    ...(status === 200 && data.results[0]
      ? {
          location: {
            descriptiveName: data.results[0].descriptiveName,
            road: data.results[0].road,
            houseNumber: data.results[0].houseNumber,
            city: data.results[0].city,
            postcode: data.results[0].postcode,
            country: data.results[0].country,
          },
        }
      : {}),
  };
  return { ...result, formattedValue: locationToHumanReadableString(result) };
};

let activeLocationPromise: Promise<Location> | undefined;

export const getLocation = (): Promise<Location> => {
  // Makes sure only one location promise runs at once, but always returns one.
  // Prevents an issues on Android; when multiple location requests get called at the same time the watchPosition returns the same id
  const locationPromise = new Promise<Location>(watchPosition);

  if (activeLocationPromise === undefined) {
    activeLocationPromise = locationPromise;
    activeLocationPromise.finally(() => {
      activeLocationPromise = undefined;
    });
  }

  return locationPromise;
};

const watchPosition = (resolve: (value: Location) => void, reject: (reason?: any) => void): void => {
  if (activeLocationPromise === undefined) {
    Geolocation.checkPermissions()
      .then((permissions) => {
        if (permissions.coarseLocation === "denied" || permissions.location === "denied") {
          reject(new Error("No Location permissions"));
        }
        // Watch and return first result instead of using getCurrentLocation(), which results in 10 sec delay
        // More info: https://github.com/ionic-team/capacitor/issues/1893#issuecomment-528320906
        const id = Geolocation.watchPosition(WATCH_OPTIONS, async (position, err) => {
          await Geolocation.clearWatch({ id: await id });
          if (err) {
            reject(err);
            return;
          }
          if (!position) {
            reject(new Error("No position after callback"));
            return;
          }
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy,
          });
        });
      })
      .catch((err) => reject(err));
  } else {
    activeLocationPromise.then((location) => resolve(location)).catch((err) => reject(err));
  }
};

export const locationToLatLng = (location?: SubmissionLocation): LatLngLiteral | undefined =>
  location?.coordinates
    ? {
        lat: location.coordinates.latitude,
        lng: location.coordinates.longitude,
      }
    : undefined;

export const formatAddress = (result: SearchResult<OpenCageResult>): string[] => {
  const addressComponents = result.raw.components;
  // eslint-disable-next-line no-underscore-dangle
  const type = addressComponents._type;
  return addressFormatter.format(
    {
      building: type ? addressComponents.unmappedFields[type] : undefined,
      city: addressComponents.town ?? addressComponents.village ?? addressComponents.city,
      postcode: addressComponents.postcode,
      road: addressComponents.road,
      houseNumber: addressComponents.house_number,
      countryCode: addressComponents.country_code,
    },
    { output: "array", appendCountry: true, abbreviate: true },
  );
};

export const openCageToLocationResult = (result: SearchResult<OpenCageResult>): LocationResult => {
  const addressComponents = result.raw.components;
  // eslint-disable-next-line no-underscore-dangle
  const type = addressComponents._type;
  return {
    coordinates: {
      latitude: result.y,
      longitude: result.x,
    },
    location: {
      descriptiveName: type ? addressComponents.unmappedFields[type] : undefined,
      road: addressComponents.road,
      city: addressComponents.town ?? addressComponents.village ?? addressComponents.city,
      postcode: addressComponents.postcode,
      houseNumber: addressComponents.house_number,
      country: addressComponents.country,
    },
    formattedValue: result.label,
  };
};

export const locationResultToLatLng = (location?: LocationResult): LatLngLiteral | undefined => {
  if (!location?.coordinates) {
    return undefined;
  }
  return {
    lat: location.coordinates?.latitude,
    lng: location.coordinates?.longitude,
  };
};

export class LocationProvider extends OpenCageProvider {
  constructor(options: any | undefined) {
    super({
      ...options,
    });
    this.searchUrl = `${LOCATION_BASE_URL}/opencage/forward`;
  }
}
