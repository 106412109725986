import { createContext, FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { RxCollection } from "rxdb";
import { Badge } from "@capawesome/capacitor-badge";
import useAuth from "../hooks/useAuth";
import { Submission } from "../types/Submission";
import useLocalSubmissions from "../hooks/useLocalSubmissions";
import logger from "../utils/logger";
import useDeviceInfo from "../hooks/useDeviceInfo";

const CUT_OFF_VALUE = 20;
const PAGE_SIZE = CUT_OFF_VALUE + 1;

const getBadge = (submissions: Submission[]): number | undefined => {
  if (!submissions || submissions.length === 0) {
    return undefined;
  }
  return submissions.length;
};

export const SubmissionBadgeProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [tasksBadge, setTasksBadge] = useState<number>();
  const [draftsBadge, setDraftsBadge] = useState<number>();
  const [sendingBadge, setSendingBadge] = useState<number>();
  const { customerId, customers } = useAuth();
  const { platform } = useDeviceInfo();

  const taskQuery = useCallback(
    (collection: RxCollection<Submission>) =>
      collection.find().where("status").eq("draft").where("customerId").eq(customerId).where("type").eq("task"),
    [customerId],
  );

  const unopenedTaskQuery = useCallback(
    (collection: RxCollection<Submission>) =>
      collection
        .find()
        .where("status")
        .eq("draft")
        .where("customerId")
        .in((customers || []).map((c) => c.customerId))
        .where("type")
        .eq("task")
        .where("viewedAt")
        .eq(null),
    [customers],
  );

  const draftQuery = useCallback(
    (collection: RxCollection<Submission>) =>
      collection
        .find()
        .where("status")
        .eq("draft")
        .where("customerId")
        .eq(customerId)
        .where("type")
        .eq("draft")
        .where("submittedAt")
        .equals(null),
    [customerId],
  );

  const sendingQuery = useCallback(
    (collection: RxCollection<Submission>) =>
      collection.find().where("customerId").eq(customerId).where("submittedAt").ne(null),
    [customerId],
  );

  const { result: tasks } = useLocalSubmissions(taskQuery, { pageSize: PAGE_SIZE });
  const { result: drafts } = useLocalSubmissions(draftQuery, { pageSize: PAGE_SIZE });
  const { result: sending } = useLocalSubmissions(sendingQuery, { pageSize: PAGE_SIZE });
  const { result: unopenedTasks } = useLocalSubmissions(unopenedTaskQuery);

  useEffect(() => setTasksBadge(getBadge(tasks)), [tasks]);
  useEffect(() => setDraftsBadge(getBadge(drafts)), [drafts]);
  useEffect(() => setSendingBadge(getBadge(sending)), [sending]);

  useEffect(() => {
    // Only iOS, we cant send badge count to android
    if (unopenedTasks && platform === "ios") {
      Badge.set({ count: unopenedTasks.length }).catch((e) => logger.error("Could not set task badge count", e));
    }
  }, [unopenedTasks]);

  return (
    <SubmissionBadgeContext.Provider
      value={{
        cutOffValue: CUT_OFF_VALUE,
        tasks: tasksBadge,
        drafts: draftsBadge,
        sending: sendingBadge,
      }}
    >
      {children}
    </SubmissionBadgeContext.Provider>
  );
};

export interface BadgeState {
  cutOffValue: number;
  drafts?: number;
  tasks?: number;
  sending?: number;
}

export const SubmissionBadgeContext = createContext<BadgeState>({} as BadgeState);
