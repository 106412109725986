import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Img from "../../Img";
import Pin from "./Pin";
import { Dimensions, getLocation } from "../../../utils/pinUtil";
import { RETRYABLE_UPLOAD_STATUSES, UploadStatus } from "../../../types/Field";
import { IconButton } from "../../../storybook/components/IconButton/IconButton";
import { Spinner } from "../../../storybook/components/Spinner/Spinner";
import { SubformEntry } from "../WidgetSubform";
import FieldUploadStatus from "../../FieldUploadStatus";

interface PinPreviewImageProps {
  entries: SubformEntry<any>[];
  imgUrl?: string;
  isDisabled: boolean;
  windowSize: Dimensions;
  onClick: () => void;
  isUploadingPhoto: boolean;
  uploadPercentage: number;
  onUploadRetry: () => void;
  uploadStatus?: UploadStatus;
  onCloseFocusId?: string;
}

const fallbackImageClassName = "fallback-image";

const PinPreviewImage: FC<PinPreviewImageProps> = ({
  entries,
  imgUrl,
  isDisabled,
  windowSize,
  onClick,
  isUploadingPhoto,
  uploadPercentage,
  onUploadRetry,
  uploadStatus,
  onCloseFocusId,
}) => {
  const { t } = useTranslation();
  const [img, setImg] = useState<HTMLImageElement>();
  const imgRef = useCallback((ref: HTMLImageElement) => ref && setImg(ref), []);
  const [originalDimensions, setOriginalDimensions] = useState({ width: 0, height: 0 });

  const isFallback = !img || img.classList.contains(fallbackImageClassName);

  return (
    <div className="relative">
      <button
        className="relative z-10 block w-full rounded-lg border-2 bg-gray-50 outline-none hover:bg-gray-100 focus-visible:ring disabled:cursor-not-allowed"
        onClick={() => onClick()}
        disabled={isDisabled}
      >
        {!isUploadingPhoto ? (
          <Img
            className="z-10 size-full max-h-72 object-contain"
            src={imgUrl}
            ref={imgRef}
            fallbackClassName={fallbackImageClassName}
            onLoad={() => setOriginalDimensions({ width: img?.naturalWidth ?? 0, height: img?.naturalHeight ?? 0 })}
            alt={t("PIN_IMAGE_PREVIEW")}
          />
        ) : (
          <div className="relative flex h-40 items-center justify-center">
            <Spinner className="size-8" />
          </div>
        )}
        {windowSize &&
          !isFallback &&
          entries.map((pin, index) => (
            <Pin
              key={index}
              location={getLocation(img, pin.meta.location, originalDimensions, 1, true)}
              number={pin.meta.order}
              icon={pin.meta.icon}
            />
          ))}
      </button>

      <div className="absolute right-4 top-4 z-10 flex space-x-5">
        {uploadStatus === "uploaded" && !isDisabled && (
          <IconButton
            id={onCloseFocusId}
            aria-label={t("OPEN_PIN_PLACEMENT_DRAWER")}
            icon="PlusIcon"
            onClick={() => onClick()}
          />
        )}

        {uploadStatus && RETRYABLE_UPLOAD_STATUSES.includes(uploadStatus) && (
          <IconButton
            aria-label={t("UPLOAD_FAILED")}
            icon="ExclamationCircleIcon"
            iconColor="destructive"
            onClick={(e) => {
              e.stopPropagation();
              onUploadRetry();
            }}
          />
        )}
      </div>

      <FieldUploadStatus percentage={uploadPercentage} status={uploadStatus} />
    </div>
  );
};

export default PinPreviewImage;
