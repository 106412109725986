import { FieldState, UniqueFieldId } from "../types/SubmissionState";
import useSubmissionStore from "./useSubmissionStore";
import { WidgetProperties } from "../types/FormVersion";
import { WidgetResult } from "../types/Field";

const useActionGetFieldStates = (): ((
  uniqueFieldIds: UniqueFieldId[],
) => FieldState<WidgetProperties, WidgetResult<unknown>>[]) => useSubmissionStore((store) => store.getFieldStates);

export default useActionGetFieldStates;
