import { FC, useMemo } from "react";
import { KeepScale } from "react-zoom-pan-pinch";
import ResourceImage from "../../ResourceImage";
import { PinIcon } from "../../../types/Widget";
import { fromPinIconUrl } from "../../../utils/iconUtil";
import { Icon } from "../../../storybook/components/Icon/Icon";
import { Badge } from "../../../storybook/components/Badge/Badge";
import { IconColor } from "../../../storybook/components/Chips/Chips";

interface PinProps {
  location: Record<string, number>;
  number: number;
  icon: PinIcon;
  keepScale?: boolean;
}

const Pin: FC<PinProps> = ({ location, number, icon, keepScale = false }) => {
  const iconComponent = useMemo(() => {
    // Default icons
    if (icon.type === "URL") {
      const pinIcon = fromPinIconUrl(icon.value);
      const pinColor: Record<Exclude<IconColor, "default">, string> = {
        red: "text-red-500",
        orange: "text-orange-500",
        green: "text-green-500",
        blue: "text-blue-500",
        purple: "text-purple-500",
      };
      return <Icon type="solid" name={pinIcon.icon} className={pinColor[pinIcon.color]} />;
    }
    // Custom icons
    if (icon.type === "RESOURCE") {
      return <ResourceImage resourceId={icon.value} />;
    }
    // Fallback
    return <Icon type="solid" name="CheckIcon" className="text-green-500" />;
  }, [icon]);

  return (
    <div
      className="pointer-events-none absolute"
      style={{
        transform: `translate(-50%, -50%)`,
        left: `${location.x}px`,
        top: `${location.y}px`,
      }}
    >
      {keepScale ? (
        <KeepScale>
          <div className="relative">
            <div className="flex size-8 items-center justify-center rounded-full bg-white p-1 text-green-500">
              {iconComponent}
            </div>
            <Badge value={number} className="absolute -left-2 -top-2 border border-white" />
          </div>
        </KeepScale>
      ) : (
        <div className="relative">
          <div className="flex size-8 items-center justify-center rounded-full bg-white p-1 text-green-500">
            {iconComponent}
          </div>
          <Badge value={number} className="absolute -left-2 -top-2 border border-white" />
        </div>
      )}
    </div>
  );
};

export default Pin;
