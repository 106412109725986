import { useContext } from "react";
import { ToastsContext } from "../context/ToastsContext";
import { ToastProps } from "../storybook/components/Toast/Toast";

export type UseToastsResult = {
  toast?: ToastProps;
  showToast: (options: ToastProps) => void;
  hideToast: () => void;
};

const useToasts = (): UseToastsResult => {
  const { toast, setToast } = useContext(ToastsContext);
  return {
    toast,
    showToast: (options: ToastProps): void => {
      setToast(options);
    },
    hideToast: (): void => {
      setToast(undefined);
    },
  };
};

export default useToasts;
