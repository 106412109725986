import { FC } from "react";
import classNames from "classnames";
import { containsSizeOverride } from "../../utils/classHelper";

export interface SpinnerProps {
  color?: "default" | "current";
  className?: string;
}

export const Spinner: FC<SpinnerProps> = ({ color = "default", className }) => {
  const classes = classNames(
    "animate-spin",
    !containsSizeOverride(className) && "size-8",
    color === "default" ? "text-gray-500" : "text-current",
    className,
  );

  return (
    <div className={classes}>
      <svg viewBox="0 0 50 50" fill="none">
        <rect y="2" width="50" height="50" rx="25" />
        <path d="M48 26C48 12.7452 37.2548 2 24 2" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
      </svg>
    </div>
  );
};
