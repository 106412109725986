import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Browser } from "@capacitor/browser";
import { Link } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import Content from "../../components/Content";
import useCustomer from "../../hooks/useCustomer";
import InlineLoader from "../../components/InlineLoader";
import useAuth from "../../hooks/useAuth";
import useAppVersions from "../../hooks/useAppVersions";
import { CURRENT_COMMIT, openAppListingInStore } from "../../utils/versionUtil";
import useDeviceInfo from "../../hooks/useDeviceInfo";
import useApiPermission from "../../hooks/useApiPermission";
import { PROD_ENV } from "../../constants";
import Page from "../../components/Page";
import useDeviceOrientation from "../../hooks/useDeviceOrientation";
import useCurrentAppVersion from "../../hooks/useCurrentAppVersion";
import { Text } from "../../storybook/components/Text/Text";
import { TextButton } from "../../storybook/components/TextButton/TextButton";
import { Header } from "../../storybook/components/Header/Header";
import { Title } from "../../storybook/components/Title/Title";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";
import branding from "../../utils/brandingUtil";
import ReplicationResetModal from "../../components/ReplicationResetModal";
import useOnlineStatus from "../../hooks/useOnlineStatus";
import useDevMode from "../../hooks/useDevMode";

const SupportPage: FC = () => {
  const [count, setCount] = useState(0);
  const [devMode, setDevMode] = useDevMode();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { customerId, impersonatedUser, username } = useAuth();
  const customer = useCustomer(customerId);
  const { appVersions, isOutdated } = useAppVersions();
  const { currentVersion } = useCurrentAppVersion();
  const deviceInfo = useDeviceInfo();
  const { isSuperAdmin } = useApiPermission();
  const { landscapeIndent } = useDeviceOrientation(true);
  const [isReset, setReset] = useState(false);
  const { isOnline } = useOnlineStatus();

  useEffect(() => {
    if ((count > 5 && !devMode) || impersonatedUser !== undefined) {
      setDevMode(true);
    }
  }, [count]); // eslint-disable-line react-hooks/exhaustive-deps

  const isNative = Capacitor.isNativePlatform();
  const versionInformation = (): JSX.Element | null => {
    if (appVersions.error) {
      return (
        <Text size="sm" color="danger">
          {t("APP_VERSION_LOAD_FAILED")}
        </Text>
      );
    }

    if (appVersions.isSuccess && appVersions.data) {
      return (
        <Text size="sm">
          {appVersions.data?.latest && isOutdated ? (
            <TextButton
              className="ml-auto"
              label={t("APP_VERSION_UPDATE_AVAILABLE")}
              onClick={async () => {
                await openAppListingInStore();
              }}
            />
          ) : (
            t("APP_VERSION_UP_TO_DATE")
          )}
        </Text>
      );
    }

    // Still loading, don't display anything
    return null;
  };

  const isDevPageEnabled = devMode && (isSuperAdmin || !PROD_ENV);
  return (
    <Page className="absolute inset-0 lg:static">
      <Header
        className="mt-safe"
        title={t("SETTINGS_SUPPORT")}
        backBtn={{ ariaLabel: t("BACK"), onClick: () => navigate("/settings") }}
      />
      <Content className={landscapeIndent}>
        {!branding.isBranded() && (
          <div className="mb-11 mt-4">
            <Title as="h2" size="lg" className="my-1 block">
              {t("SETTINGS_SUPPORT_DOCUMENTATION_TITLE")}
            </Title>
            <Text className="my-2">{t("SETTINGS_SUPPORT_DOCUMENTATION_DESCRIPTION")}</Text>
            <IconAndTextButton
              label={t("SETTINGS_SUPPORT_DOCUMENTATION_ACTION_LABEL")}
              block
              icon="InformationCircleIcon"
              onClick={async () => {
                await Browser.open({
                  url: `https://links.moreapp.com/help-center`,
                });
              }}
            />
          </div>
        )}

        {!branding.isBranded() && (
          <div className="mb-11 mt-4">
            <Title as="h2" size="lg" className="my-1 block">
              {t("SETTINGS_SUPPORT_HELP_CENTER_TITLE")}
            </Title>
            <Text className="my-2">{t("SETTINGS_SUPPORT_HELP_CENTER_DESCRIPTION")}</Text>
            <IconAndTextButton
              label={t("SETTINGS_SUPPORT_HELP_CENTER_ACTION_LABEL")}
              block
              icon="ChatIcon"
              onClick={async () => {
                await Browser.open({
                  url: `https://links.moreapp.com/contact-support`,
                });
              }}
            />
          </div>
        )}

        <div className="mb-11 mt-4">
          <Title as="h2" size="lg" className="my-1 block">
            {t("SETTINGS_ACCOUNT_INFORMATION_TITLE")}
          </Title>
          <Text className="my-2">{t("SETTINGS_ACCOUNT_INFORMATION_DESCRIPTION")}</Text>
          <dl>
            {customerId && (
              <>
                <div className="flex justify-between border-b py-3">
                  <dt className="flex-1">
                    <Text color="medium">{t("CUSTOMER_NAME")}</Text>
                  </dt>
                  <dd className="flex-1 text-right">
                    <Text>
                      <InlineLoader obj={customer} field="name" />
                    </Text>
                  </dd>
                </div>
                <div className="flex justify-between border-b py-3">
                  <dt className="flex-1">
                    <Text color="medium">{t("CUSTOMER_ID")}</Text>
                  </dt>
                  <dd className="flex-1 text-right">
                    <Text>
                      <InlineLoader obj={customer} field="customerId" />
                    </Text>
                  </dd>
                </div>
              </>
            )}
            <div className="flex justify-between border-b py-3">
              <dt className="flex-1">
                <Text color="medium">{t("USERNAME")}</Text>
              </dt>
              <dd className="flex-1 text-right">
                <Text>{username}</Text>
              </dd>
            </div>
          </dl>
        </div>

        <div className="mb-11 mt-4">
          <Title as="h2" size="lg" className="my-1 block">
            {t("SETTINGS_APP_INFORMATION_TITLE")}
          </Title>
          <Text className="my-2">{t("SETTINGS_APP_INFORMATION_DESCRIPTION")}</Text>
          <dl>
            {isNative && currentVersion && (
              <div className="flex justify-between border-b py-3">
                <dt className="flex-1">
                  <Text color="medium">{t("APP_VERSION")}</Text>
                </dt>
                <dd className="flex-1 text-right">
                  <Text>{currentVersion.format()}</Text>
                  {versionInformation()}
                </dd>
              </div>
            )}

            {isNative && (
              <div className="flex justify-between border-b py-3">
                <dt className="flex-1">
                  <Text color="medium">{t("SETTINGS_APP_INFORMATION_DEVICE")}</Text>
                </dt>
                <dd className="flex-1 text-right">
                  <Text>
                    {deviceInfo.manufacturer} {deviceInfo.model}
                  </Text>
                </dd>
              </div>
            )}

            <div className="flex justify-between border-b py-3">
              <dt className="flex-1">
                <Text color="medium">{t("SETTINGS_APP_INFORMATION_OPERATING_SYSTEM")}</Text>
              </dt>
              <dd className="flex-1 text-right">
                <Text>
                  {deviceInfo.operatingSystem} {deviceInfo.osVersion}
                </Text>
              </dd>
            </div>

            <div className="flex justify-between border-b py-3">
              <dt className="flex-1">
                <Text color="medium">{t("SETTINGS_APP_INFORMATION_BROWSER")}</Text>
              </dt>
              <dd className="flex-1 text-right">
                <Text>
                  {deviceInfo.browserBrand} {deviceInfo.browserVersion}
                </Text>
              </dd>
            </div>

            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div className="flex justify-between border-b py-3" onClick={() => setCount(count + 1)}>
              <dt className="flex-1">
                <Text color="medium">{t("APP_BUILD")}</Text>
              </dt>
              <dd className="flex-1 text-right">
                <Text>{CURRENT_COMMIT}</Text>
              </dd>
            </div>

            {isDevPageEnabled && (
              <div className="flex justify-between border-b py-3">
                <dt className="flex-1">
                  <Text color="medium">{t("DEVELOPER_OPTIONS")}</Text>
                </dt>
                <dd className="flex-1 text-right">
                  <Link to="/developer">
                    <Text color="danger">{t("OPEN")}</Text>
                  </Link>
                </dd>
              </div>
            )}
          </dl>
          <div className="mb-11 mt-4">
            <Title as="h2" size="lg" className="my-1 block">
              {t("SETTINGS_SUPPORT_RESET_TITLE")}
            </Title>
            <Text className="my-2">{t("SETTINGS_SUPPORT_RESET_DESCRIPTION")}</Text>
            <IconAndTextButton
              label={t("SETTINGS_SUPPORT_RESET_ACTION_LABEL")}
              block
              variant="destructive"
              icon="ShieldExclamationIcon"
              disabled={!isOnline}
              onClick={() => {
                setReset(true);
              }}
            />
          </div>
        </div>
      </Content>
      <ReplicationResetModal show={isReset} onClose={() => setReset(false)} />
    </Page>
  );
};

export default SupportPage;
