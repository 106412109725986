import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { IMAGE_OPT_URL, LOCATION_BASE_URL, PROD_ENV } from "../constants";

const CSP: FC = () => {
  const csp = {
    "default-src": ["'self'"],
    "connect-src": [
      "data:",
      "capacitor:", // Pin-widget
      "blob:", // File and video widget
      PROD_ENV ? "https://*.moreapp.com" : "https://*.moreapp.dev",
      "https://*.moreapp.cloud",
      `wss://${process.env.VITE_HASURA_ENDPOINT}`,
      `https://${process.env.VITE_HASURA_ENDPOINT}`,
      "*.ingest.sentry.io",
      "https://api.postcodeapi.nu",
      "https://ip2c.org",
      "http://localhost:*", // capacitor-blob-writer
      ...(!PROD_ENV ? ["ws://localhost:3000"] : []), // Local Vite DEV server
    ],
    "style-src": ["'self'", "'unsafe-inline'"],
    "img-src": [
      "'self'",
      "data:",
      "capacitor:", // Pre-loaded Customer Resources
      "blob:", // Img widget
      process.env.VITE_MOREAPP_API_BASE_URL,
      IMAGE_OPT_URL,
      "https://cdn.moreapp.com",
      LOCATION_BASE_URL,
      "https://maps.geoapify.com",
      "https://tiles.stadiamaps.com",
    ],
    "font-src": ["data:"],
    "frame-src": ["youtube.com", "www.youtube.com", "https://youtube.com", "https://www.youtube.com"],
  };

  const stringified = Object.entries(csp)
    .map(([k, v]) => `${k} ${v.join(" ")}`)
    .join("; ");

  return (
    <Helmet>
      <meta httpEquiv="Content-Security-Policy" content={stringified} />
    </Helmet>
  );
};

export default CSP;
