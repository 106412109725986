import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FieldError, MoreAppError } from "../context/MoreAppContext";
import logger from "../utils/logger";
import { Message } from "../storybook/components/Message/Message";

type FormSubmitErrorMessageProps = {
  title: string;
  moreAppError: MoreAppError;
};

const FormSubmitErrorMessage: FC<FormSubmitErrorMessageProps> = ({ moreAppError, title }) => {
  const { t } = useTranslation();
  useEffect(() => {
    logger.debug(`Submission error`, moreAppError);
  }, [moreAppError]);

  const containsPatchErrors = moreAppError.data?.fieldErrors?.some((fieldError: FieldError) =>
    fieldError.path.includes("filterableJsonPatch"),
  );

  return (
    <Message type="error" text={moreAppError.code ? t(moreAppError.code) : title}>
      {moreAppError.data?.globalErrors && (
        <ul className="ml-4 list-disc">
          {moreAppError.data.globalErrors.map((constraintViolation) => (
            <li>{t(constraintViolation.code)}</li>
          ))}
        </ul>
      )}
      {containsPatchErrors && t("SOMETHING_WENT_WRONG")}
    </Message>
  );
};

export default FormSubmitErrorMessage;
