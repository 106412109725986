import { gzipSync, gunzipSync } from "fflate";
import { Buffer } from "buffer";

type DecompressedData<T> = {
  dataType: string;
  data: T;
};

/**
 * @description
 * Compress the data to a base64 string. This is currently only used with the drawing widget
 * @param input the data to compress
 * @output the compressed data as a string in base64 by FFlatte
 * @constructor
 */
export const compress = <T>(input: T): string => {
  const rawData: DecompressedData<T> = {
    dataType: typeof input,
    data: input,
  };

  const jsonString = JSON.stringify(rawData);
  const dataArray = new TextEncoder().encode(jsonString);
  const compressedBytes = gzipSync(dataArray, { level: 6 });
  return Buffer.from(compressedBytes).toString("base64");
};

/**
 * @description
 * Decompress the data. If the input is not a string, it will return the input.
 * This is currently only used and optimized with the drawing widget.
 * @param input the compressed data as string, in base64 by FFlatte
 * @output The decompressed data
 */
export const decompress = <T>(input: string): T => {
  // At run time we want to check if the input is a string
  if (typeof input !== "string") {
    return input;
  }

  const compressedBytes = Buffer.from(input, "base64");
  const decompressedBytes = gunzipSync(compressedBytes);
  const jsonString = new TextDecoder().decode(decompressedBytes);
  const dataObject: DecompressedData<T> = JSON.parse(jsonString);

  return dataObject.data;
};
