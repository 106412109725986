import { createElement, FC, useState } from "react";
import classNames from "classnames";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { isNil } from "lodash-es";
import useFileHandler from "../hooks/useFileHandler";
import { FileResult } from "../types/Widget";
import { useAsyncEffect } from "../hooks/useAsyncEffect";
import { noopAsync } from "../utils/noop";
import useStateSubmissionId from "../state/useStateSubmissionId";
import { decompress } from "../utils/compressUtil";
import { getFilePath } from "../utils/fileUtil";
import useAuth from "../hooks/useAuth";
import { stat } from "../utils/fileSystemUtil";
import useActionGetFieldStates from "../state/useActionGetFieldStates";
import { UniqueFieldId } from "../types/SubmissionState";

interface TemplateContentProps {
  content: string;
  className?: string;
  allowNewLines?: boolean;
}

const TemplateContent: FC<TemplateContentProps> = ({ content, className, allowNewLines = false }) => {
  const [innerHtml, setInnerHtml] = useState("");
  const { t } = useTranslation();
  const { getFileUrl } = useFileHandler();
  const submissionId = useStateSubmissionId();
  const { username, customerId } = useAuth();
  const getFieldStates = useActionGetFieldStates();

  // replace all `FILE:` segments in content by actual <img> elements
  // this is required for rendering Photos and Signatures in Entry Descriptions
  // NOTE:  This is not used in SubmissionListPage (for Draft/Task/Sent), so we filter out the `FILE:` items there.
  //        Maybe later, we add support for images in those description lines too
  const buildThumbnail = (fileUrl: string): string =>
    renderToString(
      createElement("img", {
        className: "mx-1 inline max-h-8 rounded-md border-1 border-gray-300",
        src: fileUrl,
        alt: t("USER_SUPPLIED_IMAGE"),
      }),
    );

  useAsyncEffect(
    async () => {
      const replaceImages = async (input: string): Promise<string> => {
        // OLD: Replace FILE: segments with actual images from compressed files
        // DEV-5979 is created to remove this code once all the old submissions are updated
        const fileMatches = input.match(/FILE:H4s\S+/g);
        if (fileMatches) {
          const replacePromises = fileMatches.map(async (fileMatch) => {
            const compressedFileResult = fileMatch.replace("FILE:", "");
            const file = decompress<FileResult>(compressedFileResult);
            let fileUrl;
            try {
              // If the file has a remoteId, we don't need to check if it exists locally
              if (isNil(file.remoteId)) {
                const path = getFilePath(username!, customerId!, submissionId, file.id, file.extension);
                await stat({ path }); // This line will throw the error if the file doesn't exist locally
              }
              fileUrl = await getFileUrl(file, submissionId);
            } catch (e) {
              // If the file doesn't exist, we show placeholder
              fileUrl = "";
            }
            // eslint-disable-next-line no-param-reassign
            input = input.replace(fileMatch, buildThumbnail(fileUrl));
          });
          await Promise.all(replacePromises);
        }

        const uniqueFieldIdReferences =
          content.match(/FILE:.{36}/g)?.map((id) => id.replace("FILE:", "") as UniqueFieldId) || [];
        const fieldStates = getFieldStates(uniqueFieldIdReferences);

        // NEW: Replace FILE: segments with actual images from fieldStates
        if (fieldStates) {
          const replacePromises = fieldStates.map(async (fieldState) => {
            const file = fieldState.value.rawValue as FileResult;
            const fileUrl = await getFileUrl(file, submissionId);
            // eslint-disable-next-line no-param-reassign
            input = input.replace(`FILE:${fieldState.uniqueFieldId}`, buildThumbnail(fileUrl));
          });
          await Promise.all(replacePromises);
        }
        return input;
      };

      setInnerHtml(await replaceImages(content));
    },
    noopAsync,
    [content],
  );

  return (
    <span
      className={classNames("line-clamp-4 break-words", className, { "whitespace-pre-wrap": allowNewLines })}
      dangerouslySetInnerHTML={{ __html: innerHtml }} // eslint-disable-line react/no-danger -- We mitigated the danger by sanitizing it first
    />
  );
};
export default TemplateContent;
