import { FC } from "react";
import { Icon } from "../storybook/components/Icon/Icon";

const ImpersonationWatermark: FC = () => (
  <div className="absolute bottom-20 right-3 z-100 text-red-600 opacity-40">
    <Icon name="KeyIcon" type="outline" />
  </div>
);

export default ImpersonationWatermark;
