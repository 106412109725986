import { Camera, CameraResultType, CameraSource, ImageOptions, type Photo } from "@capacitor/camera";
import useDeviceInfo from "./useDeviceInfo";
import { Platform } from "../utils/deviceUtil";

type UseCameraResult = {
  getPhoto: (source?: CameraSource, options?: Partial<ImageOptions>) => Promise<Photo>;
};

const useCamera = (): UseCameraResult => {
  const { platform } = useDeviceInfo();

  return {
    getPhoto: async (source = CameraSource.Camera, options: Partial<ImageOptions> = {}): Promise<Photo> => {
      if (await isDeniedSource(source, platform)) {
        throw new InsufficientPermissionError();
      }

      return Camera.getPhoto({
        source,
        quality: 90,
        correctOrientation: true,
        resultType: CameraResultType.Uri,
        ...options,
      });
    },
  };
};

export class InsufficientPermissionError extends Error {}

const isDeniedSource = async (source: CameraSource, platform: Platform): Promise<boolean> => {
  // Some browsers (*kuch* Firefox) do not support the "camera" permission, so Capacitor throws
  // an error when we try to check it. Since Capacitor has a hardcoded return value
  // of "granted" for the 'photos' permission when the platform is "web", we just
  // skip the permission check and get the same behavior as other browsers.
  // See https://github.com/ionic-team/capacitor-plugins/blob/main/camera/src/web.ts#L259
  if (platform === "web" && source === CameraSource.Photos) {
    return false;
  }

  const permissionStatus = await Camera.checkPermissions();
  return (
    (source === CameraSource.Camera && permissionStatus.camera === "denied") ||
    (source === CameraSource.Photos && permissionStatus.photos === "denied")
  );
};

export default useCamera;
