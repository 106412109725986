import { useTranslation } from "react-i18next";
import Img from "./Img";

const MissingImage = (): JSX.Element => {
  const { t } = useTranslation();

  return <Img className="mx-auto h-20" src="/assets/image_error.svg" alt={t("IMAGE_NOT_PROVIDED_ALT")} />;
};

export default MissingImage;
