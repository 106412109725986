import { useContext, useEffect } from "react";
import { WorkerContext } from "../context/WorkerContext";

const useWorker = (key: string, createWorker: () => Worker): Worker | undefined => {
  const { getWorker, setWorker } = useContext(WorkerContext);

  useEffect(() => setWorker(key, createWorker), [createWorker, key, setWorker]);

  return getWorker(key);
};

export default useWorker;
