import type { FC, MouseEventHandler, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Img from "../../Img";
import { getDatasourceImg } from "../../../utils/datasourceUtil";
import { Title } from "../../../storybook/components/Title/Title";
import { Text } from "../../../storybook/components/Text/Text";
import { IconButton } from "../../../storybook/components/IconButton/IconButton";

type CatalogueListItemProps = {
  name: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onClickInfo?: MouseEventHandler<HTMLButtonElement>;
  description: string;
  thumbnail?: string;
  priceExVat?: string;
  showPrices?: boolean;
};

const CatalogueListItem: FC<PropsWithChildren<CatalogueListItemProps>> = ({
  name,
  description,
  thumbnail,
  priceExVat,
  onClick,
  onClickInfo,
  showPrices = true,
  children,
}) => {
  const { t } = useTranslation();
  const classes = classNames("flex w-full flex-col items-start gap-y-3 text-left", {
    "cursor-pointer p-4 outline-none ring-inset hover:bg-gray-900/5 focus-visible:ring pointer:active:!bg-gray-900/10":
      onClick,
    "mt-1 rounded border bg-gray-50 p-3": !onClick,
  });

  if (onClick) {
    return (
      <div className="relative">
        <button
          aria-label={t("CATALOGUE_SELECT_ITEM", { item: name })}
          onClick={onClick}
          className={`group ${classes}`}
        >
          <ListItemContent
            name={name}
            thumbnail={thumbnail}
            description={description}
            showPrices={showPrices}
            priceExVat={priceExVat}
          >
            {children}
          </ListItemContent>
        </button>
        {onClickInfo && <InfoButton ariaLabel={t("CATALOGUE_ITEM_VIEW_INFO")} onClick={onClickInfo} />}
      </div>
    );
  }

  return (
    <div className="relative">
      <div className={classes}>
        <ListItemContent
          name={name}
          thumbnail={thumbnail}
          description={description}
          showPrices={showPrices}
          priceExVat={priceExVat}
        >
          {children}
        </ListItemContent>
      </div>
      {onClickInfo && <InfoButton ariaLabel={t("CATALOGUE_ITEM_VIEW_INFO")} onClick={onClickInfo} />}
    </div>
  );
};

type InfoButtonProps = {
  ariaLabel: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
};
const InfoButton: FC<InfoButtonProps> = ({ ariaLabel, onClick }) => (
  <div className="absolute right-0 top-0 mr-4 mt-4 shrink-0">
    <IconButton
      aria-label={ariaLabel}
      icon="InformationCircleIcon"
      iconType="outline"
      variant="transparentBrand"
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
    />
  </div>
);

type ListItemContentProps = Pick<
  CatalogueListItemProps,
  "name" | "thumbnail" | "description" | "showPrices" | "priceExVat"
>;

const ListItemContent: FC<PropsWithChildren<ListItemContentProps>> = ({
  name,
  thumbnail,
  description,
  showPrices,
  priceExVat,
  children,
}) => (
  <>
    <div className="flex max-w-full grow gap-x-2 pr-12">
      <Img
        alt={`${name} thumbnail`}
        src={getDatasourceImg(thumbnail)}
        fallbackSrc="/assets/image-empty.svg"
        fallbackClassName="bg-gray-100 p-7"
        className="size-20 shrink-0 overflow-hidden rounded border-1.5 object-cover"
      />
      <div className="min-w-0 space-y-2">
        <Title as="h2" size="sm" className="line-clamp-2">
          {name}
        </Title>
        <Text size="xs" weight="medium" color="dark-light" className="line-clamp-3 group-active:text-gray-700">
          {description}
        </Text>
        {showPrices && priceExVat && (
          <Text size="sm" weight="semibold" className="truncate">
            {priceExVat}
          </Text>
        )}
      </div>
    </div>
    {children && <div className="w-full 2xs:pl-22">{children}</div>}
  </>
);

export default CatalogueListItem;
