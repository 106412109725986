import i18n, { ParseKeys } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { compact } from "lodash-es";
import translationsDefault from "./translations/translation.json";
import translationsEN from "./translations/en/translation.json";
import translationsNL from "./translations/nl/translation.json";
import translationsES from "./translations/es/translation.json";
import translationsDE from "./translations/de/translation.json";
import translationsPT from "./translations/pt/translation.json";
import translationsFR from "./translations/fr/translation.json";
import translationsCA from "./translations/ca/translation.json";
import { LanguageCode, languageToISO } from "./utils/languageUtil";

const FALLBACK_LANGUAGE = "dev";

export type TranslationKeys = ParseKeys<keyof (typeof resources)[typeof FALLBACK_LANGUAGE]>;
export const defaultNS = "translation";
export const resources = {
  [FALLBACK_LANGUAGE]: { translation: translationsDefault },
  [LanguageCode.EN]: { translation: translationsEN },
  [LanguageCode.NL]: { translation: translationsNL },
  [LanguageCode.ES]: { translation: translationsES },
  [LanguageCode.DE]: { translation: translationsDE },
  [LanguageCode.PT]: { translation: translationsPT },
  [LanguageCode.FR]: { translation: translationsFR },
  [LanguageCode.CA]: { translation: translationsCA },
};

i18n.on("languageChanged", (languageCode) => {
  document.documentElement.setAttribute("lang", languageToISO(languageCode));
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    returnEmptyString: false,
    fallbackLng: (code) => {
      // conversion needed to handle compatibility with capitalised language code, which we used previously
      const languageParts = code.split("-");
      const parsedCode = compact([languageParts[0].toLowerCase(), languageParts[1]]).join("-");

      if (parsedCode.startsWith(LanguageCode.CA)) {
        return [parsedCode, LanguageCode.ES, LanguageCode.EN, FALLBACK_LANGUAGE];
      }

      return [parsedCode, LanguageCode.EN, FALLBACK_LANGUAGE];
    },
    debug: false,
    defaultNS,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
