import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Camera } from "@capacitor/camera";
import { isNil } from "lodash-es";
import { CLEARABLE_UPLOAD_STATUSES, UploadStatus, WidgetResult } from "../types/Field";
import useFileWidget, { PersistWithUploadStatusFn } from "../hooks/useFileWidget";
import { FileResult } from "../types/Widget";
import useDeviceInfo from "../hooks/useDeviceInfo";
import { DropdownMenu, MenuItem } from "../storybook/components/DropdownMenu/DropdownMenu";
import { IconAndTextButton } from "../storybook/components/IconAndTextButton/IconAndTextButton";
import { WidgetContentButton } from "../storybook/components/WidgetContentButton/WidgetContentButton";
import { Spinner } from "../storybook/components/Spinner/Spinner";
import { Icon } from "../storybook/components/Icon/Icon";
import { Label } from "../storybook/components/Label/Label";
import { fileTypes } from "../utils/fileUtil";
import { FieldState } from "../types/SubmissionState";
import { WidgetProperties } from "../types/FormVersion";
import FieldUploadStatus from "./FieldUploadStatus";

type FileUploadButtonProps = {
  allowedMimeType: string;
  isDisabled: boolean;
  readOnly: boolean;
  labelText: string;
  required: boolean;
  persistWithUploadStatus: PersistWithUploadStatusFn<FileResult>;
  fieldState: FieldState<WidgetProperties, WidgetResult<FileResult>>;
  showPermissionsModal?: () => void;
};

const FileUploadButton: FC<FileUploadButtonProps> = ({
  persistWithUploadStatus,
  isDisabled,
  readOnly,
  labelText,
  required,
  fieldState,
  allowedMimeType,
  showPermissionsModal = (): void => {},
}) => {
  const { uploadStatus } = fieldState.value.meta;
  const allowedMimeTypeArray: string[] | undefined = fileTypes[allowedMimeType];

  const { t } = useTranslation();
  const {
    inputFile,
    isAttemptingOrUploaded,
    uploadPercentage,
    cancelUpload,
    storeFileOnChange,
    retryUpload,
    removeFile,
  } = useFileWidget(persistWithUploadStatus, fieldState, allowedMimeTypeArray);

  const { platform, operatingSystem } = useDeviceInfo();
  // Operating system and platform dependent, we want to allow android users to select or create a video
  const isAndroid = platform === "android" || operatingSystem === "android";

  const getButtonLabel = (
    type: string,
  ): "ATTACH_FILE_IMAGE" | "ATTACH_FILE_VIDEO" | "ATTACH_FILE_AUDIO" | "ATTACH_FILE_DOCUMENT" | "ATTACH_FILE" => {
    switch (type) {
      case "images":
        return "ATTACH_FILE_IMAGE";
      case "video":
        return "ATTACH_FILE_VIDEO";
      case "audio":
        return "ATTACH_FILE_AUDIO";
      case "documents":
        return "ATTACH_FILE_DOCUMENT";
      default:
        return "ATTACH_FILE";
    }
  };

  const getCaptureLabel = (type: string): "CAPTURE_PHOTO" | "CAPTURE_VIDEO" | undefined => {
    switch (type) {
      case "images":
        return "CAPTURE_PHOTO";
      case "video":
        return "CAPTURE_VIDEO";
      default:
        return undefined;
    }
  };

  const getBrowseLabel = (
    type: string,
  ): "BROWSE_PHOTOS" | "BROWSE_VIDEOS" | "BROWSE_AUDIOS" | "BROWSE_DOCUMENTS" | "BROWSE_FILES" => {
    switch (type) {
      case "images":
        return "BROWSE_PHOTOS";
      case "video":
        return "BROWSE_VIDEOS";
      case "audio":
        return "BROWSE_AUDIOS";
      case "documents":
        return "BROWSE_DOCUMENTS";
      default:
        return "BROWSE_FILES";
    }
  };

  const getIconType = (
    type: string,
    status?: UploadStatus,
  ): "PaperClipIcon" | "RefreshIcon" | "CameraIcon" | "VideoCameraIcon" | "MusicNoteIcon" | "DocumentIcon" => {
    if (status === "failed") {
      return "RefreshIcon";
    }

    switch (type) {
      case "images":
        return "CameraIcon";
      case "video":
        return "VideoCameraIcon";
      case "audio":
        return "MusicNoteIcon";
      case "documents":
        return "DocumentIcon";
      default:
        return "PaperClipIcon";
    }
  };

  const getUploadButtonDropdownItems = (): { label: string; onClick: () => void }[] => {
    const items = [
      {
        label: t(getBrowseLabel(allowedMimeType)),
        onClick: (): void => {
          inputFile.current?.removeAttribute("capture");
          inputFile.current?.click();
        },
      },
    ];

    const captureLabel = getCaptureLabel(allowedMimeType);
    if (captureLabel) {
      items.unshift({
        label: t(captureLabel),
        onClick: async () => {
          const permissionResult = await Camera.requestPermissions();

          if (permissionResult.camera !== "granted" || permissionResult.photos !== "granted") {
            showPermissionsModal();
            return;
          }

          const capture = document.createAttribute("capture");
          capture.value = "environment";
          inputFile.current?.setAttributeNode(capture);
          inputFile.current?.click();
        },
      });
    }

    return items;
  };

  const uploadButton = isAndroid ? (
    <DropdownMenu
      className="w-full"
      menuButton={() => (
        <IconAndTextButton
          block
          disabled={isDisabled}
          icon="PaperClipIcon"
          label={!isDisabled ? t(getButtonLabel(allowedMimeType)) : t("NO_FILE_ATTACHED")}
        />
      )}
      items={getUploadButtonDropdownItems()}
    />
  ) : (
    <IconAndTextButton
      block
      disabled={isDisabled}
      icon="PaperClipIcon"
      label={!isDisabled ? t(getButtonLabel(allowedMimeType)) : t("NO_FILE_ATTACHED")}
      onClick={() => inputFile.current?.click()}
    />
  );

  const resultButton = (
    <div className="relative">
      <FieldUploadStatus percentage={uploadPercentage} status={uploadStatus} />
      <WidgetContentButton
        items={[
          ...(uploadStatus === "failed" ? [{ label: t("RETRY_UPLOAD"), onClick: retryUpload }] : []),
          ...(uploadStatus === "uploading"
            ? [{ label: t("CANCEL_UPLOAD"), type: "destructive", onClick: cancelUpload } as MenuItem]
            : []),
        ]}
        singleLine
        disabled={isDisabled}
      >
        <span className="relative inline-flex items-center space-x-3">
          {uploadStatus === "uploading" ? (
            <Spinner className="size-4" />
          ) : (
            <Icon name={getIconType(allowedMimeType, uploadStatus)} className="size-4 shrink-0" />
          )}

          <span>{fieldState.value.rawValue?.name}</span>
        </span>
      </WidgetContentButton>
    </div>
  );
  return (
    <>
      <Label
        htmlFor={fieldState.uniqueFieldId}
        label={labelText}
        required={required}
        showClearBtn={uploadStatus && CLEARABLE_UPLOAD_STATUSES.includes(uploadStatus) && !readOnly}
        onClear={removeFile}
        clearLabel={t("CLEAR")}
      />
      {isAttemptingOrUploaded ? resultButton : uploadButton}
      <input
        disabled={isDisabled}
        className="hidden"
        type="file"
        accept={allowedMimeTypeArray ? allowedMimeTypeArray.toString() : undefined}
        ref={inputFile}
        onChange={async (e) => {
          const result = await storeFileOnChange(e);
          if (!isNil(result)) {
            persistWithUploadStatus(result.fileResult, result.uploadStatus);
          }
        }}
      />
    </>
  );
};

export default FileUploadButton;
