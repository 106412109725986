import { useTranslation } from "react-i18next";
import { to24hTime, toIsoDate } from "../../utils/dateUtil";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import { DateTimeInput, DateTimeValue } from "../../storybook/components/DateTimeInput/DateTimeInput";
import WidgetContainer from "../WidgetContainer";

export interface WidgetDateTimeProperties {
  required: boolean;
  label_text: string;
  now_as_default: boolean;
}

const WidgetDateTime: Widget<WidgetDateTimeProperties, WidgetResult<DateTimeValue>> = ({
  fieldState,
  setFieldState,
  readOnly,
}) => {
  const { t } = useTranslation();

  return (
    <WidgetContainer fieldState={fieldState} name="DATETIME_FIELD">
      <DateTimeInput
        type="datetime"
        name={fieldState.uid}
        label={fieldState.properties.label_text}
        value={fieldState.value.rawValue}
        errorMessage={fieldState.error}
        disabled={readOnly}
        onClear={() => setFieldState(undefined)}
        onChange={(value) => {
          const now = new Date();
          let prefilledValue = value;
          if (!value.date) {
            prefilledValue = { ...value, date: toIsoDate(now) };
          }
          if (!value.time) {
            prefilledValue = { ...value, time: to24hTime(now) };
          }
          setFieldState(prefilledValue);
        }}
        required={fieldState.properties.required}
        showClearBtn={!!fieldState.value.rawValue && !readOnly}
        clearLabel={t("CLEAR")}
      />
    </WidgetContainer>
  );
};

WidgetDateTime.defaultValue = (properties, defaultMeta: any): WidgetResult<DateTimeValue> => {
  const now = new Date();
  return {
    type: "datetime",
    rawValue: properties.now_as_default ? { date: toIsoDate(now), time: to24hTime(now) } : undefined,
    meta: {
      widget: "datetime",
      ...defaultMeta,
    },
  };
};

WidgetDateTime.validate = (val, properties, t): string | undefined => {
  const { required } = properties;

  if ((!val?.date || !val?.time) && !(!val?.date && !val?.time)) {
    return t("VALIDATION_DATETIME_BOTH_REQUIRED");
  }

  if (required && !val?.date && !val?.time) {
    return t("VALIDATION_REQUIRED");
  }

  return undefined;
};

export default WidgetDateTime;
