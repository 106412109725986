import { Trans, useTranslation } from "react-i18next";
import { Text } from "../../storybook/components/Text/Text";
import CommonErrorPage from "./CommonErrorPage";

const PreviewErrorPage = (): JSX.Element => {
  const { t } = useTranslation();

  const errorContent = (
    <>
      <img className="mx-auto pb-8" src="/assets/errorpages/expired.svg" alt={t("EXPIRED_ALT")} />
      <Text className="pb-5" weight="semibold">
        {t("PREVIEW_CLOSED_TITLE")}
      </Text>
      <Text className="pb-6">
        {/* Needed instead of useTranslation in order to use breaklines  */}
        <Trans i18nKey="PREVIEW_CLOSED_DESCRIPTION" />
      </Text>
    </>
  );

  return <CommonErrorPage errorContent={errorContent} showFaqBtn={false} showHomeBtn={false} showSupportBtn={false} />;
};

export default PreviewErrorPage;
