import useAuth from "./useAuth";

const useUserId = (): string | undefined => {
  const {
    authorization: { userId },
  } = useAuth();

  return userId;
};

export default useUserId;
