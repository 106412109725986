import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Message } from "../../storybook/components/Message/Message";

const WidgetError: FC<{ widgetId: string }> = ({ widgetId }) => {
  const { t } = useTranslation();

  return (
    <Message
      className="mt-4"
      type="warning"
      title={t("WIDGET_UNAVAILABLE_TITLE", { name: widgetId })}
      text={t("WIDGET_UNAVAILABLE_DESCRIPTION")}
    />
  );
};

export default WidgetError;
