import { StatusBar, Style } from "@capacitor/status-bar";
import useDeviceInfo from "./useDeviceInfo";
import logger from "../utils/logger";
import { useAsyncEffect } from "./useAsyncEffect";
import { noopAsync } from "../utils/noop";
import { HexColor } from "../storybook/colors";

const DEFAULT_BACKGROUND_COLOR: HexColor = "#FFFFFF";

const useStatusBar = (color?: HexColor): void => {
  const { platform } = useDeviceInfo();

  useAsyncEffect(
    async () => {
      try {
        if (!platform || platform === "web") {
          return;
        }

        if (platform === "android") {
          await StatusBar.setOverlaysWebView({ overlay: false });
          await StatusBar.setBackgroundColor({ color: color ?? DEFAULT_BACKGROUND_COLOR });
        }

        await StatusBar.setStyle({ style: color ? Style.Dark : Style.Light });
      } catch (e) {
        logger.error("Error when setting status bar theme color", e);
      }
    },
    noopAsync,
    [color, platform],
  );
};

export default useStatusBar;
