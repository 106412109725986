import { FC } from "react";
import { RxDocument } from "rxdb";
import { isNil } from "lodash-es";
import useFormVersion from "../hooks/useFormVersion";
import { Submission } from "../types/Submission";
import SubmissionNotFoundPage from "./errorpages/SubmissionNotFoundPage";
import OfflinePage from "./errorpages/OfflinePage";
import useOnlineStatus from "../hooks/useOnlineStatus";
import LoadingPage from "./LoadingPage";
import useTheme from "../hooks/useTheme";
import SubmissionForm from "../components/SubmissionForm";
import useStatusBar from "../hooks/useStatusBar";
import { useSubmissionPageTitle } from "../hooks/useSubmissionPageTitle";
import useInitialFormState from "../hooks/useInitialFormState";
import useSubmissionCollection from "../hooks/useSubmissionCollection";
import useFieldCollection from "../hooks/useFieldCollection";
import useRememberedFieldCollection from "../hooks/useRememberedFieldCollection";
import useShowToastAndGoHomeOnInvalidFormVersion from "../hooks/useShowToastAndGoHomeOnInvalidFormVersion";

type SubmissionPageProps = {
  submission: RxDocument<Submission>;
};

const SubmissionPage: FC<SubmissionPageProps> = ({ submission }) => {
  const { id, formId, customerId, formVersionId } = submission;

  const { isOnline } = useOnlineStatus();
  const fieldCollection = useFieldCollection();
  const submissionCollection = useSubmissionCollection();
  const rememberedFieldCollection = useRememberedFieldCollection();
  const { formVersion, isLoadingFormVersion, errorFormVersion } = useFormVersion(formVersionId, customerId, formId);
  const formState = useInitialFormState(id, formId, formVersion, fieldCollection, rememberedFieldCollection);
  useSubmissionPageTitle(submission);
  const theme = useTheme(formVersion);
  useStatusBar(theme.color);
  useShowToastAndGoHomeOnInvalidFormVersion(errorFormVersion);

  if (
    isLoadingFormVersion ||
    isNil(fieldCollection) ||
    isNil(submissionCollection) ||
    isNil(rememberedFieldCollection) ||
    isNil(formState)
  ) {
    return (
      <LoadingPage
        conditions={[
          { labelKey: "LOADING_ERROR_DIRECT_URL_TOKEN", value: isLoadingFormVersion },
          { labelKey: "LOADING_SUBMISSION_DATABASE_FIELD", value: !fieldCollection },
          { labelKey: "LOADING_SUBMISSION_DATABASE_SUBMISSION", value: !submissionCollection },
          { labelKey: "LOADING_SUBMISSION_FORM_STATE", value: !formState },
        ]}
      />
    );
  }

  if (isNil(formVersion)) {
    return isOnline ? <SubmissionNotFoundPage /> : <OfflinePage />;
  }

  return (
    <SubmissionForm
      submission={submission}
      formVersion={formVersion}
      submissionCollection={submissionCollection}
      theme={theme}
      formState={formState}
    />
  );
};

export default SubmissionPage;
