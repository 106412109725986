import stringLength from "string-length";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import { TextInput } from "../../storybook/components/TextInput/TextInput";
import useSyncedState from "../../hooks/useSyncedState";
import WidgetContainer from "../WidgetContainer";

export interface WidgetTextareaProperties {
  required: boolean;
  label_text: string;
  min_length?: number;
  max_length?: number;
  text_placeholder?: string;
  text_default_value?: string;
}

const WidgetTextarea: Widget<WidgetTextareaProperties, WidgetResult<string>> = ({
  fieldState,
  setFieldState,
  readOnly,
}) => {
  const [localState, setLocalState] = useSyncedState(fieldState.value.rawValue);

  return (
    <WidgetContainer fieldState={fieldState} name="TEXT_AREA_FIELD">
      <TextInput
        name={fieldState.uniqueFieldId}
        value={localState}
        errorMessage={fieldState.error}
        disabled={readOnly}
        onChange={(e) => setLocalState(e.target.value)}
        onBlur={() => setFieldState(localState)}
        type="textarea"
        label={fieldState.properties.label_text}
        required={fieldState.properties.required}
        placeholder={fieldState.properties.text_placeholder}
      />
    </WidgetContainer>
  );
};

WidgetTextarea.defaultValue = (properties, defaultMeta): WidgetResult<string> => ({
  type: "string",
  rawValue: properties.text_default_value,
  meta: {
    widget: "textarea",
    ...defaultMeta,
  },
});

WidgetTextarea.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  const max = properties.max_length;
  const min = properties.min_length;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }

  if (val && min && stringLength(val) < min) {
    return t("VALIDATION_TEXT_MIN", { min });
  }

  if (val && max && stringLength(val) > max) {
    return t("VALIDATION_TEXT_MAX", { max });
  }

  return undefined;
};

export default WidgetTextarea;
