import { ChangeEvent, FC, FocusEvent, PropsWithChildren } from "react";
import classNames from "classnames";
import { Icon } from "../Icon/Icon";
import { Text } from "../Text/Text";
import { Feedback } from "../Feedback/Feedback";

export interface CheckboxProps extends PropsWithChildren {
  className?: string;
  name: string;
  selected?: boolean;
  onChange?: (e: ChangeEvent<any>) => void;
  onBlur?: (e: FocusEvent<any>) => void;
  description?: string;
  errorMessage?: string;
  disabled?: boolean;
}

export const Checkbox: FC<CheckboxProps> = ({
  selected = false,
  className,
  description = "",
  errorMessage = "",
  disabled = false,
  onChange = (): void => {},
  onBlur = (): void => {},
  children,
  name,
}) => {
  const showDescription = description && !errorMessage;

  const classes = classNames(
    "relative -mx-2 mt-1",
    !disabled && {
      "active:!bg-brand-100": true,
      "pointer:hover:bg-brand-200": selected,
      "pointer:hover:bg-brand-50": !selected,
    },
    className,
  );

  const checkboxClasses = classNames(
    "flex size-6 shrink-0 items-center justify-center rounded border-1.5",
    disabled
      ? {
          "border-gray-500 bg-gray-500 text-white": selected,
          "bg-gray-100 text-transparent": !selected,
        }
      : {
          "pointer:peer-focus-visible:ring": true,
          "bg-brand-500 text-white border-brand-500": selected,
          "bg-white text-transparent": !selected,
        },
  );

  return (
    <div className={classes}>
      <input
        id={name}
        name={name}
        type="checkbox"
        checked={selected}
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
        className="peer absolute size-0 cursor-pointer opacity-0 outline-none disabled:cursor-not-allowed"
      />
      <label
        htmlFor={name}
        className="flex cursor-pointer select-none flex-col rounded p-2 text-left text-sm peer-focus-visible:ring peer-disabled:cursor-not-allowed"
      >
        <div className="flex">
          <span className={checkboxClasses}>
            <Icon name="CheckIcon" />
          </span>
          <span className="my-auto ml-2 overflow-hidden break-words text-gray-700">{children}</span>
        </div>

        {errorMessage && <Feedback status="error" message={errorMessage} />}
        {showDescription && (
          <Text className="mt-1" size="sm" color="medium">
            {description}
          </Text>
        )}
      </label>
    </div>
  );
};
