import { FC, MouseEventHandler } from "react";
import classNames from "classnames";
import { Title } from "../Title/Title";
import { Text } from "../Text/Text";
import { Icon, IconName } from "../Icon/Icon";
import { NavIcon, NavIconProps } from "./NavIcon/NavIcon";
import { DropdownMenu, MenuItem } from "../DropdownMenu/DropdownMenu";
import { IconButton } from "../IconButton/IconButton";

export interface NavItemMeta {
  label: string;
  icon?: IconName;
}

export interface NavItemProps {
  label: string;
  ariaLabel?: string;
  description?: string;
  comment?: string;
  meta?: NavItemMeta[];
  icon?: Omit<NavIconProps, "className" | "compact" | "disabled">;
  menuItems?: MenuItem[];
  disabled?: boolean;
  onClick?: () => void;
  onBtnClick?: MouseEventHandler<HTMLButtonElement>;
  onSelect?: MouseEventHandler<HTMLDivElement>;
  selectable?: boolean;
  selected?: boolean;
  btnAriaLabel?: string;
  className?: string;
}

export const NavItem: FC<NavItemProps> = ({
  label,
  ariaLabel,
  description,
  comment,
  meta = [],
  icon,
  menuItems,
  disabled,
  onClick,
  onBtnClick,
  selectable = false,
  selected = false,
  btnAriaLabel,
  className,
}) => {
  const centerTitle = !description && !comment && !meta.length;
  const showMetaPills = meta.length > 0;
  const showAction = !disabled && !selectable && (!!menuItems || !!onBtnClick);
  const showMarker = !disabled && !showAction;

  const classes = classNames(
    "group relative flex w-full select-none justify-between text-start outline-none ring-inset",
    disabled
      ? { "cursor-not-allowed": true, "bg-gray-100": selectable || selected }
      : {
          "focus-visible:ring": true,
          "bg-brand-100 pointer:hover:bg-gray-200": selected,
          "pointer:hover:bg-gray-100": !selected,
        },
    className,
  );

  const navAction = (): JSX.Element | undefined => {
    if (menuItems && menuItems.length > 0) {
      const menuButton = (open: boolean): JSX.Element => (
        <IconButton
          aria-label={btnAriaLabel}
          variant={open ? "default" : "transparentMedium"}
          icon="DotsVerticalIcon"
          iconType="outline"
        />
      );
      return <DropdownMenu menuButton={menuButton} items={menuItems} />;
    }
    if (onBtnClick) {
      return (
        <IconButton
          aria-label={btnAriaLabel}
          variant="transparentBrand"
          icon="InformationCircleIcon"
          iconType="outline"
          onClick={onBtnClick}
        />
      );
    }
    return undefined;
  };

  const navTitle = (
    <Title as="h3" size="lg" className="line-clamp-2" color={!disabled ? "dark" : "medium"}>
      {label}
    </Title>
  );

  const navDescription = description && (
    <Text className="line-clamp-3" color={!disabled ? "medium" : "medium-light"}>
      {description}
    </Text>
  );

  const navComment = (): JSX.Element => {
    const commentClasses = classNames(
      "line-clamp-3 max-w-xl rounded-lg px-2 py-1",
      disabled
        ? "bg-gray-900/5 text-gray-900/20"
        : {
            "text-gray-600": true,
            "bg-brand-200 pointer:group-hover:bg-gray-300": selected,
            "bg-gray-100 pointer:group-hover:bg-gray-200": !selected,
          },
    );

    return (
      <Text className={commentClasses} color="medium">
        {comment}
      </Text>
    );
  };

  const navMetaPills = (): JSX.Element => {
    const metaClasses = classNames(
      "flex items-center gap-x-1 overflow-hidden rounded-xl px-2 py-0.5 text-gray-600",
      selected
        ? { "bg-brand-200 pointer:group-hover:bg-gray-300": !disabled }
        : { "bg-gray-100": true, "pointer:group-hover:bg-gray-200": !disabled },
    );

    return (
      <div className="flex flex-wrap gap-2 overflow-hidden">
        {meta.map((metaField, i) => (
          <Text color="medium" size="xs" weight="medium" className={metaClasses} key={i}>
            {metaField.icon && <Icon name={metaField.icon} className="size-4 shrink-0" />}
            <span className="truncate">{metaField.label}</span>
          </Text>
        ))}
      </div>
    );
  };

  return (
    <div className="relative">
      <button aria-label={ariaLabel} disabled={disabled} onClick={onClick} className={classes}>
        <div className={classNames("flex min-w-0 flex-1 gap-x-4 py-3 pl-4 pr-12", centerTitle && "items-center")}>
          <NavIcon {...icon} disabled={disabled} />
          <div className="flex min-w-0 flex-col gap-y-2">
            <div className="flex flex-col">
              {navTitle}
              {description && navDescription}
            </div>
            {comment && navComment()}
            {showMetaPills && navMetaPills()}
          </div>
        </div>
        {showMarker && <NavMarker center={centerTitle} selectable={selectable} selected={selected} />}
      </button>
      {showAction && <div className="absolute right-0 top-0 shrink-0">{navAction()}</div>}
    </div>
  );
};

interface NavMarkerProps {
  center?: boolean;
  selectable?: boolean;
  selected?: boolean;
}

const NavMarker: FC<NavMarkerProps> = ({ center, selectable, selected }) => {
  const classes = classNames(
    "absolute inset-y-0 right-0 m-4 flex",
    center && "items-center",
    selectable && {
      "h-6 w-6 rounded-full border-2": true,
      "text-white bg-brand-500 border-brand-500 group-active:text-transparent group-active:bg-transparent group-active:border-brand-300":
        selected,
      "text-transparent group-active:bg-brand-500 group-active:text-white group-active:border-brand-500": !selected,
    },
  );

  return (
    <div className={classes}>
      {selectable ? (
        <Icon name="CheckIcon" className="size-5" />
      ) : (
        <Icon name="ArrowRightIcon" className="mt-1 text-gray-700" />
      )}
    </div>
  );
};
