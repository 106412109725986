import { FC, PropsWithChildren, Ref } from "react";
import classNames from "classnames";

interface ContentProps {
  className?: string;
  ourRef?: Ref<HTMLDivElement>;
  padding?: boolean;
  asRows?: boolean;
}

const Content: FC<PropsWithChildren<ContentProps>> = ({
  children,
  className,
  ourRef,
  padding = true,
  asRows = false,
}) => {
  const classes = classNames(
    "h-full overflow-y-auto overflow-x-hidden bg-white outline-none ring-inset focus-visible:ring",
    {
      "pt-5 pb-4 px-4": padding,
      "flex flex-col gap-y-6": asRows,
    },
    className,
  );
  return (
    <div ref={ourRef} className={classes}>
      {children}
    </div>
  );
};
export default Content;
