import React, { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { first, isNil } from "lodash-es";
import uuidv4 from "../utils/uuid";
import { Form } from "../types/Folder";
import LoadingPage from "./LoadingPage";
import logger from "../utils/logger";
import DirectUrlForm from "../components/DirectUrlForm";
import useTheme from "../hooks/useTheme";
import useStatusBar from "../hooks/useStatusBar";
import useLocalSubmissions from "../hooks/useLocalSubmissions";
import { useSubmissionPageTitle } from "../hooks/useSubmissionPageTitle";
import useFieldCollection from "../hooks/useFieldCollection";
import useSubmissionCollection from "../hooks/useSubmissionCollection";
import useRememberedFieldCollection from "../hooks/useRememberedFieldCollection";
import { nowToISO } from "../utils/dateUtil";
import { UploadManagerProvider } from "../context/UploadManagerContext";
import usePreviewForm from "../hooks/usePreviewForm";
import useAuth from "../hooks/useAuth";
import { ErrorBoundary } from "../components/ErrorBoundary";
import PreviewErrorPage from "./errorpages/PreviewErrorPage";
import PreviewClosedPage from "./errorpages/PreviewClosedPage";

const PreviewPage: FC = () => {
  const { formVersionId } = useParams<{ formVersionId: string }>();
  const submissions = useSubmissionCollection();
  const fieldCollection = useFieldCollection();
  const rememberedFields = useRememberedFieldCollection();
  const { form, formVersion, customerId, status, hasToken } = usePreviewForm();
  const { authorizePreview } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [routeId, setRouteId] = useState<string | null>(searchParams.get("id"));
  const [initState, setInitState] = useState<"pending" | "initializing" | "initialized">("pending");

  const { result } = useLocalSubmissions((collection) => {
    const submissionId = routeId ?? ""; // Query param can't be empty
    return collection.find().where("id").equals(submissionId);
  });

  const submission = first(result);
  useSubmissionPageTitle(submission);

  const theme = useTheme(formVersion);
  useStatusBar(theme.color);

  useEffect(() => {
    if (isNil(customerId) || !hasToken) {
      return;
    }
    authorizePreview(customerId).catch((e) => {
      logger.error("Couldn't authorize preview", e);
    });
  }, [customerId, hasToken]); // eslint-disable-line react-hooks/exhaustive-deps

  const createNewSubmission = useCallback(
    async (newForm: Form) => {
      if (initState !== "pending") {
        return;
      }
      const id = uuidv4();
      await submissions?.upsert({
        id,
        customerId,
        formId: newForm.id,
        formVersionId,
        status: "draft",
        form: {
          name: newForm.meta.name,
          description: newForm.meta.description,
          icon: newForm.meta.icon,
          iconColor: newForm.meta.iconColor,
        },
        meta: {},
        createdAt: nowToISO(),
        updatedAt: nowToISO(),
        sendType: "manual",
      });
      setSearchParams((prev) => {
        const updatedSearchParams = new Map(prev);
        updatedSearchParams.set("id", id); // Replace instead of adding extra search param
        setRouteId(id);
        return [...updatedSearchParams.entries()];
      });
    },
    [customerId, formVersionId, initState, setSearchParams, submissions],
  );

  useEffect(() => {
    // Make sure proper database is loaded, could be switching auth
    const expectedDatabaseName = `moreapp-anonymous-rx15`;
    const isDatabaseInitialized = submissions?.database.name === expectedDatabaseName;
    if (!isDatabaseInitialized) {
      return;
    }
    if (initState !== "pending" || status !== "active") {
      return;
    }

    setInitState("initializing");
    rememberedFields?.find().remove();

    if (!routeId) {
      createNewSubmission(form)
        .catch((e) => logger.error("Couldn't create new submission", e))
        .finally(() => setInitState("initialized"));
    } else {
      submissions
        .findOne(routeId)
        .exec()
        .then((value) => {
          if (isNil(value)) {
            createNewSubmission(form).catch((e) => logger.error("Couldn't create new submission", e));
          }
        })
        .finally(() => setInitState("initialized"));
    }
  }, [submissions, routeId, createNewSubmission, initState, rememberedFields, form, status]);

  if (status === "pending" || isNil(submission)) {
    return (
      <LoadingPage
        conditions={[
          { labelKey: "LOADING_ERROR_PREVIEW_LOAD", value: status === "pending" },
          { labelKey: "LOADING_ERROR_SUBMISSION", value: isNil(submission) },
        ]}
        skipNavigation
      />
    );
  }

  if (status === "closed") {
    return <PreviewClosedPage />;
  }

  if (isNil(fieldCollection)) {
    return (
      <LoadingPage
        conditions={[{ labelKey: "LOADING_ERROR_DIRECT_URL_DATABASE", value: !fieldCollection }]}
        skipNavigation
      />
    );
  }

  return (
    <ErrorBoundary key="preview">
      {(hasError) =>
        hasError ? (
          <PreviewErrorPage />
        ) : (
          <UploadManagerProvider>
            <DirectUrlForm
              preview="compact"
              formVersion={formVersion}
              theme={theme}
              submission={submission}
              fieldCollection={fieldCollection}
              isLoggedIn={false}
            />
          </UploadManagerProvider>
        )
      }
    </ErrorBoundary>
  );
};
export default PreviewPage;
