import { TFunction } from "i18next";
import { isNil } from "lodash-es";
import { CurrencyResult, FileResult } from "../types/Widget";
import { WidgetPriceProperties } from "../components/widgets/WidgetPrice";
import { toFormattedCurrency } from "./stringUtil";
import { UploadStatus } from "../types/Field";

export const validateUpload = (
  val: FileResult | undefined,
  uploadStatus: UploadStatus | undefined,
  t: TFunction,
): string | undefined => {
  if (isNil(val)) {
    return undefined;
  }
  switch (uploadStatus) {
    case "error":
      return t("VALIDATION_UPLOAD_ERROR");
    case "size_exceeded":
      return t("VALIDATION_UPLOAD_SIZE_EXCEEDED");
    case "invalid_extension":
      return t("VALIDATION_UPLOAD_INVALID_EXTENSION", { extension: val.extension || "unknown" });
    default:
      return undefined;
  }
};

export const validateCurrency = (
  properties: WidgetPriceProperties,
  val: CurrencyResult | undefined,
  t: TFunction,
): string | undefined => {
  const { required } = properties;
  const min = properties.min ?? -Infinity;
  const max = properties.max ?? Infinity;

  const { value, currency } = val ?? {};

  if (required && isNil(value)) {
    return t("VALIDATION_REQUIRED");
  }

  if (!isNil(value)) {
    if (value < min) {
      return t("VALIDATION_NUMBER_MIN", { min: toFormattedCurrency(min, currency) });
    }

    if (value > max) {
      return t("VALIDATION_NUMBER_MAX", { max: toFormattedCurrency(max, currency) });
    }
  }
  return undefined;
};
