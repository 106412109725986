import ResourceImage from "../ResourceImage";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import { Label } from "../../storybook/components/Label/Label";
import MissingImage from "../MissingImage";
import ClickableImage from "../ClickableImage";
import WidgetContainer from "../WidgetContainer";

export interface WidgetImageProperties {
  label_text: string;
  resource_id?: string;
  max_height?: number;
  max_width?: number;
  click_to_view?: boolean;
}

const WidgetImage: Widget<WidgetImageProperties, WidgetResult<void>> = ({ fieldState }) => {
  const {
    resource_id: resourceId,
    max_width: maxWidth,
    max_height: maxHeight,
    click_to_view: clickToView,
    label_text: labelText,
  } = fieldState.properties;

  const style = {
    ...(maxHeight && { maxHeight: `${maxHeight}px` }),
    ...(maxWidth && { maxWidth: `${maxWidth}px` }),
  };

  const getImageComponent = (): JSX.Element => {
    if (!resourceId) {
      return <MissingImage />;
    }

    return clickToView ? (
      <ClickableImage resourceId={resourceId} style={style} />
    ) : (
      <ResourceImage className="mx-auto w-full" resourceId={resourceId} style={style} />
    );
  };

  return (
    <WidgetContainer name="IMAGE_FIELD" fieldState={fieldState}>
      <Label id={fieldState.uniqueFieldId} label={labelText} />
      {getImageComponent()}
    </WidgetContainer>
  );
};

WidgetImage.defaultValue = (_properties, defaultMeta: any): WidgetResult<void> => ({
  type: "none",
  meta: {
    widget: "image",
    ...defaultMeta,
  },
});

WidgetImage.validate = (): undefined => undefined;

export default WidgetImage;
