import { BarcodeScanner } from "@capacitor-mlkit/barcode-scanning";
import { GoogleBarcodeScannerModuleInstallState } from "@capacitor-mlkit/barcode-scanning/dist/esm/definitions";
import { sleep } from "./sleepUtil";
import { Platform } from "./deviceUtil";

const installSdk = (): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    BarcodeScanner.addListener("googleBarcodeScannerModuleInstallProgress", (e) => {
      if (e.state === GoogleBarcodeScannerModuleInstallState.FAILED) {
        reject(new Error("GoogleBarcodeScannerModuleInstall failed to complete"));
      }
      if (e.state === GoogleBarcodeScannerModuleInstallState.COMPLETED) {
        resolve();
      }
    });

    BarcodeScanner.installGoogleBarcodeScannerModule().catch(reject);
  });

export const ensureSdk = async (
  platform: Platform,
  isScannerInstalling: boolean,
  setIsScannerInstalling: (value: ((prevState: boolean) => boolean) | boolean) => void,
): Promise<void> => {
  if (platform !== "android") {
    return;
  }
  const { available } = await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable();
  if (!available && !isScannerInstalling) {
    setIsScannerInstalling(true);
    await installSdk();
    // We know it takes a bit longer than Google says it does. This ensures we can scan directly after install
    await sleep(1000);
    setIsScannerInstalling(false);
  }
};
