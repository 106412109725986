import { StrictMode } from "react";
import "./index.css";
import { QueryClient } from "@tanstack/react-query";
import { createRoot } from "react-dom/client";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import App from "./App";
import "./i18n";
import { BarcodeScannerProvider } from "./context/BarcodeScannerContext";
import { ToastsProvider } from "./context/ToastsContext";
import { AuthProvider } from "./context/AuthContext";
import Compose from "./components/Compose";
import { MoreAppClientProvider } from "./context/MoreAppContext";
import "./sentry";
import { DatabaseProvider } from "./context/DatabaseContext";
import { OnlineStatusProvider } from "./hooks/useOnlineStatus";
import { DeviceInfoProvider } from "./context/DeviceInfoContext";
import { DrawerProvider } from "./context/DrawerContext";
import { PermissionsProvider } from "./context/PermissionsContext";
import { DeviceOrientationProvider } from "./context/DeviceOrientationContext";
import { WorkerProvider } from "./context/WorkerContext";

defineCustomElements(window);
const queryClient = new QueryClient();
const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Compose
    components={[
      ToastsProvider,
      AuthProvider,
      OnlineStatusProvider,
      DatabaseProvider,
      MoreAppClientProvider,
      BarcodeScannerProvider,
      DeviceInfoProvider,
      DrawerProvider,
      PermissionsProvider,
      DeviceOrientationProvider,
      WorkerProvider,
      StrictMode,
    ]}
  >
    <App queryClient={queryClient} />
  </Compose>,
);
