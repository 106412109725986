import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import type { Customer } from "./useCustomers";
import { type MoreAppError, useMoreAppClient } from "../context/MoreAppContext";

const useCustomer = (customerId: number | undefined): UseQueryResult<Customer, MoreAppError> => {
  const client = useMoreAppClient();
  return useQuery<Customer, MoreAppError>({
    queryKey: ["customer", customerId],
    queryFn: async () => {
      const { data } = await client!.get<Customer>(`/api/v1.0/customers/${customerId}`);
      return data;
    },
    enabled: !!customerId,
  });
};

export default useCustomer;
