import { useContext, useMemo } from "react";
import { DeviceOrientationContext } from "../context/DeviceOrientationContext";

type UseDeviceOrientationResult = {
  landscapeIndent: string;
};

const useDeviceOrientation = (asMargin: boolean): UseDeviceOrientationResult => {
  const isLandscape = useContext(DeviceOrientationContext);
  const landscapeIndent = useMemo(() => (isLandscape ? (asMargin ? "mx-11" : "px-11") : ""), [asMargin, isLandscape]);

  return {
    landscapeIndent,
  };
};

export default useDeviceOrientation;
