import { FC } from "react";
import classNames from "classnames";
import { Spinner } from "./Spinner";
import { Text } from "../Text/Text";

export type SpinnerWithMessageProps = {
  message: string;
  bottomPadding?: boolean;
};

const SpinnerWithMessage: FC<SpinnerWithMessageProps> = ({ message, bottomPadding }) => {
  const classes = classNames("flex flex-col items-center pt-8", bottomPadding && "pb-16");

  return (
    <div className={classes}>
      <Spinner className="mb-2" />
      <Text color="medium" size="sm">
        {message}
      </Text>
    </div>
  );
};

export default SpinnerWithMessage;
