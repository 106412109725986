import { useMemo } from "react";
import useAuth from "./useAuth";
import { getTermData, getTermFields } from "../utils/termUtil";
import useStateFields from "../state/useStateFields";

const useSearchTerms = (filterFields?: string[], entryId?: string): any[] | undefined => {
  const { authorization } = useAuth();
  const fields = useStateFields();

  return useMemo(() => {
    if (!filterFields) {
      return [];
    }
    const termFields = getTermFields(fields, filterFields, entryId).filter((f) => f.field.value.rawValue);

    const terms = termFields
      .flatMap((f) => getTermData(f))
      .filter((x) => !!x)
      .map((termData) => (typeof termData === "number" ? termData.toString() : termData));
    if (filterFields.includes("username") && authorization.username && authorization.type === "oauth") {
      terms.push(authorization.username);
    }
    return terms;
  }, [filterFields, fields, entryId, authorization.username, authorization.type]);
};

export default useSearchTerms;
