import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import { Capacitor } from "@capacitor/core";
import { Directory } from "@capacitor/filesystem";
import { useContext, useEffect } from "react";
import useAuth from "./useAuth";
import { API_URL } from "../constants";
import { type MoreAppError, useMoreAppClient } from "../context/MoreAppContext";
import { getUri, stat } from "../utils/fileSystemUtil";
import { CustomerResourceContext } from "../context/CustomerResourceContext";

export type CustomerResource = {
  id: string;
  dataUrl?: string;
};

export type UseResourceResult = {
  resource: CustomerResource | undefined;
  isLoadingResource: boolean;
  errorResource: MoreAppError | null;
};

const useResource = (resourceId?: string): UseResourceResult => {
  const { customerId, username } = useAuth();
  const client = useMoreAppClient();
  const { results } = useContext(CustomerResourceContext);

  const queryResult = useQuery<CustomerResource, MoreAppError>({
    queryKey: ["customer-resource", resourceId],
    queryFn: () => {
      const result = results?.find((x) => x.data?.id === resourceId);
      if (result?.data) {
        return result.data;
      }
      return getResource(username!, customerId!, resourceId!, client);
    },
    enabled: !!client && !!resourceId && !!customerId && !!username,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    networkMode: "always",
  });

  return {
    ...queryResult,
    resource: queryResult.data,
    isLoadingResource: queryResult.isLoading,
    errorResource: queryResult.error,
  };
};

export const useResources = (resourceIds: string[]): UseQueryResult<CustomerResource, MoreAppError>[] => {
  const { results, addResources } = useContext(CustomerResourceContext);

  useEffect(() => {
    addResources(resourceIds);
  }, [addResources, resourceIds]);

  return results;
};

export const getResource = async (
  username: string,
  customerId: number,
  resourceId: string,
  client?: AxiosInstance,
): Promise<CustomerResource> => {
  const dataUrl = Capacitor.isNativePlatform()
    ? await fetchResourceNative(username, customerId, resourceId, client)
    : await fetchResource(customerId, resourceId, client);
  return { id: resourceId, dataUrl };
};

const fetchResourceNative = async (
  username?: string,
  customerId?: number,
  resourceId?: string,
  client?: AxiosInstance,
): Promise<string | undefined> => {
  const resourcePath = `${username}/${customerId}/customerResources/${resourceId}`;
  try {
    await stat({ path: resourcePath });
    const uri = await getUri({ directory: Directory.Data, path: resourcePath });
    return Capacitor.convertFileSrc(uri.uri);
  } catch (e) {
    return fetchResource(customerId, resourceId, client);
  }
};

const fetchResource = async (
  customerId?: number,
  resourceId?: string,
  client?: AxiosInstance,
): Promise<string | undefined> => {
  try {
    const response = await client!.get(`${API_URL}/customers/${customerId}/resources/${resourceId}/download/direct`, {
      responseType: "blob",
    });
    return URL.createObjectURL(response.data);
  } catch (e) {
    return undefined;
  }
};

export default useResource;
