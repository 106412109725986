import * as Sentry from "@sentry/react";
import { useRef } from "react";
import useDatabase from "./useDatabase";
import useOnlineStatus from "./useOnlineStatus";
import { minutes } from "../utils/timeUtil";
import { useAsyncEffect } from "./useAsyncEffect";
import { noopAsync } from "../utils/noop";
import useSubmissionCollection from "./useSubmissionCollection";

/**
 * This is a temporary hook to finalize submissions that are stuck in the local DB of the user.
 * @deprecated DEV-4867
 */
export const useSubmissionResend = (): void => {
  const { isOnline } = useOnlineStatus();
  const { isInitiallySynced } = useDatabase();
  const collection = useSubmissionCollection();
  const executedOnce = useRef<boolean>(false);

  useAsyncEffect(
    async () => {
      if (!collection || !isOnline || !isInitiallySynced || executedOnce.current) {
        return;
      }

      // Get submissions that have been "in sending" for 2 minutes or more.
      // On average a submission should be in processed within a few seconds
      const finalSubmissions = await collection
        .find()
        .where("status")
        .equals("final")
        .where("submittedAt")
        .lte(new Date(Date.now() - minutes(2)).toISOString())
        .exec();

      await Promise.allSettled(
        finalSubmissions.map(async (submission) => {
          Sentry.captureEvent({
            message: "Retrying submission finalization because it was stuck",
            level: "info",
            extra: { submission: submission.toJSON(false) },
          });
          await submission.incrementalPatch({ status: "final" });
        }),
      );
      executedOnce.current = true;
    },
    noopAsync,
    [collection, isOnline, isInitiallySynced],
  );
};
