import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../storybook/components/Modal/Modal";
import useDatabase from "../hooks/useDatabase";

interface ReplicationResetModalProps {
  show: boolean;
  onClose: () => void;
}

const ReplicationResetModal: FC<ReplicationResetModalProps> = ({ show, onClose }) => {
  const { t } = useTranslation();
  const { replicator } = useDatabase();
  const [isResetting, setResetting] = useState(false);

  return (
    <Modal
      title={t("REPLICATION_RESYNCHRONISE_MODAL_TITLE")}
      content={{
        kind: "message",
        message: t("REPLICATION_RESYNCHRONISE_MODAL_DESCRIPTION"),
      }}
      open={show}
      onClose={onClose}
      buttons={[
        {
          label: t("CANCEL"),
          onClick: onClose,
        },
        {
          label: t("RESET"),
          variant: "destructive",
          icon: "RefreshIcon",
          loading: isResetting,
          onClick: async (): Promise<void> => {
            setResetting(true);
            try {
              await replicator?.resetState();
            } finally {
              setResetting(false);
              onClose();
            }
          },
        },
      ]}
    />
  );
};

export default ReplicationResetModal;
