import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import useAuth from "./useAuth";
import { useMoreAppClient } from "../context/MoreAppContext";
import { DataSource } from "../types/Datasource";
import { fetchDataSource, onDatasourceError, onDatasourceSuccess } from "../utils/datasourceUtil";
import { hours, minutes } from "../utils/timeUtil";
import useDataSourceCollection from "./useDataSourceCollection";

const useDatasource = (id: string, disableRetry = false): UseQueryResult<DataSource, Error> => {
  const { customerId, username } = useAuth();
  const client = useMoreAppClient();
  const datasourceCollection = useDataSourceCollection();

  return useQuery<DataSource, Error>({
    queryKey: ["datasources", customerId, id],
    queryFn: () => fetchDataSource(username!, customerId!, id, client!, datasourceCollection!),
    enabled: !!customerId && !!id && !!datasourceCollection && !!username,
    onSuccess: (data) => onDatasourceSuccess(username!, customerId!, data, id, datasourceCollection!),
    onError: (err) => onDatasourceError(username!, customerId!, err, id),
    cacheTime: minutes(15),
    staleTime: hours(1),
    ...(disableRetry && { retry: false }),
    networkMode: "always",
  });
};

export default useDatasource;
