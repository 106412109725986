import { MutableRefObject, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { isNil } from "lodash-es";
import Form from "../Form";
import { ActiveEntry, DeleteIntent, Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import { SubForm } from "../../types/FormVersion";
import useDrawer from "../../hooks/useDrawer";
import { Modal } from "../../storybook/components/Modal/Modal";
import { WidgetContentButton } from "../../storybook/components/WidgetContentButton/WidgetContentButton";
import { Label } from "../../storybook/components/Label/Label";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";
import { Feedback } from "../../storybook/components/Feedback/Feedback";
import { Drawer } from "../../storybook/components/Drawer/Drawer";
import { MenuItem } from "../../storybook/components/DropdownMenu/DropdownMenu";
import TemplateContent from "../TemplateContent";
import WidgetContainer from "../WidgetContainer";
import { seconds } from "../../utils/timeUtil";
import useEntries from "../../state/useEntries";
import useActionAddEntry from "../../state/useActionAddEntry";
import useActionRemoveEntry from "../../state/useActionRemoveEntry";
import useActionGetFirstVisibleInvalidFieldId from "../../state/useActionGetFirstVisibleInvalidFieldId";
import useActionSetFocussedField from "../../state/useActionSetFocussedField";
import useActionValidateForm from "../../state/useActionValidateForm";
import useStateSubmissionId from "../../state/useStateSubmissionId";
import useStateFieldProperties from "../../state/useStateFieldProperties";
import useActionRememberFields from "../../state/useActionRememberFields";
import { useFocusId } from "../../hooks/useFocusId";

export interface WidgetSubformProperties {
  label_text: string;
  form: SubForm;
  target_form_id?: string;
  add_button_text?: string;
  itemHtml?: string;
  min_items?: number;
  max_items?: number;
}

export type SubformEntry<M> = {
  id: string;
  submissionId: string;
  meta: SubformEntryMeta & M;
  deleted: boolean;
};

export type SubformEntryMeta = {
  createdOn: string;
  order: number;
  description: string;
  error: boolean;
};

const WidgetSubform: Widget<WidgetSubformProperties, WidgetResult<void>> = ({ fieldState, readOnly }) => {
  const { t } = useTranslation();
  const addEntry = useActionAddEntry();
  const removeEntry = useActionRemoveEntry();
  const getFirstVisibleInvalidFieldId = useActionGetFirstVisibleInvalidFieldId();
  const setFocussedField = useActionSetFocussedField();
  const validateForm = useActionValidateForm();
  const submissionId = useStateSubmissionId();
  const fieldProperties = useStateFieldProperties();
  const rememberFields = useActionRememberFields();
  const [entries, activeEntry, setActiveEntry] = useEntries(fieldState);
  const [open, setOpen] = useDrawer(fieldState.uid);
  const [pendingRemovalEntry, setPendingRemovalEntry] = useState<DeleteIntent | undefined>();
  const initialFocus: MutableRefObject<any> = useRef(null);
  const onCloseFocusId = useFocusId(fieldState);

  const setDrawerOpen = useCallback((activityEntry: ActiveEntry) => {
    setActiveEntry(activityEntry);
    setOpen(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setDrawerClose = (): void => {
    setOpen(false);
    setActiveEntry(undefined);
  };

  const formVersion = fieldState.properties.target_form_id
    ? fieldProperties[fieldState.properties.target_form_id] // linked subform
    : fieldState.properties.form; // inline subform

  const handleDeleteEntry = (): void => {
    pendingRemovalEntry && removeEntry(pendingRemovalEntry.id, fieldState.uniqueFieldId);
    setPendingRemovalEntry(undefined);
    setDrawerClose();
  };

  const deleteModal = useMemo(() => {
    const isBack = pendingRemovalEntry?.type === "BACK_BUTTON";
    const message = isBack ? t("DISCARD_MODAL_DESCRIPTION") : t("SUBFORM_DELETE_MODAL_DESCRIPTION");
    return (
      <Modal
        onCloseFocusId={onCloseFocusId}
        title={isBack ? t("DISCARD_MODAL_TITLE") : t("SUBFORM_DELETE_MODAL_TITLE")}
        content={{ kind: "message", message }}
        open={!!pendingRemovalEntry}
        onClose={() => setPendingRemovalEntry(undefined)}
        buttons={[
          { label: t("CANCEL"), onClick: (): void => setPendingRemovalEntry(undefined) },
          { label: t("DELETE"), variant: "destructive", onClick: handleDeleteEntry },
        ]}
      />
    );
  }, [pendingRemovalEntry]); // eslint-disable-line react-hooks/exhaustive-deps

  const hasMaxItems = fieldState.properties.max_items && entries.length >= fieldState.properties.max_items;
  const hideAddButton = hasMaxItems || (readOnly && entries.length > 0);

  const saveEntry = async (entryId: string, enforceValidation?: boolean): Promise<void> => {
    rememberFields(formVersion, entryId);

    if (!enforceValidation) {
      setDrawerClose();
      return;
    }

    validateForm(entryId);
    const firstVisibleInvalidFieldId = getFirstVisibleInvalidFieldId(entryId);

    if (!isNil(firstVisibleInvalidFieldId)) {
      setFocussedField(undefined); // reset focus
      setTimeout(() => setFocussedField({ uniqueFieldId: firstVisibleInvalidFieldId, entryId })); // ensure reset is done first
      return;
    }

    setDrawerClose();
  };

  const onClose = (): void => {
    if (readOnly || isNil(activeEntry)) {
      setDrawerClose();
      return;
    }

    if (open && !activeEntry.isHumanEdited) {
      setTimeout(() => removeEntry(activeEntry.id, fieldState.uniqueFieldId), seconds(0.3)); // wait till drawer is gone
      setDrawerClose();
      return;
    }

    validateForm(activeEntry.id);

    open && saveEntry(activeEntry.id);
  };

  return (
    <WidgetContainer fieldState={fieldState} name="SUBFORM_FIELD">
      <Label
        id={fieldState.uniqueFieldId}
        label={fieldState.properties.label_text}
        required={(fieldState.properties.min_items || 0) > 0}
      />
      {entries.map((entry) => {
        const openButton: MenuItem = {
          label: t("OPEN"),
          onClick: () => setDrawerOpen({ id: entry.id, isHumanEdited: true }),
        };
        const deleteButton: MenuItem = {
          label: t("DELETE"),
          onClick: () => setPendingRemovalEntry({ type: "DELETE_BUTTON", id: entry.id }),
          type: "destructive",
        };
        return (
          <WidgetContentButton
            key={entry.id}
            appearance={entry.meta.error ? "danger" : "default"}
            className="my-1"
            items={!readOnly ? [openButton, deleteButton] : [openButton]}
          >
            <span className="flex" data-testid="entry-description">
              <TemplateContent content={entry.meta.description} allowNewLines />
            </span>
          </WidgetContentButton>
        );
      })}
      {!hideAddButton && (
        <IconAndTextButton
          id={onCloseFocusId}
          label={readOnly ? t("NO_SUBFORM_ENTRIES") : fieldState.properties.add_button_text || t("ADD")}
          icon="PlusIcon"
          block
          disabled={readOnly}
          onClick={() => {
            const entryId = addEntry(fieldState.uniqueFieldId, formVersion);
            setDrawerOpen({ id: entryId });
          }}
        />
      )}

      {fieldState.error && <Feedback status="error" message={fieldState.error} />}
      <Drawer
        open={open}
        header={{
          kind: "extended",
          title: fieldState.properties.label_text,
          leftButton: {
            kind: "textAndIcon",
            label: activeEntry?.isHumanEdited ? t("SAVE_AND_CLOSE") : t("BACK"),
            icon: "ChevronLeftIcon",
            iconAlign: "left",
            onClick: onClose,
          },
          rightButton: !readOnly
            ? {
                kind: "textAndIcon",
                label: t("OPTIONS"),
                icon: "ChevronDownIcon",
                dropDownMenu: {
                  items: [
                    {
                      label: t("SAVE"),
                      onClick: () => open && activeEntry && saveEntry(activeEntry.id, true),
                    },
                    {
                      label: t("DELETE"),
                      type: "destructive",
                      onClick: () =>
                        open && activeEntry && setPendingRemovalEntry({ type: "DELETE_BUTTON", id: activeEntry.id }),
                    },
                  ],
                },
              }
            : undefined,
        }}
        footer={{
          kind: "default",
          primaryButton: {
            label: readOnly ? t("CLOSE") : t("SAVE"),
            onClick: () => open && activeEntry && saveEntry(activeEntry.id, true),
          },
        }}
        initialFocus={initialFocus}
        onClose={onClose}
        className="flex flex-col gap-y-6 py-6"
      >
        {open && activeEntry && (
          <>
            <Form
              formVersion={formVersion}
              submissionId={submissionId}
              entryId={activeEntry.id}
              parentId={fieldState.uniqueFieldId}
            />
            {pendingRemovalEntry && deleteModal}
          </>
        )}
      </Drawer>
      {!open && deleteModal}
    </WidgetContainer>
  );
};

WidgetSubform.defaultValue = (_properties, defaultMeta): WidgetResult<void> => ({
  type: "array",
  meta: {
    widget: "subform",
    ...defaultMeta,
  },
  entries: [],
});

WidgetSubform.validate = (_val, properties, t, _meta, entries): string | undefined => {
  const min = properties.min_items;
  const max = properties.max_items;

  const subformEntries = entries?.filter((entry) => !entry.deleted) || [];

  if (min && min > subformEntries.length) {
    return t("VALIDATION_SUBFORM_MIN", { min });
  }
  if (max && max < subformEntries.length) {
    return t("VALIDATION_SUBFORM_MAX", { max });
  }
  const hasEntryWithError = !isNil(subformEntries.find((x) => x.meta.error));
  if (hasEntryWithError) {
    return t("VALIDATION_SUBFORM_ENTRY_INVALID");
  }

  return undefined;
};

export default WidgetSubform;
