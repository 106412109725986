import { useContext } from "react";
import { UploadManagerContext, type UploadManagerState } from "../context/UploadManagerContext";

export const useUploadManager = (): UploadManagerState => {
  const { isUploading, current, isActive } = useContext(UploadManagerContext);

  return {
    isUploading,
    current,
    isActive,
  };
};
