import { isEmpty } from "lodash-es";
import { HexColor } from "../storybook/colors";

type Branding = {
  isBranded: () => boolean;
  key: string;
  name: string;
  color: HexColor;
  startPageButton?: {
    label: string;
    url: string;
  };
};

const assertPresent = (value: any, key: string): string => {
  if (isEmpty(value)) {
    throw new Error(`Missing value for 'VITE_BRANDING_${key}'`);
  }

  return value;
};

const isBranded = (): boolean =>
  process.env.VITE_BRANDING_KEY !== null &&
  process.env.VITE_DEFAULT_BRANDING_KEY !== null &&
  process.env.VITE_BRANDING_KEY !== process.env.VITE_DEFAULT_BRANDING_KEY;

const startPageButtonLabel = process.env.VITE_BRANDING_START_PAGE_BUTTON_LABEL;
const startPageButtonUrl = process.env.VITE_BRANDING_START_PAGE_BUTTON_URL;

const validStartPageButton = startPageButtonLabel && startPageButtonUrl;

const branding: Branding = {
  isBranded,
  key: assertPresent(process.env.VITE_BRANDING_KEY, "KEY"),
  name: assertPresent(process.env.VITE_BRANDING_NAME, "NAME"),
  color: assertPresent(process.env.VITE_BRANDING_COLOR, "COLOR") as HexColor,
  ...(validStartPageButton && {
    startPageButton: {
      label: startPageButtonLabel!,
      url: startPageButtonUrl!,
    },
  }),
};

export default branding;
