import { useContext, useEffect } from "react";
import { isNil } from "lodash-es";
import { useNavigate } from "react-router";
import { RedirectContext } from "../context/RedirectContext";

type UseRedirectResult = {
  setRedirect: (path: string) => void;
};

const useRedirect = (): UseRedirectResult => {
  const { redirect, setRedirect } = useContext(RedirectContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isNil(redirect)) {
      return;
    }
    setRedirect(undefined);
    navigate(redirect);
  }, [navigate, redirect]);

  return {
    setRedirect,
  };
};

export default useRedirect;
