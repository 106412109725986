import { useMemo } from "react";
import useAuth from "./useAuth";
import useAuthenticationInfo from "./useAuthenticationInfo";
import { canCreateForms, isAdmin } from "../utils/permissionUtil";

type UseApiPermissionResult = {
  isFormCreator: boolean;
  isSuperAdmin: boolean;
};

const useApiPermission = (): UseApiPermissionResult => {
  const { customerId } = useAuth();
  const { data: authInfo } = useAuthenticationInfo();

  const isFormCreator = useMemo(
    () => (!customerId ? false : canCreateForms(customerId, authInfo)),
    [authInfo, customerId],
  );
  const isSuperAdmin = useMemo(() => isAdmin(authInfo), [authInfo]);

  return {
    isFormCreator,
    isSuperAdmin,
  };
};

export default useApiPermission;
