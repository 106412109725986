import { FC } from "react";
import { Text } from "../storybook/components/Text/Text";

interface DrawerListItemProps {
  label: string;
  value: string;
}

const DrawerListItem: FC<DrawerListItemProps> = ({ label, value }) => (
  <div className="flex w-full items-center justify-between border-b border-gray-200 py-3">
    <Text color="medium">{label}</Text>
    <Text color="dark">{value}</Text>
  </div>
);

export default DrawerListItem;
