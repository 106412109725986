import { useEffect, useMemo } from "react";
import { useRxData } from "rxdb-hooks";
import { groupBy, isEmpty } from "lodash-es";
import * as Sentry from "@sentry/react";
import useDeviceInfo from "./useDeviceInfo";
import { Field } from "../types/Field";
import useFileHandler from "./useFileHandler";
import { useUploadManager } from "./useUploadManager";
import useLocalSubmissions from "./useLocalSubmissions";
import useFieldCollection from "./useFieldCollection";

const useSubmissionFinalizer = (): void => {
  const fieldsCollection = useFieldCollection();
  const { isActive } = useUploadManager();
  const { id: deviceId } = useDeviceInfo();
  const { removeLocalFiles } = useFileHandler();
  const { result: submissions } = useLocalSubmissions((collection) =>
    collection
      .find()
      .where("status")
      .equals("draft")
      .where("submittedAt")
      .ne(null)
      .where("meta.device.id")
      .equals(deviceId),
  );

  const submissionIds = useMemo(() => submissions.map((submission) => submission.id), [submissions]);

  // exclude fields that were 'cleared' (value = null) but somehow didn't clear the `meta.uploadStatus`, which is fixed since DEV-5322
  const { result: allFieldsWithUploadStatus } = useRxData<Field>("fields", (collection) =>
    collection.find().where("submissionId").in(submissionIds).exists("uploadStatus").where("data").ne(null),
  ); // Pending uploads

  useEffect(() => {
    if (!fieldsCollection || !isActive) {
      return;
    }

    const fieldsPerSubmission: Record<string, Field[]> = groupBy(
      allFieldsWithUploadStatus,
      (field) => field.submissionId,
    );

    submissions.forEach((submission) => {
      const fieldsWithUploadStatus = fieldsPerSubmission[submission.id];
      if (isEmpty(fieldsWithUploadStatus)) {
        Sentry.captureEvent({
          message: "No fields found for submission in submissions finalizer",
          level: "info",
          extra: { submissionId: submission.id },
        });
        return;
      }

      const isReady = fieldsWithUploadStatus.every((field) => {
        if (field.uploadStatus === "error") {
          submission.incrementalPatch({
            submittedAt: undefined,
            meta: { statusMessage: "uploadsFailed" },
          });
          return false;
        }
        return field.uploadStatus === "uploaded" || field.uploadStatus === "aborted";
      });

      if (!isReady) {
        return;
      }

      submission.incrementalPatch({ status: "final" });
      removeLocalFiles(submission.id).catch((err) => {
        throw new Error("Could not remove local files", { cause: err });
      });
    });
  }, [allFieldsWithUploadStatus, fieldsCollection, submissions, removeLocalFiles, isActive]);
};

export default useSubmissionFinalizer;
