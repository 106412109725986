import { useState } from "react";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import { useMoreAppClient } from "../context/MoreAppContext";
import { AppVersions } from "../types/AppVersions";
import useDeviceInfo from "./useDeviceInfo";
import { hours, minutes } from "../utils/timeUtil";
import { useAsyncEffect } from "./useAsyncEffect";
import { isOutdatedVersion } from "../utils/versionUtil";

type UseAppVersionsResult = {
  appVersions: UseQueryResult<AppVersions, Error>;
  isOutdated: boolean;
};

const useAppVersions = (): UseAppVersionsResult => {
  const client = useMoreAppClient();
  const { platform } = useDeviceInfo();
  const [isOutdated, setOutdated] = useState<boolean>(false);

  const versions = useQuery<AppVersions, Error>({
    queryKey: ["appVersions"],
    queryFn: async () => {
      if (platform === "web") {
        return null;
      }

      const response = await client!.get(`/api/v1.0/common/app/${platform}/version`);
      return response.data;
    },
    enabled: !!platform,
    cacheTime: minutes(15),
    staleTime: hours(1),
  });

  useAsyncEffect(
    async () => {
      if (!versions.isSuccess || !versions.data) {
        return;
      }
      setOutdated(await isOutdatedVersion(versions.data.minimum));
    },
    async () => {},
    [versions.data],
  );

  return {
    appVersions: versions,
    isOutdated,
  };
};

export default useAppVersions;
