import { App } from "@capacitor/app";
import { Device, DeviceInfo as CapacitorDeviceInfo } from "@capacitor/device";
import { Capacitor } from "@capacitor/core";
import { Network } from "@capacitor/network";
import UAParser from "ua-parser-js";

export type Platform = "ios" | "android" | "web";
export type DeviceInfo = {
  id: string;
  name: string;
  model: string;
  platform: Platform;
  operatingSystem?: string;
  osVersion?: string;
  browserBrand?: string;
  browserVersion?: string;
  manufacturer: string;
  userAgent: string;
};

export type AppInfo = {
  name?: string;
  id?: string;
  version?: string;
  platform: Platform;
};

export type Connection = {
  connected: boolean;
  type: string;
};

export const getCurrentDevice = async (): Promise<DeviceInfo> => {
  const { identifier: id } = await Device.getId();
  const deviceInfo = await Device.getInfo();
  const { platform, manufacturer, model, name } = deviceInfo;
  const { userAgent } = navigator;

  const parsedUserAgent = new UAParser(userAgent);
  const operatingSystem = await getOperatingSystem(deviceInfo, parsedUserAgent);
  const osVersion = await getOperatingSystemVersion(deviceInfo, parsedUserAgent);
  const browser = await getBrowser(parsedUserAgent);

  return {
    id,
    manufacturer,
    model,
    name: name || `${platform} ${osVersion}`,
    operatingSystem,
    osVersion,
    browserBrand: browser.brand,
    browserVersion: browser.version,
    platform,
    userAgent,
  };
};

const getOperatingSystem = async (
  deviceInfo: CapacitorDeviceInfo,
  parsedUserAgent: UAParser,
): Promise<string | undefined> => {
  if (navigator.userAgentData) {
    return (await navigator.userAgentData.getHighEntropyValues(["platform"])).platform;
  }
  if (Capacitor.isNativePlatform()) {
    return deviceInfo.operatingSystem;
  }
  return parsedUserAgent.getOS().name;
};

const getOperatingSystemVersion = async (
  deviceInfo: CapacitorDeviceInfo,
  parsedUserAgent: UAParser,
): Promise<string | undefined> => {
  if (navigator.userAgentData) {
    return (await navigator.userAgentData.getHighEntropyValues(["platformVersion"])).platformVersion;
  }
  if (Capacitor.isNativePlatform()) {
    // ⚠️ All newer macOS versions report '10.15.7'
    return deviceInfo.osVersion;
  }
  // ⚠️ All newer macOS versions report '10.15.7'
  return parsedUserAgent.getOS().version;
};

const getBrowser = async (
  parsedUserAgent: UAParser,
): Promise<{ brand: string | undefined; version: string | undefined }> => {
  if (navigator.userAgentData) {
    const { fullVersionList } = await navigator.userAgentData.getHighEntropyValues(["fullVersionList"]);

    if (fullVersionList) {
      const withoutNotABrand = fullVersionList.filter(
        // 'Not_A Brand' is randomized over Chromium versions, so just check for 'brand' only
        (x) => !x.brand.toLowerCase().includes("brand"),
      );
      if (withoutNotABrand.length === 1) {
        return withoutNotABrand[0];
      }
      if (withoutNotABrand.length > 1) {
        const withoutChromium = withoutNotABrand.filter((x) => x.brand !== "Chromium");
        if (withoutChromium.length >= 1) {
          // Just get the first one
          return withoutChromium[0];
        }
      }
    }
  }

  const { name, version } = parsedUserAgent.getBrowser();
  return {
    brand: name,
    version,
  };
};

export const getCurrentAppInfo = async (): Promise<AppInfo> => {
  if (!Capacitor.isNativePlatform()) {
    return { platform: "web" };
  }
  const { id, name, version } = await App.getInfo();
  const { platform } = await Device.getInfo();
  return { id, name, version, platform };
};

export const getConnection = async (): Promise<Connection> => {
  const { connected, connectionType: type } = await Network.getStatus();
  return { connected, type };
};

/**
 * Coarse pointers usually indicate a touchscreen, or something with an imprecise pointer.
 */
export const isTouchCapable = (): boolean => window.matchMedia("(pointer: coarse)").matches;
