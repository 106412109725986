import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import useAuth from "./useAuth";
import { Form } from "../types/Folder";
import { API_URL } from "../constants";

type UserDirectFormResult = {
  form: Form | undefined;
  isLoadingForm: boolean;
};

const useDirectForm = (id?: string, customerId?: number): UserDirectFormResult => {
  const { authorization } = useAuth();
  const queryResult = useQuery<Form, Error>({
    queryKey: ["direct-form", id],
    queryFn: async () => {
      const response = await axios.get(`${API_URL}/forms/customer/${customerId}/forms/${id}`, {
        headers: { "X-More-Token": authorization.accessToken },
      });
      return response.data;
    },
    enabled: !!id && !!customerId && authorization.type === "token",
    staleTime: 0,
    cacheTime: 0, // This call shouldn't be cached, it can be changed or revoked every refresh
    refetchOnWindowFocus: false, // Only refetch on refresh
  });

  return {
    form: queryResult.data,
    isLoadingForm: queryResult.isLoading,
  };
};

export default useDirectForm;
