import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { MoreAppError } from "../context/MoreAppContext";
import { LOCATION_BASE_URL } from "../constants";
import { hours } from "../utils/timeUtil";

export type MapStyle = "STREET" | "SATELLITE";

export interface LocationConfiguration {
  tiles: {
    STREET: TileConfiguration;
    SATELLITE?: TileConfiguration;
  };
}

export type TileConfiguration = {
  url: string;
  displayName: string;
  attribution: string;
  maxZoom: number;
};

const useLocationConfiguration = (): UseQueryResult<LocationConfiguration, MoreAppError> =>
  useQuery<LocationConfiguration, MoreAppError>({
    queryKey: ["locationConfiguration"],
    queryFn: async () => {
      const { data } = await axios.get<LocationConfiguration>(`${LOCATION_BASE_URL}/v2/location/configuration`);
      return data;
    },
    staleTime: hours(24),
  });

export default useLocationConfiguration;
