import { FC, PropsWithChildren } from "react";

export interface FreestyleComponentProps extends PropsWithChildren {
  // eslint-disable-next-line react/no-unused-prop-types
  kind: "freestyle";
  className?: string;
}

const FreestyleComponent: FC<FreestyleComponentProps> = ({ children, className }) => (
  <div className={className}>{children}</div>
);

export default FreestyleComponent;
