import { useEffect } from "react";
import { isNil } from "lodash-es";
import useStateFocusedField from "../state/useStateFocusedField";

const useAutoScrollToFocussedField = (entryId?: string): void => {
  const focussedField = useStateFocusedField();
  useEffect(() => {
    if (focussedField?.entryId === entryId && !isNil(focussedField?.uniqueFieldId)) {
      const elem = document.getElementById(`field-${focussedField?.uniqueFieldId}`);
      elem?.scrollIntoView({ behavior: "smooth", block: "center" });

      // Prevent focus on date/time widget because scrolling gets interrupted in Safari when opening the
      // native date picker. This does not interfere with default Android or Browser behaviour
      const elementToFocus: HTMLInputElement | HTMLTextAreaElement | null | undefined = elem?.querySelector(
        "input:not([type='date']):not([type='time']), textarea",
      );
      elementToFocus?.focus({ preventScroll: true });
    }
  }, [focussedField?.uniqueFieldId, entryId, focussedField?.entryId]);
};

export default useAutoScrollToFocussedField;
