import { FC } from "react";
import { Button, GenericButtonProps } from "../Button/Button";

export interface TextButtonProps extends GenericButtonProps {
  label: string;
  block?: boolean;
  size?: "lg" | "md";
}

export const TextButton: FC<TextButtonProps> = (props) => <Button block={false} {...props} />;
