import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useInterval } from "usehooks-ts";
import { Duration } from "luxon";
import { DurationResult, Widget } from "../../types/Widget";
import { durationToString } from "../../utils/stringUtil";
import { WidgetResult } from "../../types/Field";
import { Label } from "../../storybook/components/Label/Label";
import { IconButton } from "../../storybook/components/IconButton/IconButton";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";
import { Feedback } from "../../storybook/components/Feedback/Feedback";
import WidgetContainer from "../WidgetContainer";
import { nowToISO } from "../../utils/dateUtil";

export interface WidgetStopwatchProperties {
  label_text: string;
}

const WidgetStopwatch: Widget<WidgetStopwatchProperties, WidgetResult<DurationResult>> = ({
  fieldState,
  setFieldState,
  readOnly,
}) => {
  const { t } = useTranslation();
  const { rawValue } = fieldState.value;
  const [elapsedTime, setElapsedTime] = useState<Duration>();
  const running = rawValue?.start && !rawValue?.end;

  useInterval(() => updateElapsedTime(), running ? 500 : null);

  const updateElapsedTime = (): void => {
    if (!rawValue?.start) {
      return;
    }
    const startTimestamp = new Date(rawValue?.start).getTime();
    setElapsedTime(Duration.fromMillis(Date.now() - startTimestamp));
  };

  const start = (): void => setFieldState({ start: nowToISO() });

  const stop = (): void => {
    if (!rawValue?.start) {
      return;
    }
    const endDate = new Date();
    const startDate = new Date(rawValue?.start);
    setFieldState({
      ...rawValue,
      end: endDate.toISOString(),
      duration: Duration.fromMillis(endDate.getTime() - startDate.getTime()).toISO() || undefined,
    });
  };

  const reset = (): void => {
    setElapsedTime(undefined);
    setFieldState(undefined);
  };

  const timerClasses =
    "flex h-12 w-auto grow items-center justify-center rounded-lg bg-brand-100 font-semibold text-gray-700";

  return (
    <WidgetContainer fieldState={fieldState} name="STOPWATCH_FIELD" className="relative">
      <Label
        id={fieldState.uniqueFieldId}
        label={fieldState.properties.label_text}
        showClearBtn={!readOnly && !!rawValue?.duration}
        onClear={reset}
        clearLabel={t("CLEAR")}
      />
      <div className="flex gap-x-1">
        {rawValue?.duration && (
          <time dateTime={rawValue.duration} aria-label={t("STOPWATCH_RESULT")} className={timerClasses}>
            {durationToString(Duration.fromISO(rawValue.duration), { forceHours: true, includeMillis: true })}
          </time>
        )}
        {rawValue?.start && !rawValue.end && (
          <div className="flex w-full items-center space-x-1.5">
            <div role="timer" aria-label={t("STOPWATCH_COUNTING")} className={timerClasses}>
              {elapsedTime ? durationToString(elapsedTime, { realTime: true, forceHours: true }) : "00:00:00"}
            </div>
            <IconButton aria-label={t("STOP_TIMER")} variant="primary" onClick={stop} icon="StopIcon" />
          </div>
        )}
        {!rawValue && (
          <IconAndTextButton
            block
            variant="default"
            label={readOnly ? t("NO_TIME_REGISTERED") : t("START")}
            icon="ClockIcon"
            onClick={start}
            disabled={readOnly}
          />
        )}
      </div>
      {fieldState.error && <Feedback status="error" message={fieldState.error} />}
    </WidgetContainer>
  );
};

WidgetStopwatch.defaultValue = (_properties, defaultMeta): WidgetResult<DurationResult> => ({
  type: "duration",
  meta: {
    widget: "stopwatch",
    ...defaultMeta,
  },
});

WidgetStopwatch.validate = (val, properties, t, _meta, _entries, validateAll): string | undefined => {
  if (val?.start && !val.end && validateAll) {
    return t("VALIDATION_STOPWATCH_RUNNING");
  }
  return undefined;
};

export default WidgetStopwatch;
