import { Capacitor } from "@capacitor/core";
import { ForegroundService } from "@capawesome-team/capacitor-android-foreground-service";
import { random } from "lodash-es";

export const startForegroundService = async (title: string, body: string): Promise<void> => {
  if (Capacitor.getPlatform() !== "android") {
    return;
  }
  await ForegroundService.startForegroundService({
    title,
    body,
    id: random(1000, 100_000, false),
    smallIcon: "notification",
  });
};

export const stopForegroundService = async (): Promise<void> => {
  if (Capacitor.getPlatform() !== "android") {
    return;
  }
  await ForegroundService.stopForegroundService();
};
