import React, { FC, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { isNil } from "lodash-es";
import { RxCollection } from "rxdb";
import useToasts from "../hooks/useToasts";
import useAuth from "../hooks/useAuth";
import { Submission } from "../types/Submission";
import uuidv4 from "../utils/uuid";
import { buildNewSubmission, updateSubmissionMeta } from "../utils/submissionUtil";
import useFileHandler from "../hooks/useFileHandler";
import logger from "../utils/logger";
import FormContentWrapper from "./FormContentWrapper";
import Form from "./Form";
import useDeviceInfo from "../hooks/useDeviceInfo";
import { FormVersion } from "../types/FormVersion";
import { SubmissionDocument } from "../utils/databaseUtil";
import { Theme } from "../storybook/themes";
import SubmissionDeleteModal from "./SubmissionDeleteModal";
import { IconAndTextButton } from "../storybook/components/IconAndTextButton/IconAndTextButton";
import { DropdownMenu } from "../storybook/components/DropdownMenu/DropdownMenu";
import useOnlineStatus from "../hooks/useOnlineStatus";
import { Modal } from "../storybook/components/Modal/Modal";
import { TextButton } from "../storybook/components/TextButton/TextButton";
import { FormState } from "../state/useSubmissionStore";
import { useSubmissionStoreInitializer } from "../state/useSubmissionStoreInitializer";
import { Spinner } from "../storybook/components/Spinner/Spinner";
import useStateHumanEdited from "../state/useStateHumanEdited";
import useActionGetFirstVisibleInvalidFieldId from "../state/useActionGetFirstVisibleInvalidFieldId";
import useActionSetFocussedField from "../state/useActionSetFocussedField";
import useActionValidateForm from "../state/useActionValidateForm";
import useActionRememberFields from "../state/useActionRememberFields";
import { nowToISO } from "../utils/dateUtil";
import useActionHasPendingUploads from "../state/useActionHasPendingUploads";
import useActionSetOptions from "../state/useActionSetOptions";

interface SubmissionFormProps {
  formVersion: FormVersion;
  submission: SubmissionDocument;
  theme: Theme;
  submissionCollection: RxCollection<Submission>;
  formState: FormState;
}

const editableFormOptions = { readOnly: false, persist: true, validate: true };
const readOnlyFormOptions = { readOnly: true, persist: false, validate: false };

const SubmissionForm: FC<SubmissionFormProps> = ({
  theme,
  submission,
  formVersion,
  submissionCollection,
  formState,
}) => {
  const { authorization } = useAuth();
  const { t } = useTranslation();
  const { removeLocalFiles } = useFileHandler();
  const navigate = useNavigate();
  const location = useLocation();
  const { showToast, hideToast } = useToasts();
  const device = useDeviceInfo();
  const { isOnline } = useOnlineStatus();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAbortModal, setShowAbortModal] = useState(false);
  const { initialized } = useSubmissionStoreInitializer(
    submission.id,
    submission.formId,
    formState,
    formVersion,
    isNil(submission?.submittedAt) ? editableFormOptions : readOnlyFormOptions,
  );
  const humanEdited = useStateHumanEdited();
  const getFirstVisibleInvalidFieldId = useActionGetFirstVisibleInvalidFieldId();
  const setFocussedField = useActionSetFocussedField();
  const validateForm = useActionValidateForm();
  const rememberFields = useActionRememberFields();
  const hasPendingUploads = useActionHasPendingUploads();
  const setOptions = useActionSetOptions();

  useEffect(() => {
    if (submission.status === "draft") {
      submission.incrementalPatch({ viewedAt: nowToISO() });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps -- should run only once

  const sentWithPendingUploads = submission.status === "draft" && submission.submittedAt;
  const isEditingDraft = submission.status === "draft" && !submission.submittedAt;
  const previouslyViewed = useRef(submission.viewedAt).current;
  const { saveMode } = formVersion.settings;

  const submit = async (): Promise<void> => {
    setIsSubmitting(true);
    try {
      setFocussedField(undefined); // reset focus
      validateForm();
      const firstVisibleInvalidFieldId = getFirstVisibleInvalidFieldId();

      if (!isNil(firstVisibleInvalidFieldId)) {
        setTimeout(() => setFocussedField({ uniqueFieldId: firstVisibleInvalidFieldId })); // ensure reset is done first
        return;
      }

      const metaSubmission = await updateSubmissionMeta(submission, device);

      const submittedAt = nowToISO();
      if (hasPendingUploads()) {
        // Only set the submittedAt, set final after all uploads are successful
        await metaSubmission.incrementalPatch({ submittedAt });
      } else {
        // Finalize and cleanup
        await metaSubmission.incrementalPatch({ submittedAt, status: "final" });
        await removeLocalFiles(submission.id);
      }

      await onSave();
    } catch (e) {
      logger.error("Couldn't submit submission", e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const navigateAfterSend = async (): Promise<void> => {
    if (saveMode === "ALL") {
      // Save & New
      await openNew();
    } else {
      goBack();
    }
  };

  const openNew = async (): Promise<void> => {
    const newSubmissionId = uuidv4();
    const { customerId, formId, formVersionId, form } = submission;
    const newSubmission = buildNewSubmission(newSubmissionId, customerId, formId, formVersionId, form);
    await submissionCollection.upsert(newSubmission);
    navigate(`/submissions/${newSubmissionId}`, { replace: true });
    window.location.reload(); // FIX IN DEV-5884: Solve this temporary hack with decent state management, solving out-of-sync form state issues with "Save & New"
  };

  const deleteSubmission = async (showMessage = true): Promise<void> => {
    try {
      await submission.remove();
      await removeLocalFiles(submission.id);
      if (showMessage) {
        showToast({ message: t("SUBMISSION_DELETED_TOAST_TITLE"), icon: "CheckIcon" });
      }
    } catch (e) {
      logger.warn("Could not delete submission", e);
    }
  };

  const returnAction = async (): Promise<void> => {
    goBack();
    if (!humanEdited && !submission.task && !previouslyViewed) {
      await deleteSubmission(false);
    }
  };

  const goBack = (): void => {
    location.key !== "default" ? navigate(-1) : navigate("/folders");
  };

  const header = (
    <div className="flex h-14 items-center justify-between">
      <IconAndTextButton
        icon="ChevronLeftIcon"
        variant="transparentWhite"
        size="md"
        onClick={returnAction}
        label={humanEdited && isEditingDraft ? t("SAVE_AND_CLOSE") : t("BACK")}
      />
      {isEditingDraft && (
        <DropdownMenu
          menuButton={() => (
            <IconAndTextButton
              iconAlign="right"
              icon="ChevronDownIcon"
              variant="transparentWhite"
              label={t("OPTIONS")}
              size="md"
              loading={isSubmitting}
            />
          )}
          items={[
            ...(saveMode !== "NO_SAVE"
              ? [
                  { label: t("SEND_FORM"), onClick: submit },
                  { label: t("SAVE_AND_CLOSE"), onClick: goBack },
                ]
              : []),
            {
              label: t("DELETE"),
              type: "destructive",
              onClick: () => setShowDeleteModal(true),
            },
          ]}
        />
      )}

      {sentWithPendingUploads && (
        <TextButton
          variant="transparentWhite"
          size="md"
          label={t("CANCEL_SENDING_BUTTON")}
          onClick={() => setShowAbortModal(true)}
        />
      )}
    </div>
  );

  const onSave = async (): Promise<void> => {
    rememberFields();
    await navigateAfterSend();
    if (authorization.type === "oauth") {
      if (!isOnline) {
        showToast({ message: t("OFFLINE_SUBMISSION_TOAST_TITLE") });
        return;
      }
      showToast({
        message: t("SUBMISSION_SENT_TOAST_TITLE"),
        icon: "CheckIcon",
        button: {
          type: "text",
          label: t("SUBMISSION_SENT_TOAST_ACTION"),
          onClick: () => {
            hideToast();
          },
          navigateTo: "/sent",
        },
      });
    }
  };

  const deleteModal = (
    <SubmissionDeleteModal
      showModal={showDeleteModal}
      onConfirm={async () => {
        goBack();
        await deleteSubmission();
        setShowDeleteModal(false);
      }}
      onModalClose={async () => setShowDeleteModal(false)}
    />
  );

  const abortSending = async (): Promise<void> => {
    try {
      await submission.incrementalPatch({ submittedAt: undefined, meta: undefined });
      setOptions(editableFormOptions);
    } catch (e) {
      logger.error("Couldn't abort sending submission with pending file uploads", e);
    } finally {
      setShowAbortModal(false);
    }
  };

  const abortModal = (
    <Modal
      title={t("SUBMISSION_CANCEL_SENDING_MODAL_TITLE")}
      content={{ kind: "message", message: t("SUBMISSION_CANCEL_SENDING_MODAL_DESCRIPTION") }}
      open={showAbortModal}
      onClose={() => setShowAbortModal(false)}
      buttons={[
        { label: t("CANCEL"), onClick: () => setShowAbortModal(false) },
        { label: t("SUBMISSION_CANCEL_CONFIRM_BUTTON"), onClick: abortSending, variant: "destructive" },
      ]}
    />
  );

  if (!initialized) {
    return <Spinner className="mx-auto my-8" />;
  }

  return (
    <>
      {deleteModal}
      {abortModal}
      <FormContentWrapper
        submission={submission}
        theme={theme}
        header={header}
        onSubmit={saveMode !== "NO_SAVE" ? submit : undefined}
        isSubmitting={isSubmitting}
      >
        <Form formVersion={formVersion} submissionId={submission.id} />
      </FormContentWrapper>
    </>
  );
};

export default SubmissionForm;
