import { Dialog, Transition } from "@headlessui/react";
import { FC, Fragment, MouseEventHandler, MutableRefObject } from "react";
import classNames from "classnames";
import { ButtonVariant } from "../Button/Button";
import { Title } from "../Title/Title";
import { Text } from "../Text/Text";
import { TextButton } from "../TextButton/TextButton";
import FreestyleComponent, { FreestyleComponentProps } from "../FreestyleComponent";
import { IconName } from "../Icon/Icon";
import { IconAndTextButton } from "../IconAndTextButton/IconAndTextButton";
import { useAfterLeaveFocus } from "../../../hooks/useAfterLeaveFocus";

export interface ModalMessageContent {
  kind: "message";
  message: string;
}

export interface ModalButton {
  label: string;
  variant?: ButtonVariant;
  icon?: IconName;
  loading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export interface ModalProps {
  title: string;
  content: ModalMessageContent | FreestyleComponentProps;
  open: boolean;
  onClose: () => void;
  buttons: ModalButton[];
  initialFocus?: MutableRefObject<any>;
  className?: string;
  onCloseFocusId?: string;
}

export const Modal: FC<ModalProps> = ({
  title,
  content,
  open,
  onClose,
  buttons = [],
  initialFocus,
  onCloseFocusId,
  className,
}) => {
  const classes = classNames("fixed inset-0 z-100 overflow-y-auto", className);
  const { afterLeaveFocus } = useAfterLeaveFocus(onCloseFocusId);

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => afterLeaveFocus()}>
      <Dialog as="div" className={classes} initialFocus={initialFocus} onClose={onClose}>
        <div className="flex min-h-screen items-center justify-center px-4 pb-20 pt-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            <div className="isolate flex max-h-full min-h-min w-full max-w-sm flex-col gap-y-4 rounded-2xl bg-white p-6 shadow-xl">
              <Dialog.Title as="div" className="min-w-0">
                <Title as="h1" size="lg">
                  {title}
                </Title>
              </Dialog.Title>
              <Dialog.Description as="div">
                {content.kind === "message" && <Text>{content.message}</Text>}
                {content.kind === "freestyle" && <FreestyleComponent {...content} />}
              </Dialog.Description>
              <div className="mt-2 flex gap-x-2">
                {buttons.map((b) => {
                  const props = {
                    key: b.label,
                    label: b.label,
                    variant: b.variant,
                    onClick: b.onClick,
                    loading: b.loading,
                    className: "grow",
                  };
                  return b.icon ? <IconAndTextButton {...props} icon={b.icon} /> : <TextButton {...props} />;
                })}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
