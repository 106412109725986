import { FC, useEffect } from "react";
import usePush from "../hooks/usePush";
import useAuth from "../hooks/useAuth";
import usePrefetchUser from "../hooks/usePrefetchUser";
import logger from "../utils/logger";

const AppState: FC = () => {
  const { registerPush } = usePush();
  const { authorization, isLoading } = useAuth();
  usePrefetchUser();

  useEffect(() => {
    if (authorization?.type === "oauth" && authorization?.userId && !isLoading) {
      registerPush().catch((e) => {
        if (e.status === 401) {
          logger.warn("Authentication expired");
        } else {
          logger.error("Could not register push notifications", e);
        }
      });
    }
  }, [authorization?.userId, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  // Isolate to avoid re-rendering entire App component for external effects
  return null;
};

export default AppState;
