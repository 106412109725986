import { UseQueryResult } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { MoreAppError } from "../context/MoreAppContext";

interface BaseInlineLoaderProps<T> {
  obj: UseQueryResult<T, MoreAppError>;
  opts?: { width: string };
}

interface InlineLoaderPropsField<T, K extends keyof T> extends BaseInlineLoaderProps<T> {
  field: K;
  format?: never;
}

interface InlineLoaderPropsFormat<T> extends BaseInlineLoaderProps<T> {
  field?: never;
  format: (value: T) => string;
}

const InlineLoader = <T, K extends keyof T>({
  obj,
  field,
  format,
  opts = { width: "w-full" },
}: InlineLoaderPropsField<T, K> | InlineLoaderPropsFormat<T>): JSX.Element => {
  const { t } = useTranslation();

  if (obj.isLoading) {
    return (
      <span className={`mx-auto inline-block max-w-sm ${opts.width}`}>
        <span className=" flex animate-pulse space-x-4">
          <span className="flex-1 space-y-4 py-1">
            <span className="block h-4 rounded bg-gray-200" />
          </span>
        </span>
      </span>
    );
  }

  if (obj.isError) {
    return <>{t("LOADER_FAILURE", { field })}</>;
  }

  if (format) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{format(obj.data)}</>;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{obj.data[field]}</>;
};

export default InlineLoader;
