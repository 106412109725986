import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useMutation } from "@tanstack/react-query";
import { debounce } from "lodash-es";
import { normalize } from "../utils/stringUtil";
import SelectionIcon from "./SelectionIcon";
import useFormTemplates, { FormTag } from "../hooks/useFormTemplates";
import { Form as FormTemplate } from "../types/Folder";
import { fromPlatformIcon } from "../utils/iconUtil";
import useAuth from "../hooks/useAuth";
import { MoreAppError, useMoreAppClient } from "../context/MoreAppContext";
import EmptyContent from "./EmptyContent";
import useToasts from "../hooks/useToasts";
import useFormVersionTemplate from "../hooks/useFormVersionTemplate";
import StaticForm from "./StaticForm";
import { ChipOption, Chips } from "../storybook/components/Chips/Chips";
import { Drawer } from "../storybook/components/Drawer/Drawer";
import { SearchField } from "../storybook/components/SearchField/SearchField";
import { Title } from "../storybook/components/Title/Title";
import { Spinner } from "../storybook/components/Spinner/Spinner";
import { TextButton } from "../storybook/components/TextButton/TextButton";
import { Text } from "../storybook/components/Text/Text";
import { Icon } from "../storybook/components/Icon/Icon";
import uuidv4 from "../utils/uuid";

interface AddFormsDrawerProps {
  open: boolean;
  onClose: () => void;
  folderId?: string;
}

interface AddTemplatesToFolder {
  folderId: string;
  templateIds: string[];
  publish: boolean;
}

const submissionId = uuidv4();

const AddFormsDrawer: FC<AddFormsDrawerProps> = ({ open, onClose, folderId: existingFolderId }) => {
  const { t } = useTranslation();
  const [sector, setSector] = useState("");
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [folderId, setFolderId] = useState(existingFolderId);
  const [activeTemplate, setActiveTemplate] = useState<FormTemplate>();
  const [selectedTemplateIds, setSelectedTemplateIds] = useState<string[]>([]);
  const { showToast, hideToast } = useToasts();
  const {
    data: formVersion,
    isFetching: isFetchingFormVersion,
    error: errorFormVersion,
    refetch: refetchFormVersion,
  } = useFormVersionTemplate(activeTemplate?.publishedVersion.formVersion);
  const {
    error: errorTemplates,
    isFetching: isFetchingTemplates,
    data: templates,
    refetch: refetchTemplates,
  } = useFormTemplates(sector, searchQuery);
  const client = useMoreAppClient();
  const { customerId } = useAuth();
  const {
    error: errorCreatingFolder,
    mutateAsync: createFolder,
    isLoading: isCreatingFolder,
  } = useMutation<any, MoreAppError>({
    mutationFn: async () =>
      client?.post(`/api/v1.0/forms/customer/${customerId}/folders`, {
        meta: { name: t("NEW_FOLDER_DEFAULT_NAME") },
      }),
  });
  const {
    error: errorAddingTemplates,
    mutateAsync: addTemplates,
    isLoading: isAddingTemplates,
  } = useMutation<any, MoreAppError, AddTemplatesToFolder>({
    mutationFn: async (data) =>
      client?.post(`/api/v1.0/forms/customer/${customerId}/folders/${data.folderId}/templates`, data),
  });

  const updateSearchQuery = useMemo(() => debounce(setSearchQuery, 500), []);

  useEffect(() => {
    updateSearchQuery(query);
  }, [updateSearchQuery, query]);

  useEffect(() => {
    if (errorCreatingFolder) {
      showToast({ message: t("FAILED_CREATE_FOLDER"), icon: "ExclamationCircleIcon" });
    }
    if (errorAddingTemplates) {
      showToast({
        message: t("FAILED_ADD_TEMPLATES", { count: selectedTemplateIds.length }),
        icon: "ExclamationCircleIcon",
      });
    }
  }, [errorCreatingFolder, errorAddingTemplates]); // eslint-disable-line react-hooks/exhaustive-deps

  const SECTORS: ChipOption[] = [
    { kind: "label", value: "", label: t("ALL") },
    { kind: "label", value: FormTag.generic, label: t("GENERIC") },
    { kind: "label", value: FormTag.construction, label: t("CONSTRUCTION") },
    { kind: "label", value: FormTag.sales, label: t("SALES") },
    { kind: "label", value: FormTag.hr, label: t("HR") },
    { kind: "label", value: FormTag.safety, label: t("SAFETY") },
    { kind: "label", value: FormTag.maintenance, label: t("MAINTENANCE") },
    { kind: "label", value: FormTag.logistics, label: t("LOGISTICS") },
    { kind: "label", value: FormTag.security, label: t("SECURITY") },
    { kind: "label", value: FormTag.real_estate, label: t("REAL_ESTATE") },
    { kind: "label", value: FormTag.healthcare, label: t("HEALTHCARE") },
    { kind: "label", value: FormTag.agriculture, label: t("AGRICULTURE") },
    { kind: "label", value: FormTag.industry, label: t("INDUSTRY") },
  ];

  const selectTemplate = (id: string): void => {
    setSelectedTemplateIds(selectedTemplateIds.concat(id));
    setActiveTemplate(undefined);
  };

  const unselectTemplate = (id: string): void => {
    setSelectedTemplateIds(selectedTemplateIds.filter((templateId) => templateId !== id));
    setActiveTemplate(undefined);
  };

  const isSelected = (template: FormTemplate): boolean => selectedTemplateIds.indexOf(template?.id) !== -1;

  const toggleTemplate = (template: FormTemplate): void =>
    !isSelected(template) ? selectTemplate(template.id) : unselectTemplate(template.id);

  const confirmTemplates = async (): Promise<void> => {
    const usedFolderId = folderId ?? (await createFolder()).data.id;
    setFolderId(usedFolderId);
    await addTemplates({ templateIds: selectedTemplateIds, folderId: usedFolderId, publish: true });
    closeDrawer();
  };

  const closeDrawer = (): void => {
    setSelectedTemplateIds([]);
    setSector("");
    setQuery("");
    onClose();
    hideToast();
  };

  return (
    <Drawer
      open={open}
      header={{
        kind: "simple",
        title: t("ADD_FORMS_TITLE"),
        button: { kind: "icon", icon: "XIcon", onClick: closeDrawer },
        content: (
          <div className="pb-6">
            <SearchField
              className="px-5"
              placeholder={t("SEARCH_PLACEHOLDER")}
              value={query}
              onChange={(newQuery) => setQuery(normalize(newQuery))}
            />
            <div className="mt-4 px-5">
              <Title as="h3" size="xs" className="ml-2">
                {t("ADD_FORMS_SELECT_SECTOR")}
              </Title>
              <Chips selected={sector} onSelect={(value) => setSector(value)} options={SECTORS} />
            </div>
          </div>
        ),
      }}
      onClose={closeDrawer}
      footer={
        selectedTemplateIds.length > 0
          ? {
              kind: "default",
              primaryButton: {
                label: t("ADD_FORMS_CONFIRM", { count: selectedTemplateIds.length }),
                onClick: confirmTemplates,
                loading: isCreatingFolder || isAddingTemplates,
              },
            }
          : undefined
      }
    >
      {!templates && isFetchingTemplates && <Spinner className="mx-auto mt-4" />}
      {!templates && !isFetchingTemplates && errorTemplates && (
        <EmptyContent title={t("OOPS")} description={`${t("FAILED_LOAD_TEMPLATES")}. ${t("CHECK_YOUR_CONNECTION")}`}>
          <TextButton variant="primary" label={t("RETRY")} onClick={() => refetchTemplates()} />
        </EmptyContent>
      )}
      {templates && templates.length === 0 && (
        <EmptyContent
          imgSrc="assets/empty.svg"
          title={t("ADD_FORMS_EMPTY_STATE")}
          description={t("ADD_FORMS_EMPTY_STATE_DESCRIPTION")}
        />
      )}
      <div className="grid grid-cols-2 gap-2 pt-4">
        {templates &&
          templates.length > 0 &&
          templates.map((template) => (
            <FormTemplateCard
              key={template.id}
              template={template}
              selected={isSelected(template)}
              onOpen={setActiveTemplate}
              onToggle={toggleTemplate}
            />
          ))}
      </div>

      <Drawer
        open={!!activeTemplate}
        header={{
          kind: "simple",
          title: t("ADD_FORMS_TEMPLATE"),
          button: {
            kind: "icon",
            icon: "XIcon",
            onClick: () => setActiveTemplate(undefined),
          },
        }}
        onClose={() => setActiveTemplate(undefined)}
        footer={{
          kind: "default",
          primaryButton: isSelected(activeTemplate!)
            ? {
                label: t("ADD_FORMS_PREVIEW_UNSELECT"),
                onClick: () => unselectTemplate(activeTemplate?.id!),
              }
            : {
                label: t("ADD_FORMS_PREVIEW_SELECT"),
                onClick: () => selectTemplate(activeTemplate?.id!),
              },
        }}
      >
        <div className="my-6 w-full">
          <Title as="h1" size="3xl" children={activeTemplate?.meta.name} />
          <Text color="medium">{activeTemplate?.meta.description}</Text>
        </div>
        {isFetchingFormVersion && <Spinner className="mx-auto" />}
        {!isFetchingFormVersion && errorFormVersion && (
          <EmptyContent title={t("OOPS")} description={`${t("FAILED_LOAD_FORM")}. ${t("CHECK_YOUR_CONNECTION")}`}>
            <TextButton variant="primary" label={t("RETRY")} onClick={() => refetchFormVersion()} />
          </EmptyContent>
        )}
        {activeTemplate && formVersion && <StaticForm formVersion={formVersion} submissionId={submissionId} readOnly />}
      </Drawer>
    </Drawer>
  );
};

interface FormTemplateProps {
  template: FormTemplate;
  onOpen: (template: FormTemplate) => void;
  onToggle: (template: FormTemplate) => void;
  selected: boolean;
}

type TemplateCardColor =
  | "bg-gray-600"
  | "bg-red-600"
  | "bg-green-600"
  | "bg-purple-600"
  | "bg-blue-600"
  | "bg-brand-600";
const FormTemplateCard: FC<FormTemplateProps> = ({ template, onOpen, onToggle, selected = false }) => {
  const getBgColor = (): TemplateCardColor => {
    switch (template.meta.tags[0]) {
      case FormTag.hr:
        return "bg-gray-600";
      case FormTag.sales:
        return "bg-red-600";
      case FormTag.generic:
        return "bg-green-600";
      case FormTag.safety:
        return "bg-purple-600";
      case FormTag.construction:
        return "bg-blue-600";
      default:
        return "bg-brand-600";
    }
  };

  const classes = classNames({
    "relative border rounded hover:cursor-pointer": true,
    "hover:bg-gray-50": !selected,
    "bg-brand-50 border-brand-200 hover:bg-brand-100/60": selected,
  });

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={classes} onClick={() => onOpen(template)}>
      <div className="absolute right-0 top-0">
        <SelectionIcon
          checked={selected}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onToggle(template);
          }}
        />
      </div>
      <div
        className={classNames("flex h-32 items-center justify-center border-b", {
          "border-brand-200": selected,
        })}
      >
        <div className={`size-14 rounded-full ${getBgColor()} flex items-center justify-center`}>
          <Icon name={fromPlatformIcon(template.meta.icon)} className="size-8 text-white" />
        </div>
      </div>
      <div className="p-3">
        <div className="flex justify-between">
          <Title as="h4" size="lg" className="truncate">
            {template.meta.name}
          </Title>
          <Icon name="ArrowRightIcon" className="shrink-0 pl-1 text-gray-600" />
        </div>
        <Text color="medium" size="xs" className="mt-1 line-clamp-2">
          {template.meta.description}
        </Text>
      </div>
    </div>
  );
};

export default AddFormsDrawer;
