import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import AddFormsDrawer from "../components/AddFormsDrawer";
import BlockLoader from "../components/BlockLoader";
import Content from "../components/Content";
import EmptyContent from "../components/EmptyContent";
import MenuPage from "../components/MenuPage";
import SearchDrawer from "../components/SearchDrawer";
import useApiPermission from "../hooks/useApiPermission";
import useAuth from "../hooks/useAuth";
import useDeviceOrientation from "../hooks/useDeviceOrientation";
import useDrawer from "../hooks/useDrawer";
import useFolders from "../hooks/useFolders";
import { NON_DESCRIPTIVE_IMAGE } from "../utils/a11yUtil";
import { compareByLocale } from "../utils/formUtil";
import { fromPlatformIcon } from "../utils/iconUtil";
import { NavigationPageOutletContext } from "./NavigationPage";
import { TextButton } from "../storybook/components/TextButton/TextButton";
import { Title } from "../storybook/components/Title/Title";
import { NavItem } from "../storybook/components/NavItem/NavItem";
import PullToRefresh from "../components/PullToRefresh";
import logger from "../utils/logger";
import { isTouchCapable } from "../utils/deviceUtil";

const FoldersPage: FC = () => {
  const folders = useFolders();
  const { t } = useTranslation();
  const { hasCustomerAccess } = useAuth();
  const navigate = useNavigate();
  const [showAddForm, setShowAddForm] = useState(false);
  const [search, setSearch] = useDrawer("search");
  const { isFormCreator } = useApiPermission();
  const { setHeaderProps, setShowWorkspaceHeader } = useOutletContext<NavigationPageOutletContext>();
  const openSearchDrawer = useCallback(() => setSearch(true), [setSearch]);
  const { landscapeIndent } = useDeviceOrientation(true);
  const location = useLocation();

  /**
   * Only enable pull to refresh when not in a sub-path, and when the device has touch capabilities.
   */
  const isRefreshable = useMemo(
    () => isTouchCapable() && !location.pathname.includes("/", 1) && !showAddForm,
    [location.pathname, showAddForm],
  );

  useEffect(() => {
    setShowWorkspaceHeader(true);
    setHeaderProps({
      defaultTitle: t("FOLDERS_PAGE_TITLE"),
      actions: [
        {
          "aria-label": t("MENU_SETTINGS"),
          kind: "icon",
          icon: "CogIcon",
          iconType: "outline",
          onClick: () => navigate("/settings"),
          className: "lg:hidden",
        },
        {
          "aria-label": t("SEARCH"),
          kind: "icon",
          icon: "SearchIcon",
          onClick: openSearchDrawer,
        },
      ],
    });
  }, [navigate, setHeaderProps, setShowWorkspaceHeader, t]); // eslint-disable-line react-hooks/exhaustive-deps

  const emptyUserContent = (
    <EmptyContent
      title={t("FOLDERS_EMPTY_USER_TITLE")}
      description={t("FOLDERS_EMPTY_USER_DESCRIPTION")}
      imgAlt={NON_DESCRIPTIVE_IMAGE}
      imgSrc="assets/forms/empty.svg"
    />
  );
  const emptyState = (
    <EmptyContent
      title={t("FOLDERS_EMPTY_TITLE")}
      description={t("FOLDERS_EMPTY_DESCRIPTION")}
      imgSrc="assets/forms/empty.svg"
      imgAlt={t("FOLDERS_EMPTY_ALT")}
    >
      <TextButton variant="primary" label={t("FOLDERS_EMPTY_CTA")} onClick={() => setShowAddForm(true)} />
    </EmptyContent>
  );
  return (
    <MenuPage showTabBar>
      <SearchDrawer open={search} onClose={() => setSearch(false)} initialCategory="FORMS" />
      <PullToRefresh disabled={!isRefreshable} onRefresh={() => folders.refetch()}>
        <Content padding={false} className={landscapeIndent}>
          <Title as="h2" size="xs" className="sr-only">
            {t("FOLDERS")}
          </Title>
          {!hasCustomerAccess ? (
            emptyUserContent
          ) : (
            <BlockLoader obj={folders} emptyState={isFormCreator ? emptyState : emptyUserContent}>
              {(data) => (
                <div className="mt-3">
                  {data
                    .filter((folder) => folder.status !== "HIDDEN")
                    .sort((a, b) => compareByLocale(a.meta.name, b.meta.name))
                    .map((folder) => (
                      <NavItem
                        key={folder.id}
                        label={folder.meta.name}
                        icon={{
                          name:
                            folder.meta.icon && folder.meta.icon !== "ios-folder-outline"
                              ? fromPlatformIcon(folder.meta.icon)
                              : undefined,
                          iconBackdrop: "folder",
                        }}
                        description={folder.meta.description}
                        onClick={() => navigate(`/folders/${folder.id}`)}
                      />
                    ))}
                </div>
              )}
            </BlockLoader>
          )}
        </Content>
      </PullToRefresh>
      {showAddForm && (
        <AddFormsDrawer
          open={showAddForm}
          onClose={() => {
            setShowAddForm(false);
            folders.refetch().catch((e) => logger.error("Couldn't refetch folders", e));
          }}
        />
      )}
    </MenuPage>
  );
};

export default FoldersPage;
