import { Trans, useTranslation } from "react-i18next";
import { FC } from "react";
import { Text } from "../../storybook/components/Text/Text";
import { Message } from "../../storybook/components/Message/Message";
import { Title } from "../../storybook/components/Title/Title";

export type LoadingCondition = {
  labelKey: string;
  value: boolean;
};

interface LoadingContentProps {
  className?: string;
  conditions: LoadingCondition[];
}
const LoadingContent: FC<LoadingContentProps> = ({ className, conditions }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <img className="mx-auto mt-2" src="assets/errorpages/expired.svg" alt={t("OFFLINE_ALT")} />
      <Title as="h1" size="lg" className="mt-6 text-center">
        {t("LOADING_SCREEN_ERROR_TITLE")}
      </Title>
      <Text className="mt-2 text-center">
        {/* Needed instead of useTranslation in order to use breaklines  */}
        <Trans i18nKey="LOADING_SCREEN_ERROR_DESCRIPTION" />
      </Text>
      <div className="max-h-48 overflow-y-auto">
        {conditions
          .filter((x) => x.value)
          .map((condition) => (
            <Message key={condition.labelKey} className="mt-2" type="warning" title={t(condition.labelKey)} />
          ))}
      </div>
    </div>
  );
};

export default LoadingContent;
