import { FC, useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router";
import AndroidBackButtonListener from "../components/AndroidBackButtonListener";
import { DrawerContext } from "../context/DrawerContext";
import useStatusBar from "../hooks/useStatusBar";
import useDatabase from "../hooks/useDatabase";
import useEnsureBarcodeScannerSdk from "../hooks/useEnsureBarcodeScannerSdk";
import useRedirect from "../hooks/useRedirect";
import Toasts from "../components/Toasts";

const RootPage: FC = () => {
  const { pathname } = useLocation();
  const [currentPath, setCurrentPath] = useState(pathname);
  const { reset } = useContext(DrawerContext);
  const { setAnonymousDatabase } = useDatabase();

  useRedirect();
  useStatusBar();
  useEnsureBarcodeScannerSdk();

  useEffect(() => {
    if (pathname !== currentPath) {
      setCurrentPath(pathname);
      reset();
    }
  }, [currentPath, reset, pathname]);

  useEffect(() => setAnonymousDatabase?.(pathname.startsWith("/form/")), [pathname, setAnonymousDatabase]);

  return (
    <div className="h-screen w-screen overflow-hidden bg-gray-100">
      <Toasts />
      <AndroidBackButtonListener />
      <Outlet />
    </div>
  );
};

export default RootPage;
