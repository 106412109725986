/* eslint-disable no-param-reassign */
import { FC, useMemo } from "react";
import DOMPurify from "isomorphic-dompurify";
import ReactHtmlParser from "react-html-parser";
import { optimizationImage } from "../utils/stringUtil";

interface HtmlContentProps {
  html: string;
}
const HtmlContent: FC<HtmlContentProps> = ({ html }) => {
  const sanitizedHtml = useMemo(
    () =>
      DOMPurify.sanitize(html, {
        USE_PROFILES: { html: true },
        ADD_TAGS: ["iframe"],
        FORBID_TAGS: ["style"],
      }),
    [html],
  );

  const transformedSanitizedHtml = useMemo(
    () =>
      ReactHtmlParser(sanitizedHtml, {
        transform: (node) => {
          if (node.type === "tag" && node.name === "img") {
            node.attribs.src = node.attribs.src ? optimizationImage(node.attribs.src) : undefined;
          }
          if (node.type === "tag" && node.name === "h1") {
            node.name = "h2";
          }
          if (node.type === "tag" && node.name === "a") {
            node.attribs.target = "_blank";
            node.attribs.rel = "noopener noreferrer";
          }
        },
      }),
    [sanitizedHtml],
  );
  return <article className="prose break-words">{transformedSanitizedHtml}</article>;
};

export default HtmlContent;
