import { createContext, FC, PropsWithChildren, useCallback, useState } from "react";
import { useQueries, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { CustomerResource, getResource } from "../hooks/useResource";
import useAuth from "../hooks/useAuth";
import { MoreAppError, useMoreAppClient } from "./MoreAppContext";

export const CustomerResourceProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { customerId, username } = useAuth();
  const client = useMoreAppClient();

  const [ids, setIds] = useState<string[]>([]);
  const results = useQueries({
    queries: ids.map<UseQueryOptions<CustomerResource, MoreAppError>>((resourceId) => ({
      queryKey: ["customer-resource", resourceId],
      queryFn: () => getResource(username!, customerId!, resourceId, client),
      enabled: !!client && !!customerId && !!resourceId && !!username,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    })),
  });

  const addResources = useCallback(
    (resourceIds: string[]) => {
      setIds((prevState) => uniqBySetWithSpread<string>([...prevState, ...resourceIds]));
    },
    [setIds],
  );

  return (
    <CustomerResourceContext.Provider value={{ results, addResources }}>{children}</CustomerResourceContext.Provider>
  );
};

interface CustomerResourceState {
  results: UseQueryResult<CustomerResource, MoreAppError>[];
  addResources: (resourceIds: string[]) => void;
}

export const CustomerResourceContext = createContext<CustomerResourceState>({} as CustomerResourceState);

const uniqBySetWithSpread = <T extends any>(array: T[]): T[] => [...new Set(array)];
