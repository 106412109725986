import { useEffect } from "react";
import { WidgetResult } from "../../types/Field";
import { Widget } from "../../types/Widget";
import { getCurrentWeekNumber } from "../../utils/weekNumberUtil";
import { NumberInput } from "../../storybook/components/NumberInput/NumberInput";
import WidgetContainer from "../WidgetContainer";

export type WidgetWeekNumberProperties = {
  label_text: string;
};

const WidgetWeekNumber: Widget<WidgetWeekNumberProperties, WidgetResult<number>> = ({
  fieldState,
  setFieldState,
  readOnly,
}) => {
  useEffect(() => {
    if (!fieldState.value.rawValue && !readOnly) {
      setFieldState(getCurrentWeekNumber(), { humanEdited: false });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WidgetContainer fieldState={fieldState} name="WEEK_NUMBER_FIELD">
      <NumberInput
        name={fieldState.uid}
        label={fieldState.properties.label_text}
        inputMode="numeric"
        disabled
        value={fieldState.value.rawValue ?? 0}
      />
    </WidgetContainer>
  );
};

WidgetWeekNumber.defaultValue = (_properties, defaultMeta: any): WidgetResult<number> => ({
  type: "number",
  rawValue: undefined,
  meta: {
    widget: "weekNumber",
    ...defaultMeta,
  },
});

WidgetWeekNumber.validate = (): undefined => undefined;

export default WidgetWeekNumber;
