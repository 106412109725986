import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { isNil } from "lodash-es";
import TemplateContent from "../../TemplateContent";
import { DeleteIntent, PinResultMeta } from "../../../types/Widget";
import { SubformEntry } from "../WidgetSubform";
import { WidgetContentButton } from "../../../storybook/components/WidgetContentButton/WidgetContentButton";
import { MenuItem } from "../../../storybook/components/DropdownMenu/DropdownMenu";
import { Modal } from "../../../storybook/components/Modal/Modal";

interface PinEntriesProps {
  entries: SubformEntry<any>[];
  isDisabled: boolean;
  onEdit: (value: SubformEntry<PinResultMeta>) => void;
  onMove: (value: SubformEntry<PinResultMeta>) => void;
  onDelete: (value: DeleteIntent) => void;
  onCloseFocusId?: string;
}

const PinEntries: FC<PinEntriesProps> = ({ entries, isDisabled, onEdit, onMove, onDelete, onCloseFocusId }) => {
  const { t } = useTranslation();
  const [removeEntry, setRemoveEntry] = useState<DeleteIntent | undefined>();

  const getOptionalEditButton = (pin: SubformEntry<PinResultMeta>): { label: string; onClick: () => void }[] =>
    pin.meta?.scope?.target ? [{ label: t("OPEN"), onClick: () => onEdit(pin) }] : [];

  return (
    <>
      <div className="mt-2 flex flex-col space-y-2">
        {entries.map((pin, index) => (
          <WidgetContentButton
            key={index}
            appearance={pin.meta.error ? "danger" : "default"}
            items={[
              ...getOptionalEditButton(pin),
              ...(!isDisabled
                ? [
                    { label: t("MOVE"), onClick: () => onMove(pin) },
                    {
                      label: t("DELETE"),
                      onClick: () => setRemoveEntry({ id: pin.id, type: "DELETE_BUTTON" }),
                      variant: "destructive",
                    } as MenuItem,
                  ]
                : []),
            ]}
          >
            <span className="flex" data-testid="pin-entry-description">
              <TemplateContent content={pin.meta.description} allowNewLines />
            </span>
          </WidgetContentButton>
        ))}
      </div>
      <Modal
        onCloseFocusId={onCloseFocusId}
        title={removeEntry?.type === "BACK_BUTTON" ? t("DISCARD_MODAL_TITLE") : t("PIN_DELETE_MODAL_TITLE")}
        content={{
          kind: "message",
          message:
            removeEntry?.type === "BACK_BUTTON" ? t("DISCARD_MODAL_DESCRIPTION") : t("PIN_DELETE_MODAL_DESCRIPTION"),
        }}
        open={!isNil(removeEntry)}
        onClose={() => setRemoveEntry(undefined)}
        buttons={[
          { label: t("CANCEL"), onClick: () => setRemoveEntry(undefined) },
          {
            label: t("DELETE"),
            variant: "destructive",
            onClick: (): void => {
              onDelete(removeEntry!);
              setRemoveEntry(undefined);
            },
          },
        ]}
      />
    </>
  );
};
export default PinEntries;
