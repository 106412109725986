import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import WidgetHidden from "./WidgetHidden";
import HtmlContent from "../HtmlContent";
import WidgetContainer from "../WidgetContainer";

export interface WidgetInstructionRichTextProperties {}

const WidgetInstructionRichText: Widget<WidgetInstructionRichTextProperties, WidgetResult<string>> = ({
  fieldState,
}) => {
  if (!fieldState.value.rawValue) {
    return <WidgetHidden />;
  }
  return (
    <WidgetContainer fieldState={fieldState} name="RICH_TEXT_FIELD">
      <HtmlContent html={fieldState.value.rawValue} />
    </WidgetContainer>
  );
};

WidgetInstructionRichText.defaultValue = (_properties, defaultMeta: any): WidgetResult<string> => ({
  type: "string",
  meta: {
    widget: "instructionRichText",
    ...defaultMeta,
  },
});

WidgetInstructionRichText.validate = (): undefined => undefined;

export default WidgetInstructionRichText;
