import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useState } from "react";

export const BarcodeScannerProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [isScanning, setIsScanning] = useState(false);
  const [isScannerInstalling, setIsScannerInstalling] = useState<boolean>(false);
  return (
    <BarcodeScannerContext.Provider value={{ isScanning, setIsScanning, isScannerInstalling, setIsScannerInstalling }}>
      {children}
    </BarcodeScannerContext.Provider>
  );
};

interface BarcodeScannerState {
  isScanning: boolean;
  setIsScanning: Dispatch<SetStateAction<boolean>>;
  isScannerInstalling: boolean;
  setIsScannerInstalling: Dispatch<SetStateAction<boolean>>;
}

export const BarcodeScannerContext = createContext<BarcodeScannerState>({} as BarcodeScannerState);
